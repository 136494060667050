import { useState } from "react";

import ConfirmationModal from "../../../components/modals/confirmation-modal/ConfirmationModal";
import ExpandCollapseSection from "../../../components/core/ExpandCollapseSection";
import VerbalCounselForm from "../../../components/forms/VerbalCounselForm";
import Modal from "../../../components/modals/Modal";
import HrvcItem from "./HrvcItem";
import { ParTable } from "../../../components/helpers/ParFormComponents";

import { useCurrentPar } from "../../../components/contexts/CurrentParContext";
import { useModalContext } from "../../../components/contexts/ModalContext";

const VerbalCounselSection = () => {
  const { parFormDataState } = useCurrentPar();
  const { handleOpenModal, handleCloseModal, isModalOpen } = useModalContext();
  const { hrvc, checkboxes, date } = parFormDataState;
  const [selectedHrvc, setSelectedHrvc] = useState({});

  const renderHrvcTable = () => {
    return hrvc.map((record) => {
      return (
        <HrvcItem
          hrvc={record}
          key={record.counselNumber}
          setSelectedHrvc={setSelectedHrvc}
        />
      );
    });
  };

  return (
    <>
      <ExpandCollapseSection
        sectionName={"Verbal Counsel"}
        checkboxValue={checkboxes.verbalCounsel}
      >
        {hrvc?.length > 0 ? (
          <>
            <ParTable
              tableClass="hrvc-table"
              tableHeaders={[
                { class: "header name", name: "Employee Name" },
                { class: "header number", name: "Employee #" },
                { class: "header behavior", name: "Behavior" },
              ]}
              tableRows={renderHrvcTable()}
            />
          </>
        ) : (
          <h3>No Verbal Counsel Entered</h3>
        )}

        <button
          className="add-button"
          disabled={!date}
          onClick={() => {
            handleOpenModal("hrvc");
            setSelectedHrvc({});
          }}
        >
          Add Verbal Counsel
        </button>
      </ExpandCollapseSection>

      <Modal
        isModalOpen={isModalOpen.hrvc}
        onRequestClose={() => {
          handleCloseModal("hrvc");
          setSelectedHrvc({});
        }}
      >
        <VerbalCounselForm
          selectedHrvc={selectedHrvc}
          setSelectedHrvc={setSelectedHrvc}
        />
      </Modal>

      <ConfirmationModal
        isModalOpen={isModalOpen.addHrvc}
        onRequestClose={() => {
          handleCloseModal("addHrvc");
        }}
        modalQuestion={"Do you want to add another Verbal Counsel?"}
        onConfirm={() => {
          handleCloseModal("addHrvc");
          handleOpenModal("hrvc");
        }}
      />
    </>
  );
};
export default VerbalCounselSection;
