export const getTotalHours = (id, formData, type) => {
  let totalHours = 0;

  for (const phase in formData.phaseCodes) {
    if (phase !== "012130-00-00") {
      const formSection =
        type === "equipment"
          ? formData.phaseCodes[phase].equipmentData
          : formData.phaseCodes[phase].laborData;

      for (const item of formSection) {
        const itemId = type === "equipment" ? item.equipNum : item.id;

        if (id === itemId) {
          const itemType =
            type === "equipment" ? item.operatedHours : item.hoursWorked;

          totalHours += Number(itemType) ? Number(itemType) : 0;
        }
      }
    }
  }

  return totalHours.toString();
};
