import { useState, forwardRef } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ConfirmationModal from "../../../components/modals/confirmation-modal/ConfirmationModal";
import PhaseNote from "./PhaseNote";
import PhaseCodeSubSection from "./PhaseCodeSubSection";

import { useCurrentPar } from "../../../components/contexts/CurrentParContext";

const PhaseCodeItem = forwardRef((props, ref) => {
  const { phaseNum, perDiemAmount } = props;
  const location = useLocation();

  const [isRemovePhaseModalOpen, setIsRemovePhaseModalOpen] = useState(false);
  const [pwClassesByJob, setPwClassesByJob] = useState([]);
  const [minimize, setMinimize] = useState(
    !location.pathname.includes("approvals")
  );

  const { parFormDataState, parDispatch } = useCurrentPar();

  const item = parFormDataState.phaseCodes[phaseNum];

  const clearPhase = () => {
    parDispatch({
      type: "clearPhasecode",
      payload: {
        phasecode: phaseNum,
      },
    });
  };

  const deletePhase = () => {
    parDispatch({
      type: "removePhaseCode",
      payload: {
        selectedPhase: phaseNum,
      },
    });

    setIsRemovePhaseModalOpen(false);

    ref.current.splice(
      ref.current.indexOf(
        ref.current.find((el) => {
          return el?.id === item.phaseDescription;
        })
      ),
      1
    );
  };

  return (
    <div
      id={item.phaseDescription}
      className={`par-phase-container ${minimize ? "" : "is-selected"}`}
      ref={(el) => {
        if (!ref.current?.includes(el)) {
          ref.current.push(el);
        }
      }}
    >
      <div className="phase-button-wrapper">
        <button
          className={`phase-code-button ${minimize ? "" : "is-selected"}`}
          onClick={() => setMinimize((minimize) => !minimize)}
          title={item.phaseDescription}
        >
          Phase: {item.phaseDescription}
        </button>

        <button
          className="remove-modal"
          onClick={() => setIsRemovePhaseModalOpen(true)}
        >
          <FontAwesomeIcon icon="fa fa-trash" />
        </button>
      </div>

      {!minimize && (
        <>
          <PhaseNote phaseNum={phaseNum} item={item} />

          <PhaseCodeSubSection
            phaseNum={phaseNum}
            type="labor"
            title="Labor Data"
            totalHours={{ records: "laborData", filterProperty: "hoursWorked" }}
            perDiemAmount={perDiemAmount}
            pwClassesByJob={pwClassesByJob}
            setPwClassesByJob={setPwClassesByJob}
          />

          {phaseNum !== "012130-00-00" && (
            <PhaseCodeSubSection
              phaseNum={phaseNum}
              type="equipment"
              title="Equipment Data"
              totalHours={{
                records: "equipmentData",
                filterProperty: "operatedHours",
              }}
            />
          )}

          {phaseNum !== "012130-00-00" && (
            <PhaseCodeSubSection
              phaseNum={phaseNum}
              type="progressEntry"
              title="Progress Entry"
            />
          )}

          <div className="button-wrapper">
            <button
              className="minimize-button"
              onClick={() => setMinimize((m) => !m)}
            >
              Minimize
            </button>

            <button className="clear-button" onClick={clearPhase}>
              Clear
            </button>
          </div>
        </>
      )}

      <ConfirmationModal
        isModalOpen={isRemovePhaseModalOpen}
        onRequestClose={() => setIsRemovePhaseModalOpen(false)}
        onConfirm={deletePhase}
        modalQuestion={`Are you sure you want to remove: ${
          item.phaseDescription?.length > 0
            ? item.phaseDescription
            : `"untitled" `
        }?`}
      />
    </div>
  );
});

export default PhaseCodeItem;
