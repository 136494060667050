import ExpandCollapseSection from "../../../components/core/ExpandCollapseSection";
import { useCurrentPar } from "../../../components/contexts/CurrentParContext";
import { ParTextArea } from "../../../components/helpers/ParFormComponents";

const PhaseNote = (props) => {
  const { phaseNum, item } = props;

  const { parDispatch } = useCurrentPar();

  const updatePhaseCodeNotes = (updateValue, fieldName) => {
    parDispatch({
      type: "updatePhaseInput",
      payload: {
        updateValue,
        fieldName,
        phaseNum,
      },
    });
  };

  return (
    <div className="phase-note-container">
      <ExpandCollapseSection sectionName="Phase Note" checkboxValue={true}>
        <div className="note-wrapper">
          <ParTextArea
            customClass="phase-notes"
            rows="10"
            cols="200"
            labelName={`${phaseNum}-phase-notes`}
            labelClass="hidden"
            placeholder="Phase Notes: Please record any important information that pertains specifically to this phase code. Who, What, Where, When, and How."
            value={item.phaseNote}
            onChange={(e) => updatePhaseCodeNotes(e.target.value, "phaseNote")}
          />
        </div>
      </ExpandCollapseSection>
    </div>
  );
};

export default PhaseNote;
