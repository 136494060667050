import { getTotalHours } from "./totalHours";

const calculateSectionValues = (records, filterProperty) => {
  if (records.length > 0) {
    let totalVal = 0;

    records.forEach((record) => {
      if (record[filterProperty] === "") {
        return;
      } else {
        totalVal += parseFloat(record[filterProperty]);
      }
    });

    return totalVal;
  }
  return 0;
};

const calculateMaterialTotal = (records, filterProperty) => {
  if (records.length > 0) {
    let workingVal = records.reduce(
      (total, currentRecord) =>
        total + parseFloat(currentRecord[filterProperty].replaceAll(",", "")),
      0
    );

    workingVal = +workingVal;

    const totalVal = parseFloat(workingVal).toLocaleString("en", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });

    return totalVal;
  }

  return 0;
};

const calculateTotalPerDiemHours = (records, data) => {
  let totalHours = 0;

  records.forEach(
    (employee) =>
      (totalHours += Number(getTotalHours(employee.id, data, "labor")))
  );
  totalHours = +totalHours.toFixed(2);

  return totalHours;
};

const calculateTotalParValues = (records, type) => {
  if (Object.keys(records).length > 0) {
    const totalHoursArray = [];

    Object.keys(records).forEach((phaseCode) => {
      if (phaseCode !== "012130-00-00") {
        type === "laborData"
          ? records[phaseCode].laborData.forEach((employee) => {
              totalHoursArray.push(employee.hoursWorked);
            })
          : records[phaseCode].equipmentData.forEach((equipment) => {
              totalHoursArray.push(equipment.operatedHours);
            });
      }
    });

    let totalHours = totalHoursArray.reduce(
      (total, currentRecord) => Number(total) + Number(currentRecord),
      0
    );
    totalHours = +totalHours.toFixed(2);
    return totalHours;
  }
  return 0;
};

export {
  calculateSectionValues,
  calculateTotalPerDiemHours,
  calculateTotalParValues,
  calculateMaterialTotal,
};
