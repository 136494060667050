import { useState } from "react";

import PhaseCodeItem from "./PhaseCodeItem";

import useAbortEffect from "../../../components/hooks/useAbortEffect";

import asyncAPICall from "../../../util/apiWrapper";
import { useCurrentPar } from "../../../components/contexts/CurrentParContext";

const PhaseCodesSection = ({ phaseCodesRef }) => {
  const [perDiemAmount, setPerDiemAmount] = useState(0);

  const { parFormDataState } = useCurrentPar();
  const { jobNumber, phaseCodes } = parFormDataState;

  const movePerDiem = () => {
    const listOfKeys = Object.keys(phaseCodes);
    if (listOfKeys.includes("012130-00-00")) {
      const perDiemPhaseCodeIndex = listOfKeys.indexOf("012130-00-00");

      listOfKeys.splice(perDiemPhaseCodeIndex, 1);

      listOfKeys.push("012130-00-00");

      return listOfKeys;
    } else {
      return listOfKeys;
    }
  };

  useAbortEffect(
    (signal) => {
      if (jobNumber) {
        asyncAPICall(
          `api:KE7MCNYf/vprojectswithid_by_Project?project=${jobNumber}`,
          "GET",
          null,
          null,
          (data) => {
            if (data.length > 0) {
              if (data[0].perDiem === "Y") {
                setPerDiemAmount(data[0].perDiemAmount);
              }
            }
          },
          (err) => {
            if (signal.aborted) {
            } else {
              console.error("Setting Per Diem Error", err);
            }
          },
          signal
        );
      }
    },
    [jobNumber]
  );

  return (
    <div className="phasecode-table-container">
      {movePerDiem().map((item) => {
        return (
          <PhaseCodeItem
            key={item}
            ref={phaseCodesRef}
            phaseNum={item}
            perDiemAmount={perDiemAmount}
          />
        );
      })}
    </div>
  );
};

export default PhaseCodesSection;
