import { useCallback, useEffect, useRef, useState } from "react";

export const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const windowWidthRef = useRef(window.innerWidth);

  const handleWindowResize = useCallback(() => {
    setWindowWidth(window.innerWidth);
    windowWidthRef.current = window.innerWidth;
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  return { windowWidth, setWindowWidth, windowWidthRef };
};

export default useWindowWidth;
