import {
  createContext,
  useReducer,
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import { useLocation } from "react-router-dom";

import useDebounce from "../../components/hooks/useDebounce";

import { useAuthInfo } from "./AuthContext";

import { baseParFormData } from "../reducer/store/baseParFormData";
import { parReducer } from "../reducer/parFormReducer";

const CurrentParContext = createContext(null);

export function CurrentParContextProvider({ children }) {
  const localStoragePar = JSON.parse(localStorage.getItem("formData"));
  const [resetting, setResetting] = useState(false);
  const [lastSaved, setLastSaved] = useState("");
  const [allPhaseEmployees, setAllPhaseEmployees] = useState([]);
  const [allPhaseEquipment, setAllPhaseEquipment] = useState([]);

  const [parFormDataState, parDispatch] = useReducer(
    parReducer,
    localStoragePar ? localStoragePar : baseParFormData
  );

  const phaseCodesRef = useRef([]);
  const lastSelected = useRef();

  const debounceFormData = useDebounce(parFormDataState);
  const { pathname } = useLocation();
  const { user } = useAuthInfo();

  const handleResetting = (bool) => {
    setResetting(bool);
  };

  function resetPar() {
    const resetForm = { ...baseParFormData };

    setAllPhaseEmployees([]);
    setAllPhaseEquipment([]);
    resetForm.foreman = user.employeeID;
    resetForm.date = parFormDataState.date;
    setResetting(true);

    const resetDispatch = new Promise((res, rej) => {
      try {
        parDispatch({
          type: "setParForm",
          form: resetForm,
        });

        res(true);
      } catch (error) {
        rej(error.message);
      }
    });

    resetDispatch
      .then(() => setResetting(false))
      .catch((err) => console.error("Resetting PAR error: ", err));
  }

  const handleAllPhaseEmployees = useCallback((employeeArray) => {
    setAllPhaseEmployees(employeeArray);
  }, []);

  const handleAllPhaseEquipment = useCallback((equipmentArray) => {
    setAllPhaseEquipment(equipmentArray);
  }, []);

  useEffect(() => {
    if (debounceFormData && pathname.slice(1, 4) === "par") {
      localStorage.setItem(
        "formData",
        JSON.stringify({ ...debounceFormData, timestamp: Date.now() })
      );
    }

    setLastSaved(new Date().toLocaleString());
  }, [debounceFormData, pathname]);

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("formData"));

    if (savedData) {
      const elapsedTime = Date.now() - savedData.timestamp;
      if (elapsedTime > 64800000) {
        localStorage.clear();
      } else {
        parDispatch({
          type: "setParForm",
          form: savedData,
        });
      }
    } else {
      parDispatch({
        type: "setParForm",
        form: baseParFormData,
      });
    }
  }, []);

  const values = {
    resetting,
    handleResetting,
    lastSaved,
    setLastSaved,
    parFormDataState,
    parDispatch,
    allPhaseEmployees,
    handleAllPhaseEmployees,
    allPhaseEquipment,
    handleAllPhaseEquipment,
    phaseCodesRef,
    lastSelected,
    resetPar,
  };

  return (
    <CurrentParContext.Provider value={values}>
      {children}
    </CurrentParContext.Provider>
  );
}

export const useCurrentPar = () => {
  return useContext(CurrentParContext);
};
