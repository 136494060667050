import { useState } from "react";

import useAbortEffect from "../../components/hooks/useAbortEffect";

import asyncAPICall from "../../util/apiWrapper";
import { calculateTotalParValues } from "../../components/helpers/calculateHours";
import { getTotalHours } from "../../components/helpers/totalHours";

const VerificationTotals = (props) => {
  const { formData, windowWidth, wrapperClass = "", children } = props;

  const [billedEquipmentHours, setBilledEquipmentHours] = useState(0);
  const laborTotal = calculateTotalParValues(formData.phaseCodes, "laborData");
  const equipmentTotal = calculateTotalParValues(
    formData.phaseCodes,
    "equipmentData"
  );

  const pathName = window.location.pathname;
  const pathNameArray = pathName.split("/", 3);
  const urlPath = pathNameArray[1];

  const renderTotals = (type) => {
    const totalsData = [];
    const idNums = [];

    Object.keys(formData.phaseCodes).forEach((phaseCode) => {
      const formSection =
        type === "equipment"
          ? formData.phaseCodes[phaseCode].equipmentData
          : formData.phaseCodes[phaseCode].laborData;

      for (const item of formSection) {
        const id = type === "equipment" ? item.equipNum : item.id;
        const description = type === "equipment" ? item.description : item.name;

        if (!idNums.includes(id)) {
          idNums.push(id);
          totalsData.push([id, description]);
        }
      }
    });

    const alphabetizedItems = [...totalsData].sort(
      (firstEmployee, secondEmployee) =>
        firstEmployee[1] > secondEmployee[1] ? 1 : -1
    );

    return windowWidth <= 650 ? (
      type === "equipment" ? (
        equipmentTotal > 0 && (
          <tbody className="mobile-tbody">
            <tr>
              <th className="vertical-header">
                <span>Equipment Number & Description</span>
              </th>
              {alphabetizedItems.map((item, idx) => (
                <td
                  className={idx === 0 ? "first-column" : ""}
                  key={item[0] + "1"}
                >
                  {item[0]} {item[1]}
                </td>
              ))}
            </tr>
            <tr>
              <th className="vertical-header">
                <span>Hours</span>
              </th>
              {alphabetizedItems.map((item, idx) => (
                <td
                  className={idx === 0 ? "first-column" : ""}
                  key={item[0] + "2"}
                >
                  {getTotalHours(item[0], formData, "equipment")}
                </td>
              ))}
            </tr>
          </tbody>
        )
      ) : (
        laborTotal > 0 && (
          <tbody className="mobile-tbody">
            <tr>
              <th className="vertical-header">
                <span>Employee Name</span>
              </th>
              {alphabetizedItems.map((item, idx) => (
                <td
                  className={idx === 0 ? "first-column" : ""}
                  key={item[0] + "1"}
                >
                  {item[1]}
                </td>
              ))}
            </tr>
            <tr>
              <th className="vertical-header">
                <span>Employee Number</span>
              </th>
              {alphabetizedItems.map((item, idx) => (
                <td
                  className={idx === 0 ? "first-column" : ""}
                  key={item[0] + "2"}
                >
                  {item[0]}
                </td>
              ))}
            </tr>
            <tr>
              <th className="vertical-header">
                <span>Hours</span>
              </th>
              {alphabetizedItems.map((item, idx) => (
                <td
                  className={idx === 0 ? "first-column" : ""}
                  key={item[0] + "3"}
                >
                  {getTotalHours(item[0], formData, "labor")}
                </td>
              ))}
            </tr>
          </tbody>
        )
      )
    ) : (
      <tbody className="desktop-tbody">
        {alphabetizedItems.map((item, idx) => (
          <tr key={item[0] + idx}>
            {type === "equipment" ? (
              <>
                <td>
                  {item[0]} {item[1]}
                </td>
                <td>{getTotalHours(item[0], formData, "equipment")}</td>
              </>
            ) : (
              <>
                <td>{item[1]}</td>
                <td>{item[0]}</td>
                <td>{getTotalHours(item[0], formData, "labor")}</td>
              </>
            )}
          </tr>
        ))}
      </tbody>
    );
  };

  useAbortEffect(
    (signal) => {
      if (formData?.jobNumber && urlPath === "select-print") {
        asyncAPICall(
          `api:W2oHkD04/vIdleVsOperatedEquipmentHoursByJob?job=${formData.jobNumber}`,
          "GET",
          null,
          null,
          (data) => {
            if (data?.billHours) {
              setBilledEquipmentHours(data.billHours);
            } else {
              setBilledEquipmentHours(0);
            }
          },
          (err) => {
            console.error("Error Retriving Equipment Billed Hours", err);
          },
          signal
        );
      }
    },
    [formData.jobNumber]
  );

  return (
    <div className={`verification-totals-wrapper ${wrapperClass}`}>
      {children}
      <div className="tables">
        <h1>Total Hours</h1>
        <div className="outer-wrapper">
          <div className="table-wrapper">
            <table cellSpacing={0}>
              {laborTotal > 0 && windowWidth > 650 && (
                <thead>
                  <tr>
                    <th>Employee Name</th>
                    <th>Employee Number</th>
                    <th>Hours</th>
                  </tr>
                </thead>
              )}
              {renderTotals("labor")}
            </table>
          </div>
        </div>

        {laborTotal > 0 ? (
          <h3 className="par-phase-total">
            Daily Total Employee Hours: {laborTotal}
          </h3>
        ) : (
          <h3 className="par-phase-total-labor">No Labor</h3>
        )}

        {!(billedEquipmentHours !== 0 && urlPath === "select-print") && (
          <>
            <div className="outer-wrapper">
              <div className="table-wrapper">
                <table cellSpacing={0}>
                  {windowWidth > 650 &&
                    (equipmentTotal > 0 ? (
                      <thead>
                        <tr>
                          <th>Equipment Number & Description</th>
                          <th>Hours</th>
                        </tr>
                      </thead>
                    ) : null)}
                  {renderTotals("equipment")}
                </table>
              </div>
            </div>
            {equipmentTotal > 0 ? (
              <h3 className="par-phase-total-equipment">
                Daily Total Equipment Hours: {equipmentTotal}
              </h3>
            ) : (
              <h3 className="par-phase-total">No Equipment</h3>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default VerificationTotals;
