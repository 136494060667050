const ReportSignatures = ({ wrapperClass = "", children }) => {
  return (
    <div className={`report-signatures-wrapper ${wrapperClass}`}>
      {children}

      <div className="show-mobile hide-print">
        Show date and signature lines.
      </div>

      <div className="signatures">
        <div className="group">
          <div className="signature-container">
            <div className="label customer">
              OSR: ____________________________
            </div>
          </div>
          <div className="signature-container">
            <div className="label date">Date: ____________________________</div>
          </div>
        </div>

        <div className="group">
          <div className="signature-container">
            <div className="label bhi">BHI: ____________________________</div>
          </div>
          <div className="signature-container">
            <div className="label date">Date: ____________________________</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportSignatures;
