import { useState, useEffect } from "react";
import { useCurrentPar } from "../contexts/CurrentParContext";

const ExpandCollapseSection = (props) => {
  const { children, sectionName, checkboxValue, nested = false } = props;
  const [showSection, setShowSection] = useState(true);
  const { resetting } = useCurrentPar();

  useEffect(() => {
    setShowSection(true);
  }, [resetting]);

  return (
    <div className={`checkbox-item-container ${nested ? "nested" : ""}`}>
      {checkboxValue && (
        <div className="show-hide-wrapper">
          <button
            className="collapse-button"
            onClick={() => setShowSection((prev) => !prev)}
          >
            {sectionName}
          </button>

          {showSection && (
            <div className="single-problem-container">{children}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default ExpandCollapseSection;
