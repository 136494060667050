import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ApprovedPars = ({ userApprovedPars }) => {
  const reportsCopy = [...userApprovedPars];

  reportsCopy.sort((a, b) => b.created_at - a.created_at);

  return (
    <div className="notifications-container">
      <h1 className="approvals-header">APPROVED PAR'S</h1>

      <div className="approvals scroll">
        {reportsCopy.slice(0, 14).map((parReport) => {
          return (
            <div className="par-box green-gradient" key={parReport.id}>
              <FontAwesomeIcon icon="fas fa-check-circle" />

              <h3>{parReport.date}</h3>
              <h4>{parReport.jobNumber}</h4>

              <Link
                className="overview-report-link"
                to={{
                  pathname: `/select-print/${parReport.dcrNumber}`,
                }}
              >
                View Report
              </Link>

              <h4 className="par-status">{parReport.dcrStatus}</h4>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default ApprovedPars;
