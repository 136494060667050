import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCurrentPar } from "../../../components/contexts/CurrentParContext";

const ProgressEntryPhaseItem = (props) => {
  const { phaseNum, progressEntry, handleModal, isMobileScreen } = props;

  const { parDispatch } = useCurrentPar();

  const handleProgressEntry = (e) =>
    parDispatch({
      type: "updatePhaseCodeField",
      payload: {
        phaseCode: phaseNum,
        attributeName: "progressEntry",
        fieldName: "dcrProgress",
        updatedValue: e.target.value,
        rowId: progressEntry.id,
        filterName: "id",
      },
    });

  const handleRemoveProgressEntry = () =>
    handleModal(
      {
        attributeName: "progressEntry",
        selectedRecord: progressEntry.id,
        filterName: "id",
      },
      "removeProgressEntry"
    );

  return (
    <tr align={"left"} key={progressEntry.id} className="table-row">
      <td className="padding-20">
        {isMobileScreen && "ID: "}
        {progressEntry.id}
      </td>
      <td>
        {isMobileScreen && "Phase: "}
        {progressEntry.phase}
      </td>
      <td>
        {isMobileScreen && "Descripion: "}
        {progressEntry.description}
      </td>
      <td>
        {isMobileScreen && "Current Estimated Units: "}
        {progressEntry.currentEstimatedUnits}
      </td>
      <td>
        {isMobileScreen && "UOM: "}
        {progressEntry.uom}
      </td>
      <td>
        {isMobileScreen && "JTD Progress: "}
        {progressEntry.jtdProgress}
      </td>

      <td>
        {isMobileScreen && "PAR Daily Progress "}
        <input
          type="number"
          onKeyDown={(e) => e.code === "Minus" && e.preventDefault()}
          min="0"
          value={progressEntry.dcrProgress}
          onChange={handleProgressEntry}
        />
      </td>

      <td>
        <FontAwesomeIcon
          icon="svg-inline--fa fa-trash"
          onClick={handleRemoveProgressEntry}
        />
      </td>
    </tr>
  );
};

export default ProgressEntryPhaseItem;
