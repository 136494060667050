import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import preventNumScroll from "../../../components/helpers/preventNumScroll";
import { hoursWorkedIsValid } from "../../../components/helpers/hoursWorkedIsValid";
import { useCurrentPar } from "../../../components/contexts/CurrentParContext";
import { getTotalHours } from "../../../components/helpers/totalHours";
import { errorToast } from "../../../util/toastNotifications";

const EmployeePhaseItem = (props) => {
  const {
    employee,
    phaseNum,
    handleModal,
    perDiemAmount,
    handleResetPerDiem,
    isMobileScreen,
  } = props;

  const { parFormDataState, parDispatch } = useCurrentPar();

  const handleEmployeeHourBlur = () => {
    const resetHoursWorked = {
      type: "updatePhaseCodeField",
      payload: {
        phaseCode: phaseNum,
        attributeName: "laborData",
        fieldName: "hoursWorked",
        updatedValue: "0",
        rowId: employee.id,
        filterName: "id",
      },
    };

    const hourWorkedResult = hoursWorkedIsValid(
      parFormDataState,
      employee.id,
      "laborData"
    );

    if (hourWorkedResult === "exceeds") {
      parDispatch(resetHoursWorked);

      errorToast(
        `${employee.name} Hours Has Exceeded Total Hours From PAR Job Information Section.`
      );
    } else if (hourWorkedResult === "precision") {
      parDispatch(resetHoursWorked);

      errorToast("Cannot Input More Than Two Decimal Places.");
    }
  };

  const handleEmployeeHours = (e) => {
    parDispatch({
      type: "updatePhaseCodeField",
      payload: {
        phaseCode: phaseNum,
        attributeName: "laborData",
        fieldName: "hoursWorked",
        updatedValue: e.target.value,
        rowId: employee.id,
        filterName: "id",
      },
    });
  };

  const handlePerDiemCheckmark = (e) => {
    parDispatch({
      type: "updatePhaseCodeField",
      payload: {
        phaseCode: phaseNum,
        attributeName: "laborData",
        fieldName: "perDiem",
        updatedValue: e.target.checked,
        rowId: employee.id,
        filterName: "id",
      },
    });
  };

  const handlePerDiemAmountBlur = (e) => {
    e.target.value > perDiemAmount &&
      handleResetPerDiem(
        perDiemAmount,
        "laborData",
        "perDiemAmount",
        employee.id,
        "id"
      );
  };

  const handlePerDiemAmount = (e) =>
    parDispatch({
      type: "updatePhaseCodeField",
      payload: {
        phaseCode: phaseNum,
        attributeName: "laborData",
        fieldName: "perDiemAmount",
        updatedValue: e.target.value,
        rowId: employee.id,
        filterName: "id",
      },
    });

  const handleLaborNotes = (e) =>
    parDispatch({
      type: "updatePhaseCodeField",
      payload: {
        phaseCode: phaseNum,
        attributeName: "laborData",
        fieldName: "laborNotes",
        updatedValue: e.target.value,
        rowId: employee.id,
        filterName: "id",
      },
    });

  const handleRemoveEmployee = () =>
    handleModal(
      {
        attributeName: "laborData",
        selectedRecord: employee.name,
        filterName: "name",
      },
      "removeEmployee"
    );

  return (
    <tr className="table-row" key={employee.id}>
      <td className="padding-20">
        {isMobileScreen && "Employee Name: "}
        {employee.name}
      </td>
      <td>
        {isMobileScreen && "Employee # "}
        {employee.id}
      </td>

      {phaseNum === "012130-00-00" ? (
        <td>
          {isMobileScreen && <p>Hours Worked </p>}

          <input
            type="text"
            value={getTotalHours(employee.id, parFormDataState, "labor")}
            readOnly
          />
        </td>
      ) : (
        <td>
          {isMobileScreen && <p>Hours Worked </p>}
          <input
            className="employee-hours"
            type="number"
            min="0"
            max="24"
            step={0.25}
            onKeyDown={(e) => e.code === "Minus" && e.preventDefault()}
            value={employee.hoursWorked}
            onWheel={preventNumScroll}
            onBlur={handleEmployeeHourBlur}
            onChange={handleEmployeeHours}
          />
        </td>
      )}

      {phaseNum === "012130-00-00" && (
        <td className="checkbox-container">
          <label htmlFor={`checkmark-small${employee.id}`} />

          {isMobileScreen && <p>Per Diem Y/N </p>}

          <input
            tabIndex="0"
            id={`checkmark-small${employee.id}`}
            className="checkmark-small"
            type="checkbox"
            checked={employee.perDiem}
            onChange={handlePerDiemCheckmark}
          />
        </td>
      )}

      {phaseNum === "012130-00-00" && (
        <td>
          {isMobileScreen && <p>Per Diem Rate </p>}
          <input
            type="number"
            min="0"
            value={employee.perDiemAmount}
            onBlur={handlePerDiemAmountBlur}
            onChange={handlePerDiemAmount}
          />
        </td>
      )}

      <td>
        {isMobileScreen && <p>Labor Notes </p>}
        <input
          type="text"
          value={employee.laborNotes}
          maxLength={150}
          onChange={handleLaborNotes}
        />
      </td>

      <td className="remove-table-row">
        <FontAwesomeIcon
          icon="svg-inline--fa fa-trash"
          onClick={handleRemoveEmployee}
        />
      </td>
    </tr>
  );
};

export default EmployeePhaseItem;
