import { useState, useCallback } from "react";

import ConfirmationModal from "../../../components/modals/confirmation-modal/ConfirmationModal";
import ExpandCollapseSection from "../../../components/core/ExpandCollapseSection";
import PhaseCodeForm from "../../../components/forms/PhaseCodeForm";
import MaterialsSectionItem from "./MaterialsSectionItem";
import Modal from "../../../components/modals/Modal";
import { ParTable } from "../../../components/helpers/ParFormComponents";

import useAbortEffect from "../../../components/hooks/useAbortEffect";

import asyncAPICall from "../../../util/apiWrapper";
import { calculateMaterialTotal } from "../../../components/helpers/calculateHours";
import { useCurrentPar } from "../../../components/contexts/CurrentParContext";
import { useModalContext } from "../../../components/contexts/ModalContext";

const MaterialsSection = (props) => {
  const { setBottomPhaseAdd } = props;

  const { parFormDataState, parDispatch } = useCurrentPar();
  const { handleOpenModal, handleCloseModal, isModalOpen } = useModalContext();
  const { material, checkboxes, jobNumber } = parFormDataState;

  const [uomArray, setUomArray] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState({});
  const [currentMatId, setCurrentMatId] = useState("");
  const [phaseCodeOptions, setPhaseCodeOptions] = useState([]);

  const updateMaterial = useCallback(
    (e, id, updateField) => {
      parDispatch({
        type: "updateMaterial",
        updateField: updateField,
        value: e.target.value,
        id: id,
      });
    },
    [parDispatch]
  );

  const addMaterial = (materialType) => {
    if (
      parFormDataState.material?.length === 1 &&
      parFormDataState.material[0].id === 0
    ) {
      const firstMaterial = {
        id: 1,
        phaseCode: "",
        phaseDesc: "",
        description: "",
        units: "0.00",
        cost: "0.00",
        total: "0.00",
      };

      parDispatch({
        type: "updateInputField",
        payload: {
          field: "material",
          value: [firstMaterial],
        },
      });
    } else {
      parDispatch({
        type: "addMaterial",
        materialType,
      });
    }
  };

  const handleMaterialPhaseCode = (id) => {
    setCurrentMatId(id);
    setBottomPhaseAdd(false);
    handleOpenModal("addMaterialPhaseCode");
  };

  const handleRemoveMaterial = (description, id) => {
    setSelectedMaterial({ description, id });
    handleOpenModal("removeMaterial");
  };

  const deleteMaterial = () => {
    parDispatch({
      type: "deleteMaterial",
      payload: {
        material: selectedMaterial.id,
      },
    });
    handleCloseModal("removeMaterial");
  };

  const renderMaterials = () => {
    return material
      .filter((row) => row.id !== 0)
      .map((row) => {
        return (
          <MaterialsSectionItem
            key={row.id}
            {...row}
            updateMaterial={updateMaterial}
            uomArray={uomArray}
            setBottomPhaseAdd={setBottomPhaseAdd}
            handleMaterialPhaseCode={handleMaterialPhaseCode}
            handleRemoveMaterial={handleRemoveMaterial}
            selectedMaterial={selectedMaterial}
            phaseCodeOptions={phaseCodeOptions}
          />
        );
      });
  };

  useAbortEffect(
    (signal) => {
      if (jobNumber) {
        asyncAPICall(
          `api:E3-qrfOe/vdistinctuom`,
          "GET",
          null,
          null,
          (data) => {
            if (data) {
              setUomArray(data);
            }
          },
          (err) => {
            if (!signal.aborted) {
              console.error("error getting units of measurement (uom)", err);
            }
          },
          signal
        );
      }
    },
    [jobNumber]
  );

  useAbortEffect(
    (signal) => {
      if (jobNumber) {
        asyncAPICall(
          `api:W2oHkD04/vmaterialphases?jobNumber=${jobNumber}`,
          "GET",
          null,
          null,
          (data) => {
            if (data) {
              setPhaseCodeOptions(data);
            }
          },
          (err) => {
            if (!signal.aborted) {
              console.log("Getting Phases Error", err);
            }
          },
          signal
        );
      }
    },
    [jobNumber]
  );

  return (
    <ExpandCollapseSection
      sectionName={"Materials"}
      checkboxValue={checkboxes.material}
    >
      {material?.length > 0 ? (
        <>
          <ParTable
            tableClass="materials-table"
            tableHeaders={[
              { class: "header phase", name: "Phase" },
              { class: "header material", name: "Material" },
              { class: "header units", name: "Units" },
              { class: "header uom", name: "UOM" },
              { class: "header cost", name: "Unit Cost" },
              { class: "header total", name: "Total" },
              { class: "header delete", name: "" },
            ]}
            tableRows={renderMaterials()}
          />
          <div className="material-total-wrapper">
            <h3>
              Total Material Cost:{" $"}
              {calculateMaterialTotal(parFormDataState.material, "total")}
            </h3>
          </div>
        </>
      ) : (
        <h3>No Materials Entered</h3>
      )}

      <div className="material-container">
        <button className="add-button" onClick={() => addMaterial("bhi")}>
          Add Material
        </button>
      </div>

      <ConfirmationModal
        isModalOpen={isModalOpen.removeMaterial}
        onRequestClose={() => handleCloseModal("removeMaterial")}
        modalQuestion={`Are you sure you want to remove: ${
          selectedMaterial.description?.length > 0
            ? selectedMaterial.description
            : `"untitled" `
        }?`}
        onConfirm={() => deleteMaterial()}
      />

      <Modal
        isModalOpen={isModalOpen.addMaterialPhaseCode}
        onRequestClose={() => handleCloseModal("addMaterialPhaseCode")}
      >
        <PhaseCodeForm
          setCurrentMatId={setCurrentMatId}
          currentMatId={currentMatId}
          phaseCodeOptions={phaseCodeOptions}
        />
      </Modal>
    </ExpandCollapseSection>
  );
};
export default MaterialsSection;
