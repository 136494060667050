import { useState, useEffect, forwardRef, useMemo } from "react";

import PhaseItem from "../../pages/par-page/phase-code-section/PhaseItem";
import GeneralModalLayout from "../modals/GeneralModalLayout";

import useDebounce from "../hooks/useDebounce";

import { useCurrentPar } from "../contexts/CurrentParContext";
import { useModalContext } from "../contexts/ModalContext";
import { getTotalHours } from "../helpers/totalHours";

const PhaseCodeForm = forwardRef((props, ref) => {
  const { bottomPhaseAdd, currentMatId, setCurrentMatId, phaseCodeOptions } =
    props;

  const [phasesToRemove, setPhasesToRemove] = useState([]);
  const [filteredData, setFilteredData] = useState(phaseCodeOptions);
  const [phasesToAdd, setPhasesToAdd] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const searchValue = useDebounce(searchInput);
  const {
    parFormDataState,
    parDispatch,
    allPhaseEmployees,
    allPhaseEquipment,
    lastSelected,
  } = useCurrentPar();
  const { handleCloseModal } = useModalContext();

  const { material, phaseCodes } = parFormDataState;

  const currentMaterialPhase = useMemo(
    () =>
      material.filter((material) => material.id === currentMatId).length > 0
        ? material.filter((material) => material.id === currentMatId)[0]
        : null,
    [currentMatId, material]
  );

  const handleSelectPhase = (phase) => {
    if (!currentMaterialPhase) {
      setPhasesToRemove((prev) =>
        prev.filter((phaseCode) => phaseCode.Phase !== phase.Phase)
      );
      setPhasesToAdd((prev) => [...prev, phase]);
    } else {
      setPhasesToAdd([phase]);
    }
  };

  const handleRemovePhase = (phase) => {
    if (!currentMaterialPhase) {
      setPhasesToAdd((prev) =>
        prev.filter((phaseCode) => phaseCode.Phase !== phase.Phase)
      );
      setPhasesToRemove((prev) => [...prev, phase]);
    }
  };

  const renderData = () => {
    return filteredData.map((phaseObject) => {
      const selectedPhaseCodes = phasesToAdd.map((phaseObj) => phaseObj.Phase);

      const phaseCodesToRemove = phasesToRemove.map(
        (phaseObj) => phaseObj.Phase
      );

      let isPhaseCodeSelected =
        (!currentMaterialPhase &&
          Object.keys(phaseCodes).includes(phaseObject.Phase) &&
          !phaseCodesToRemove.includes(phaseObject.Phase)) ||
        selectedPhaseCodes.includes(phaseObject.Phase) ||
        (currentMaterialPhase?.phaseCode === phaseObject.Phase &&
          phasesToAdd.length === 0) ||
        (!currentMaterialPhase &&
          phasesToAdd.filter((phase) => phase.Phase === phaseObject.Phase)
            .length === 1);

      return (
        <PhaseItem
          phaseObject={phaseObject}
          key={phaseObject.id}
          isPhaseCodeSelected={isPhaseCodeSelected}
          handleSelectPhase={handleSelectPhase}
          handleRemovePhase={handleRemovePhase}
        />
      );
    });
  };

  const addPhase = (phase, description) => {
    const employeeIDList = [];
    const employeeList = [];
    const equipList = [];
    const payload = {
      phaseCode: phase,
      attributeName: "laborData",
    };
    const equipPayload = {
      phaseCode: phase,
      attributeName: "equipmentData",
    };

    if (currentMatId || currentMatId === 0) {
      parDispatch({
        type: "updateMaterial",
        updateField: "phaseCode",
        value: phase,
        id: currentMatId,
      });

      parDispatch({
        type: "updateMaterial",
        updateField: "phaseDesc",
        value: description,
        id: currentMatId,
      });
      return;
    }

    if (phase !== "012130-00-00") {
      allPhaseEmployees.forEach((employee) => {
        employeeList.push(employee);
      });
      allPhaseEquipment.forEach((equipment) => {
        equipList.push(equipment);
      });
    }

    if (phase === "012130-00-00") {
      Object.keys(phaseCodes).forEach((phaseCode) => {
        phaseCodes[phaseCode].laborData.forEach((employee) => {
          if (!employeeIDList.includes(employee.id)) {
            const employeeCopy = {
              name: "",
              id: "",
              laborNotes: "",
            };

            employeeCopy.name = employee.name;
            employeeCopy.id = employee.id;
            employeeCopy.laborNotes = employee.laborNotes;

            if (phase === "012130-00-00") {
              employeeCopy.combinedPhaseTime = getTotalHours(
                employee.id,
                parFormDataState,
                "labor"
              );
              employeeCopy.perDiemAmount = "";
              employeeCopy.perDiem = false;
            } else {
              employeeCopy.hoursWorked = "0";
            }

            employeeIDList.push(employee.id);
            employeeList.push(employeeCopy);
          }
        });
      });
    }

    parDispatch({
      type: "addPhaseCode",
      payload: {
        phase: phase,
        description: description,
      },
    });
    if (employeeList.length > 0) {
      payload.newRecords = employeeList;
      parDispatch({
        type: "addRecord",
        payload: payload,
      });
    }
    if (equipList.length > 0) {
      equipPayload.newRecords = equipList;
      parDispatch({
        type: "addRecord",
        payload: equipPayload,
      });
    }
    if (bottomPhaseAdd && lastSelected.current)
      lastSelected.current = `${phase} (${description})`;
  };

  const removePhase = (phase, description) => {
    parDispatch({
      type: "removePhaseCode",
      payload: {
        selectedPhase: phase,
      },
    });

    if (!currentMaterialPhase) {
      ref?.current.splice(
        ref.current.indexOf(
          ref.current.find((el) => {
            return el?.id === `${phase} (${description})`;
          })
        ),
        1
      );

      lastSelected.current = null;
    }
  };

  const handleClose = () => {
    handleCloseModal("addPhaseCode");
    handleCloseModal("bottomPhaseCode");
    handleCloseModal("addMaterialPhaseCode");
  };

  const handleSubmit = () => {
    if (phasesToAdd.length > 0) {
      phasesToAdd.forEach((phaseObject) =>
        addPhase(phaseObject.Phase, phaseObject.Description)
      );
    }

    if (phasesToRemove.length > 0) {
      phasesToRemove.forEach((phaseObject) =>
        removePhase(phaseObject.Phase, phaseObject.Description)
      );
    }

    handleClose();
  };

  useEffect(() => {
    const checkForMatch = (phaseObject) => {
      const lowerCaseDescription = phaseObject.Description.toLowerCase().trim();
      return (
        phaseObject.Phase.trim().includes(searchValue) ||
        lowerCaseDescription.includes(searchValue.toLowerCase())
      );
    };
    setFilteredData(phaseCodeOptions.filter(checkForMatch));
  }, [searchValue, phaseCodeOptions]);

  useEffect(() => {
    if (setCurrentMatId) {
      return () => setCurrentMatId("");
    }
  }, [setCurrentMatId]);

  return (
    <GeneralModalLayout
      title={"Select Phase Codes"}
      renderModalInfo={renderData}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      setSearchValue={setSearchInput}
    />
  );
});

export default PhaseCodeForm;
