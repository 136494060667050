import ConfirmationModal from "../../components/modals/confirmation-modal/ConfirmationModal";
import ApproverChanges from "../../components/ApproverChanges";
import ExternalLink from "../../components/core/ExternalLink";
import { useLocation } from "react-router-dom";
import { errorToast } from "../../util/toastNotifications";

import { useCurrentPar } from "../../components/contexts/CurrentParContext";
import { useModalContext } from "../../components/contexts/ModalContext";

export default function ParHeader({ handleModalResetPar }) {
  const { parFormDataState, lastSaved } = useCurrentPar();
  const { handleOpenModal, handleCloseModal, isModalOpen } = useModalContext();
  const location = useLocation();
  const isFromApprovals = location.state?.fromApprovals;

  const handleResetClick = () => {
    if (isFromApprovals) {
      errorToast("Cannot reset PAR while in approvals mode");
      return;
    }
    handleOpenModal("resetPar");
  };

  return (
    <div className="header-container">
      <div className="reset-information-wrapper">
        <ExternalLink
          text={`PAR\nInstructions`}
          link="https://scribehow.com/shared/PAR_Submitter__gurwseo3Rxu1Hu-diK0G9Q"
        />

        <div
          className={`reset-par ${isFromApprovals ? "disabled" : ""}`}
          onClick={handleResetClick}
        >
          Reset PAR
        </div>
      </div>

      <div className="par-message">Status: {parFormDataState.dcrStatus}</div>
      <div className="par-message">{lastSaved}</div>
      <h1 className={`par-header ${isFromApprovals ? "approvals-header" : ""}`}>
        {isFromApprovals ? "PAR EDITABLE APPROVALS" : "PROJECT ACTIVITY RECORD"}
      </h1>
      <div>
        {parFormDataState.statusComments?.length > 0 && (
          <ApproverChanges parData={parFormDataState} color={"red"} />
        )}
      </div>

      <ConfirmationModal
        isModalOpen={isModalOpen.resetPar}
        onRequestClose={() => handleCloseModal("resetPar")}
        modalQuestion={"Are you sure you want to clear your PAR?"}
        onConfirm={() => handleModalResetPar("resetPar")}
      />
    </div>
  );
}
