const PhaseItem = (props) => {
  const {
    phaseObject,
    isPhaseCodeSelected,
    handleSelectPhase,
    handleRemovePhase,
  } = props;

  return (
    <div
      className={`selectable-item-wrapper ${
        isPhaseCodeSelected ? "selected" : ""
      }`}
      onClick={
        isPhaseCodeSelected
          ? () => handleRemovePhase(phaseObject)
          : () => handleSelectPhase(phaseObject)
      }
      data-title={phaseObject.Description}
    >
      <p>{`${phaseObject.Phase}(${phaseObject.Description})`}</p>
    </div>
  );
};

export default PhaseItem;
