import { useState, useEffect, useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";

import HoursWorked from "./HoursWorked";
import {
  ParInput,
  ParSelect,
} from "../../../components/helpers/ParFormComponents";

import useAbortEffect from "../../../components/hooks/useAbortEffect";

import asyncAPICall from "../../../util/apiWrapper";
import { useCurrentPar } from "../../../components/contexts/CurrentParContext";
import { checkSameDayPar } from "../../../components/helpers/checkSameDayPar";
import { useModalContext } from "../../../components/contexts/ModalContext";
import { useAuthInfo } from "../../../components/contexts/AuthContext";
import { errorToast } from "../../../util/toastNotifications";
import ParJobInformationButtons from "./ParJobInformationButtons";
import WeatherInformation from "./WeatherInformation";
import {
  getPayPeriodDates,
  formatDateForInput,
  isDateInAllowedPayPeriods,
  getCurrentDate,
  logDateInformation,
  getDateSelectionMessage,
} from "../../../util/payPeriodUtils";

const ParJobInformation = (props) => {
  const { formattedDate, setFormattedDate, handleAddPhaseCode } = props;

  const [headerToggle, setHeaderToggle] = useState(false);
  const [pfsList, setPfsList] = useState([]);
  const [jobInfoComplete, setJobInfoComplete] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const { user } = useAuthInfo();
  const { handleOpenModal, handleCloseModal } = useModalContext();
  const {
    parFormDataState,
    parDispatch,
    resetting,
    handleResetting,
    allPhaseEmployees,
    handleAllPhaseEmployees,
    allPhaseEquipment,
    handleAllPhaseEquipment,
  } = useCurrentPar();

  const {
    pfs,
    jobNumber,
    dcrNumber,
    foreman,
    wind,
    precipitation,
    temperatureHigh,
    temperatureLow,
    date,
    safetyTopic,
    startTime,
    stopTime,
    forecast,
  } = parFormDataState;

  const basicFieldUpdate = useCallback(
    (value, field, type) => {
      parDispatch({
        type,
        payload: {
          field,
          value,
        },
      });
    },
    [parDispatch]
  );

  const handleHasDateCheck = () => {
    if (formattedDate) {
      handleOpenModal("addJob");
    } else {
      errorToast("Please choose a date before adding a job number.");
    }
  };

  useAbortEffect(
    (signal) => {
      if (jobNumber && parFormDataState.foreman) {
        checkSameDayPar(jobNumber, date, parFormDataState.foreman).then(
          (res) => {
            if (res && !dcrNumber) {
              errorToast(
                `A PAR has already been created for ${jobNumber} with your user credentials for ${formattedDate}.`
              );
              basicFieldUpdate("", "jobNumber", "updateInputField");
              handleCloseModal("addJob");
            } else {
              const multipleApproverPromise = new Promise((res, rej) => {
                asyncAPICall(
                  `api:W2oHkD04/vmultipleapproversbyjob?job=${jobNumber}`,
                  "GET",
                  null,
                  null,
                  (data) => {
                    if (data.length > 0) {
                      setPfsList(data);
                      res(true);
                    } else {
                      setPfsList([]);
                      errorToast("No Approvers Assinged To Job");
                      res(true);
                    }
                  },
                  (err) => {
                    if (signal.aborted) {
                      res(true);
                    } else {
                      rej(err);
                      console.error("Get PM Error", err);
                    }
                  },
                  signal
                );
              });

              multipleApproverPromise
                .then(
                  () => {
                    if (!signal.aborted) {
                      handleCloseModal("addJob");
                      handleResetting(false);
                    }
                  },
                  (err) => console.log("Failed to get subcontractor", err)
                )
                .catch((err) => console.error(err));
            }
          }
        );
      }
    },
    [
      jobNumber,
      basicFieldUpdate,
      parDispatch,
      date,
      formattedDate,
      parFormDataState.foreman,
      dcrNumber,
    ]
  );

  useEffect(() => {
    parDispatch({
      type: "updateInputField",
      payload: {
        field: "foreman",
        value: user.employeeID,
      },
    });
  }, [parDispatch, user.employeeID]);

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("allPhaseEmployees"));
    if (savedData) {
      handleAllPhaseEmployees(
        JSON.parse(localStorage.getItem("allPhaseEmployees"))
      );
    }
  }, [handleAllPhaseEmployees]);

  useEffect(() => {
    localStorage.setItem(
      "allPhaseEmployees",
      JSON.stringify(allPhaseEmployees)
    );
  }, [allPhaseEmployees]);

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("allPhaseEquipment"));
    if (savedData) {
      handleAllPhaseEquipment(
        JSON.parse(localStorage.getItem("allPhaseEquipment"))
      );
    }
  }, [handleAllPhaseEquipment]);

  useEffect(() => {
    localStorage.setItem(
      "allPhaseEquipment",
      JSON.stringify(allPhaseEquipment)
    );
  }, [allPhaseEquipment]);

  useEffect(() => {
    if (
      pfs !== 0 &&
      foreman.length !== "" &&
      jobNumber.length > 0 &&
      date.length > 0 &&
      forecast.length > 0 &&
      precipitation.length > 0 &&
      temperatureHigh.length > 0 &&
      temperatureLow.length > 0 &&
      wind.length > 0 &&
      safetyTopic.length > 0 &&
      startTime.length > 0 &&
      stopTime.length > 0
    ) {
      setJobInfoComplete(true);
    } else {
      setJobInfoComplete(false);
    }
  }, [
    pfs,
    foreman,
    jobNumber,
    date,
    forecast,
    precipitation,
    temperatureHigh,
    temperatureLow,
    wind,
    safetyTopic,
    startTime,
    stopTime,
  ]);

  const formatDateYearEnd = (date) => {
    const splitDate = date.split("-");
    const year = splitDate[0].slice(2);
    const arrangedDate = `${splitDate[1]}/${splitDate[2]}/${year}`;

    return arrangedDate;
  };

  useEffect(() => {
    const newDate = formatDateYearEnd(formattedDate);
    if (formattedDate === "") {
      basicFieldUpdate("", "date", "updateInputField");
    } else {
      basicFieldUpdate(newDate, "date", "updateInputField");
    }
  }, [formattedDate, basicFieldUpdate]);

  useAbortEffect((signal) => {
    const pathName = window.location.pathname;
    const pathNameArray = pathName.split("+");
    const dcrNumber = pathNameArray[1];
    if (dcrNumber) {
      const getParReportPromise = new Promise((resolve, reject) => {
        asyncAPICall(
          `api:W2oHkD04/partblmain/parNumber?parNumber=${dcrNumber}`,
          "GET",
          null,
          null,
          (data) => {
            parDispatch({
              type: "setParForm",
              form: data,
            });
            resolve(true);
          },
          (err) => reject("Error PAR report", err),
          signal
        );
      });

      getParReportPromise.catch(console.error);
    }
  }, []);

  const currentPayPeriod = getPayPeriodDates(new Date());

  useEffect(() => {
    logDateInformation();
  }, []);

  return (
    <div className="par-information-container">
      <button
        className={`job-information ${
          jobInfoComplete ? "" : "header-alternate"
        }`}
        onClick={() => setHeaderToggle((prevToggle) => !prevToggle)}
      >
        JOB INFORMATION
      </button>
      <div
        className={`main-content-container ${
          headerToggle ? "hide-header" : ""
        }`}
      >
        <div className="job-information-wrapper">
          <ParInput
            type="date"
            label="Date"
            customClass="date-container"
            value={formattedDate}
            onChange={(e) => {
              const selectedDate = new Date(e.target.value + "T00:00:00");
              if (!isDateInAllowedPayPeriods(selectedDate)) {
                const current = getPayPeriodDates(getCurrentDate());
                const message = `
                  ${getDateSelectionMessage(current)}
                  <br/><br/>
                  Due to BHI's policy, you will need to fill out a payroll correction form.
                  <br/><br/>
                  <a 
                    href="https://form.asana.com/?k=vusqZD1ncVz8iJd2bKMAew&d=511103130615243" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    class="toast-link"
                  >
                    Open Payroll Request Form
                  </a>
                `;

                errorToast(message, true);
                setFormattedDate("");
                basicFieldUpdate("", "date", "updateInputField");
                return;
              }

              history.replace({
                ...location,
                state: {
                  ...location.state,
                  dateChangeOnly: true,
                },
              });

              setFormattedDate(e.target.value);
            }}
            min={formatDateForInput(currentPayPeriod.start)}
            max={formatDateForInput(currentPayPeriod.end)}
            disabled={resetting}
          />

          <div className="par-button-wrapper">
            <button
              className="add-job"
              onClick={handleHasDateCheck}
              disabled={resetting}
            >
              Add Job Number
            </button>
          </div>

          <div className="job-number-wrapper">
            <label htmlFor="job-number">Job Number</label>
            <input
              type="text"
              value={jobNumber}
              name="job"
              id="job-number"
              readOnly
            />
          </div>

          <ParSelect
            label="Approver"
            customClass="pfs-container"
            idKey="Approver"
            options={pfsList}
            value={pfs}
            displayKeys={["FullName"]}
            onChange={(e) => {
              parDispatch({
                type: "updatePfs",
                payload: {
                  pfs: Number(e.target.value),
                },
              });
            }}
            disabled={resetting}
          />

          <ParJobInformationButtons handleAddPhaseCode={handleAddPhaseCode} />

          <ParInput
            type="text"
            label="Safety Topic"
            customClass="safety-topic-container"
            value={parFormDataState.safetyTopic}
            onChange={(e) =>
              basicFieldUpdate(
                e.target.value,
                "safetyTopic",
                "updateInputField"
              )
            }
            disabled={resetting}
          />
        </div>

        <div className="job-information-wrapper">
          <WeatherInformation />

          <HoursWorked />
        </div>
      </div>
    </div>
  );
};

export default ParJobInformation;
