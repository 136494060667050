import { useState, useEffect } from "react";

import GeneralModalLayout from "../modals/GeneralModalLayout";
import HrvcEmployeeItem from "./HrvcEmployeeItem";

import useAbortEffect from "../hooks/useAbortEffect";
import useDebounce from "../hooks/useDebounce";

import asyncAPICall from "../../util/apiWrapper";

const AddHrvcEmployee = ({
  closeModal,
  setEmployeeInfo,
  setSupervisorInfo,
  isSupervisor,
}) => {
  const [employees, setEmployees] = useState([]);
  const [filter, setFilter] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedData, setSelectedData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const debounceValue = useDebounce(searchValue);

  const submit = () => {
    isSupervisor === true
      ? setSupervisorInfo(selectedData)
      : setEmployeeInfo(selectedData);
    closeModal();
  };

  const renderData = () => {
    return filter.map((p) => {
      return (
        <HrvcEmployeeItem
          data={p}
          key={p.employeeID}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
        />
      );
    });
  };

  useAbortEffect((signal) => {
    asyncAPICall(
      "api:KE7MCNYf/vemployeesnospaces",
      "GET",
      null,
      null,
      (data) => {
        if (data) {
          setEmployees(data.resource);
          setFilter(data.resource);
          setIsLoading(false);
        }
      },
      (err) => {
        if (!signal.aborted) {
          console.log("Getting employees Error", err);
        }
      },
      signal
    );
  }, []);

  useEffect(() => {
    setFilter(
      employees.filter(
        (employeeObject) =>
          employeeObject.LastName.toLowerCase().includes(
            debounceValue.toLowerCase()
          ) ||
          employeeObject.FirstName.toLowerCase().includes(
            debounceValue.toLowerCase()
          )
      )
    );
  }, [debounceValue, employees]);

  return (
    <GeneralModalLayout
      title={"Select Employees"}
      isLoading={isLoading}
      renderModalInfo={renderData}
      handleSubmit={submit}
      setSearchValue={setSearchValue}
    />
  );
};

export default AddHrvcEmployee;
