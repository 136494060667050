import { useEffect, useState, useRef } from "react";

import ConfirmationModal from "../../../components/modals/confirmation-modal/ConfirmationModal";
import OvernightCheckbox from "./OvernightCheckbox";
import { ParInput } from "../../../components/helpers/ParFormComponents";

import { useCurrentPar } from "../../../components/contexts/CurrentParContext";
import { useModalContext } from "../../../components/contexts/ModalContext";

const HoursWorked = () => {
  const [overnightAnsweredModal, setOvernightAnsweredModal] = useState(true);
  const [timeChange, setTimeChange] = useState(false);
  const [parHours, setParHours] = useState(0);
  const [startHours, setStartHours] = useState(0);
  const [endHours, setEndHours] = useState(0);

  const stopTimeRef = useRef(null);

  const { parFormDataState, resetting, parDispatch } = useCurrentPar();
  const { startTime, stopTime, checkboxes } = parFormDataState;
  const { handleOpenModal, handleCloseModal, isModalOpen } = useModalContext();

  const handleIsOverNight = (overnight) => {
    handleCloseModal("isOvernight");

    setOvernightAnsweredModal(true);

    parDispatch({
      type: "updateCheckbox",
      payload: {
        field: "overnight",
        value: overnight,
      },
    });
  };

  const timeUpdate = (value, field, type) => {
    parDispatch({
      type,
      payload: {
        field,
        value,
      },
    });
  };

  const handleTimeBlur = () => {
    setTimeChange(true);

    if (checkboxes.overnight === true && overnightAnsweredModal === false) {
      handleOpenModal("isOvernight");

      parDispatch({
        type: "updateCheckbox",
        payload: {
          field: "overnight",
          value: !checkboxes.overnight,
        },
      });
    }
  };

  const handleOvernightCheckbox = () => {
    parDispatch({
      type: "updateCheckbox",
      payload: {
        field: "overnight",
        value: !checkboxes.overnight,
      },
    });

    if (stopTime < startTime) {
      if (checkboxes.overnight === true) {
        setParHours(`Enter Valid Stop Time`);
      } else {
        setOvernightAnsweredModal(true);
      }
    } else {
      setOvernightAnsweredModal(true);
    }
  };

  const handleOvernightModalClose = () => {
    setParHours(`Enter Valid Stop Time`);
    handleCloseModal("isOvernight");
  };

  useEffect(() => {
    if (
      !(startTime === "" || stopTime === "") &&
      startTime !== "" &&
      stopTime !== "" &&
      stopTime < startTime &&
      timeChange
    ) {
      setTimeChange(false);
    }
  }, [startTime, stopTime, timeChange]);

  useEffect(() => {
    setOvernightAnsweredModal(false);
  }, [resetting]);

  useEffect(() => {
    setStartHours(startTime.slice(0, 2));
    setEndHours(stopTime.slice(0, 2));
    const startMinutes = startTime.slice(3, 5);
    const endMinutes = stopTime.slice(3, 5);
    let minutes = String(endMinutes - startMinutes);
    let hours = String(endHours - startHours);

    const intMin = Number(minutes);

    if (intMin < 0) {
      hours = String(Number(hours) - 1);
      minutes = String(Number(minutes) + 60);
    }

    if (minutes.length < 2) {
      minutes = `0${minutes}`;
    }

    if (Number(hours) <= 0 && startHours !== "" && endHours !== "") {
      if (
        startTime > stopTime &&
        checkboxes.overnight === false &&
        overnightAnsweredModal === false
      ) {
        stopTimeRef.current?.blur();

        handleOpenModal("isOvernight");

        return;
      } else if (startTime > stopTime && checkboxes.overnight === false) {
        setParHours("Enter Valid Stop Time");
        return;
      }

      hours = 24 - Number(startHours) + Number(endHours);

      if (hours > 23) {
        hours -= 24;
      }

      if (Number(endMinutes) < Number(startMinutes)) {
        hours--;
      }

      if (hours === -1) {
        hours = 0;
      }

      setParHours(`${hours}:${minutes}`);
    } else if (startHours !== "" && endHours !== "") {
      setParHours(`${hours}:${minutes}`);
    } else {
      setParHours("");
    }
  }, [
    startTime,
    stopTime,
    endHours,
    startHours,
    setParHours,
    overnightAnsweredModal,
    checkboxes.overnight,
    parFormDataState.dcrNumber,
    handleOpenModal,
    stopTimeRef,
  ]);

  return (
    <div className="hours-worked-wrapper">
      <ParInput
        type="time"
        label="Start Time"
        customClass="time-input-wrapper"
        value={startTime}
        onChange={(e) =>
          timeUpdate(e.target.value, "startTime", "updateInputField")
        }
        onBlur={() => handleTimeBlur()}
        disabled={resetting}
      />

      <div className="time-input-wrapper">
        <label htmlFor="stop-time-input">Stop Time</label>
        <input
          type="time"
          id="stop-time-input"
          value={stopTime}
          ref={stopTimeRef}
          onChange={(e) => {
            timeUpdate(e.target.value, "stopTime", "updateInputField");
          }}
          onBlur={() => handleTimeBlur()}
          disabled={resetting}
        />
      </div>

      <OvernightCheckbox handleOvernightCheckbox={handleOvernightCheckbox} />

      <div>
        <label htmlFor="hours-worked">Hours Worked</label>
        <input
          type="text"
          value={parHours}
          name="hours-worked"
          id="hours-worked"
          readOnly
        />
      </div>

      <ConfirmationModal
        isModalOpen={isModalOpen.isOvernight}
        onRequestClose={handleOvernightModalClose}
        onConfirm={() => handleIsOverNight(true)}
        modalQuestion={
          <p>
            Does this include <u>Overnight</u> work?
          </p>
        }
      />
    </div>
  );
};

export default HoursWorked;
