import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import SiteActivityCheckboxSection from "./site-activity-checkbox-section/SiteActivityCheckboxSection";
import ConfirmationModal from "../../components/modals/confirmation-modal/ConfirmationModal";
import ParJobInformation from "./job-information-section/ParJobInformation";
import PhaseCodesSection from "./phase-code-section/PhaseCodesSection";
import JournalEntriesSection from "./JournalEntriesSection";
import ParHeader from "./ParHeader";
import ActionButtons from "../../components/core/ActionButtons";
import VerificationTotals from "../verification-page/VerificationTotals";

import { useCurrentPar } from "../../components/contexts/CurrentParContext";
import { useModalContext } from "../../components/contexts/ModalContext";

import { convertToInputDate, todaysFormattedDate } from "../../util/dayString";
import asyncAPICall from "../../util/apiWrapper";
import { errorToast } from "../../util/toastNotifications";
import { initializeCheckboxes } from "../../components/reducer/parFormReducer";

const ParPage = () => {
  const [bottomPhaseAdd, setBottomPhaseAdd] = useState(false);
  const [formattedDate, setFormattedDate] = useState("");
  const [parHours, setParHours] = useState(0);
  const [rejectMessage, setRejectMessage] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const location = useLocation();

  const params = useParams();

  const { isModalOpen, handleOpenModal, handleCloseModal } = useModalContext();
  const {
    parDispatch,
    parFormDataState,
    handleResetting,
    resetPar,
    phaseCodesRef,
  } = useCurrentPar();
  const { jobNumber, date, dcrNumber } = parFormDataState;

  const handleModalResetPar = (modalType) => {
    handleResetting(true);
    resetPar();
    setFormattedDate("");
    parDispatch({
      type: "updateInputField",
      payload: {
        field: "date",
        value: "",
      },
    });
    handleCloseModal(modalType);
    localStorage.clear();
  };

  useEffect(() => {
    if (
      dcrNumber &&
      !location.state?.bypassWarningModal &&
      !params?.slug &&
      !location.state?.fromApprovals
    ) {
      handleOpenModal("editExistingPar");
    }
  }, [dcrNumber, location.state, params?.slug, handleOpenModal]);

  useEffect(() => {
    if (!formattedDate && date) {
      setFormattedDate(convertToInputDate(date));
    }
  }, [date, formattedDate]);

  useEffect(() => {
    const fetchParData = async () => {
      if (!location.state?.fromApprovals || !params?.slug) return;

      try {
        const data = await new Promise((resolve, reject) => {
          asyncAPICall(
            `api:W2oHkD04/partblmain/parNumber?parNumber=${params.slug}`,
            "GET",
            null,
            async (res) => {
              if (res.ok) {
                const jsonData = await res.json();
                resolve(jsonData);
              } else {
                reject(new Error("API response not ok"));
              }
            },
            (data) => {
              resolve(data);
            },
            (err) => {
              console.error("Error callback:", err);
              reject(err);
            }
          );
        });

        if (!data) {
          return;
        }

        const initializedData = {
          ...data,
          checkboxes: initializeCheckboxes(data),
        };

        parDispatch({
          type: "setParForm",
          form: initializedData,
        });

        setFormattedDate(convertToInputDate(data.date));
      } catch (err) {
        errorToast("Error loading PAR data");
      }
    };

    fetchParData();
  }, [location.state?.fromApprovals, params?.slug, parDispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (location.state?.preserveContext && location.state?.parFormData) {
      parDispatch({
        type: "setParForm",
        form: location.state.parFormData,
      });

      return () => {
        setTimeout(() => {
          window.history.replaceState(
            { bypassWarningModal: true },
            "",
            location.pathname
          );
        }, 100);
      };
    }
  }, [
    location.state?.preserveContext,
    location.state?.parFormData,
    parDispatch,
    location.pathname,
  ]);

  const containerClass = `par-page-container${
    location?.pathname?.startsWith("/approvals/") ? " approvals-mode" : ""
  }`;

  const handleRejectMessage = (message) => {
    const copyParData = { ...parFormDataState };
    copyParData.statusComments = [
      ...(copyParData.statusComments || []),
      {
        id: uuidv4(),
        date: todaysFormattedDate(),
        status: "Reject",
        message: message,
      },
    ];
    return copyParData;
  };

  return (
    <div className={containerClass}>
      <ParHeader handleModalResetPar={handleModalResetPar} />

      <ParJobInformation
        setFormattedDate={setFormattedDate}
        bottomPhaseAdd={bottomPhaseAdd}
        formattedDate={formattedDate}
        parHours={parHours}
        setParHours={setParHours}
      />

      <JournalEntriesSection />

      <SiteActivityCheckboxSection setBottomPhaseAdd={setBottomPhaseAdd} />

      <PhaseCodesSection phaseCodesRef={phaseCodesRef} />

      <VerificationTotals
        formData={parFormDataState}
        windowWidth={windowWidth}
      />

      <ActionButtons
        parData={parFormDataState}
        rejectMessage={rejectMessage}
        setRejectMessage={setRejectMessage}
        handleRejectMessage={handleRejectMessage}
      />

      <ConfirmationModal
        isModalOpen={isModalOpen.editExistingPar}
        onRequestClose={() => handleCloseModal("editExistingPar")}
        modalQuestion={`Are you still working on this PAR?\n\nDate: ${date}\nJob: ${jobNumber}`}
        questionClassName="edit-par-message"
        onConfirm={() => handleModalResetPar("editExistingPar")}
        yesButtonText={"Create New"}
        noButtonText={"Resume Editing"}
      />
    </div>
  );
};

export default ParPage;
