import { useState } from "react";
import { NavLink } from "react-router-dom";

import useClickOutside from "../hooks/useClickOutside";
import { useAuthInfo } from "../contexts/AuthContext";

export default function HamburgerMenu() {
  const [isOpen, setIsOpen] = useState(false);

  const menuRef = useClickOutside(() => setIsOpen(false));
  const { logout } = useAuthInfo()

  return (
    <div className="hamburger-menu-container" ref={menuRef}>
      <button
        className="hamburger-menu"
        onClick={() => {
          setIsOpen((prev) => !prev);
        }}
      />

      <div className={isOpen ? "expanded" : "navigation-menu"}>
        <NavLink
          className="page-link nav-item"
          to="/overview"
          onClick={() => setIsOpen(false)}
        >
          OVERVIEW
        </NavLink>

        <NavLink
          className="page-link nav-item"
          to="/par"
          onClick={() => setIsOpen(false)}
        >
          NEW PAR
        </NavLink>

        <NavLink
          className="page-link nav-item"
          exact
          to="/approvals"
          onClick={() => setIsOpen(false)}
        >
          APPROVALS
        </NavLink>

        <NavLink className="page-link nav-item" to="/login" onClick= { logout } >
          LOGOUT
        </NavLink>
      </div>
    </div>
  );
}
