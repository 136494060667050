import { Link } from "react-router-dom";
import { useAuthInfo } from "../contexts/AuthContext";

import Logo from "../../img/logo.svg";
import HamburgerMenu from "./HamburgerMenu";

export default function Navbar() {
  const { user } = useAuthInfo();

  return (
    <div className="navbar-wrapper">
      <div className="bhi-logo-container">
        <div className="user-name">{user.name}</div>

        <Link className="logo-wrapper nav-item" to="/overview">
          <img src={Logo} alt="BHI Main Logo" />
        </Link>
      </div>

      <HamburgerMenu />
    </div>
  );
}
