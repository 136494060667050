import asyncAPICall from "../../util/apiWrapper";

export const checkSameDayPar = async (
  jobNumber,
  date,
  foreman,
  signal = null
) => {
  let sameDayParValue = new Promise((res, rej) => {
    asyncAPICall(
      `api:W2oHkD04/partblmainByJob?job=${jobNumber}&date=${date}&submitter=${foreman}`,
      "GET",
      null,
      null,
      (data) => {
        if (jobNumber.startsWith("16602-") || data.length === 0) {
          res(false);
        } else {
          res(true);
        }
      },
      (err) => {
        rej(err);
      },
      signal
    );
  });

  return sameDayParValue;
};
