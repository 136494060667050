import { useState } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ConfirmationModal from "../../components/modals/confirmation-modal/ConfirmationModal";
import RejectCommentsForm from "../../components/forms/RejectCommentsForm";
import Modal from "../../components/modals/Modal";
import { useCurrentPar } from "../../components/contexts/CurrentParContext";
import { useModalContext } from "../../components/contexts/ModalContext";

import asyncAPICall from "../../util/apiWrapper";
import { errorToast, successfulToast } from "../../util/toastNotifications";

const PendingPars = ({ userSubmittedPars, getUserSubmittedPars }) => {
  const { parDispatch } = useCurrentPar();

  const [selectedPAR, setSelectedPAR] = useState({});
  const reportsCopy = [...userSubmittedPars];
  const history = useHistory();

  const { isModalOpen, handleOpenModal, handleCloseModal } = useModalContext();

  const localStoragePar = JSON.parse(localStorage.getItem("formData"));

  const handleDeletePendingPar = (par) => {
    setSelectedPAR(par);
    handleOpenModal("deletePar");
  };

  const handleConfirmEditPar = (par) => {
    if (localStoragePar?.date) {
      setSelectedPAR(par);
      handleOpenModal("confirmEditPar");
    } else {
      handleEditPar(par);
    }
  };

  const handleDeactivatePAR = () => {
    const fetchedReports = new Promise((resolve, reject) => {
      asyncAPICall(
        `api:W2oHkD04/partblmain/deactivatePAR?dcrNumber=${selectedPAR.dcrNumber}`,
        "POST",
        null,
        (res) => {
          if (res.ok) {
            successfulToast(
              `Successfully deleted ${selectedPAR.jobNumber} on ${selectedPAR.date}`
            );
            resolve(true);
          }
        },
        null,
        (err) => {
          errorToast(
            `Something went wrong with deleting ${selectedPAR.jobNumber} on ${selectedPAR.date}`
          );

          reject(console.error(err));
        }
      );
    });

    fetchedReports
      .then(() => getUserSubmittedPars())
      .catch((err) => console.error("Deactivating PAR error: ", err))
      .finally(() => handleCloseModal("deletePar"));
  };

  const handleEditPar = (parFormData) => {
    const checkboxValues = {};

    if (!parFormData.checkboxes) {
      const checks = ["incidents", "injuries"];

      checkboxValues["issues"] =
        parFormData.idb[0]?.issueNumber === "0" ? false : true;

      checkboxValues["subcontractor"] =
        parFormData.subcontractor[0]?.name === "none" ? false : true;

      checks.forEach((check) => {
        checkboxValues[check] =
          parFormData[check] === "Nothing to Report." ? false : true;
      });

      checkboxValues["timeMaterials"] =
        parFormData.matCustomerCode === "" ? false : true;

      checkboxValues["overnight"] =
        parFormData.startTime > parFormData.stopTime ? true : false;

      if (parFormData.material[0]) {
        checkboxValues["material"] =
          parFormData.material[0].id === 0 ? false : true;
      } else {
        checkboxValues["material"] = false;
      }

      parFormData["checkboxes"] = checkboxValues;
    }

    localStorage.setItem("formData", JSON.stringify(parFormData));

    parDispatch({
      type: "setParForm",
      form: parFormData,
    });

    handleCloseModal("confirmEditPar");
    history.push(`/par/${parFormData.dcrNumber}`);
  };

  reportsCopy.sort((a, b) => a.created_at - b.created_at);

  const parList = reportsCopy.map((report) => {
    let statusColor = "";
    let statusIcon = "";

    switch (report.dcrStatus) {
      case "Pending":
        statusColor = "blue-gradient";
        statusIcon = "fas fa-circle-notch";
        break;
      case "Submitted":
        statusColor = "blue-gradient";
        statusIcon = "fas fa-check-circle";
        break;
      case "Reject":
        statusColor = "red-gradient";
        statusIcon = "fas fa-exclamation-circle";
        break;
      default:
        statusColor = "";
        break;
    }

    if (statusColor.length > 0) {
      return (
        <div className={`par-box ${statusColor}`} key={report.id}>
          <FontAwesomeIcon icon={`${statusIcon}`} />
          <h3>{report.date}</h3>
          <h4>{report.jobNumber}</h4>

          <div
            className="overview-report-link"
            onClick={() => handleConfirmEditPar(report)}
          >
            Edit PAR
          </div>

          {report.statusComments.length > 0 && (
            <div
              className="overview-report-link"
              onClick={() => {
                handleOpenModal("rejectComments");
                setSelectedPAR(report);
              }}
            >
              Comments
            </div>
          )}

          <h4 className="par-status">{report.dcrStatus}</h4>

          {report.dcrStatus !== "Approved" && (
            <FontAwesomeIcon
              className="svg-inline--fa fa-trash"
              icon="fa-trash"
              onClick={() => handleDeletePendingPar(report)}
            />
          )}
        </div>
      );
    } else {
      return null;
    }
  });

  const confirmationModalProps = isModalOpen.deletePar
    ? {
        isModalOpen: isModalOpen.deletePar,
        onRequestClose: () => handleCloseModal("deletePar"),
        modalQuestion: `Are you sure you want to delete ${selectedPAR.jobNumber} on ${selectedPAR.date}?`,
        onConfirm: handleDeactivatePAR,
      }
    : {
        isModalOpen:
          isModalOpen.confirmEditPar && !!localStorage.getItem("formData"),
        onRequestClose: () => handleCloseModal("confirmEditPar"),
        modalQuestion: `You will lose any unsubmitted changes to:\n\nDate: ${
          localStoragePar?.date
        }\nJob: ${
          localStoragePar?.jobNumber ? localStoragePar.jobNumber : "None"
        }`,
        onConfirm: () => handleEditPar(selectedPAR),
        noButtonText: "Cancel",
        yesButtonText: "Continue",
      };

  return (
    <div className="notifications-container">
      <h1 className="par-header">PENDING PAR'S</h1>

      <div className="par scroll">
        {parList}
        <ConfirmationModal {...confirmationModalProps} />

        <Modal
          isModalOpen={isModalOpen.rejectComments}
          onRequestClose={() => handleCloseModal("rejectComments")}
        >
          {selectedPAR && <RejectCommentsForm selectedPAR={selectedPAR} />}
        </Modal>
      </div>
    </div>
  );
};

export default PendingPars;
