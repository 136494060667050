const EquipmentItem = (props) => {
  const { Equipment, Description } = props.equipmentData;

  const { isSelected, addRemoveEquipment } = props;

  return (
    <div
      className={`selectable-item-wrapper ${isSelected ? "selected" : ""}`}
      data-title={`${Equipment} ${Description}`}
      key={Equipment}
    >
      <p onClick={addRemoveEquipment} id={Equipment} selected={isSelected}>
        {`${Equipment} ${Description}`}
      </p>
    </div>
  );
};

export default EquipmentItem;
