export const basePageData = (isSelectPrint) =>
  isSelectPrint
    ? {
        jobNumDesc: "",
        foreman: "",
        pfs: "",
      }
    : {
        submitterEmail: "",
        approverEmail: "",
        jobNumDesc: "",
        sendEmail: "",
        foreman: "",
        pfs: "",
      };

export const baseCheckboxValues = (isSelectPrint) =>
  isSelectPrint
    ? {
        foremanJournal: true,
        verbalCounsels: true,
        subcontractor: true,
        timeMaterials: true,
        forecastWork: true,
        pfsJournal: true,
        signatures: true,
        material: true,
        jobInfo: true,
        changes: true,
        issues: true,
        totals: true,
      }
    : {
        verbalCounsels: false,
        timeMaterials: false,
        subcontractor: false,
        material: false,
        problems: false,
        jobInfo: false,
        idb: false,
      };

export const dailyTicketCheckboxValues = {
  foremanJournal: false,
  verbalCounsels: false,
  forecastWork: false,
  timeMaterials: true,
  subcontractor: true,
  pfsJournal: true,
  signatures: true,
  material: true,
  changes: false,
  jobInfo: true,
  issues: false,
  totals: true,
};

export const reportTableData = (parData, isSelectPrint = false) => {
  return {
    issues: {
      "Issue Type": "issueType",
      Date: "dateRaised",
      Description: "description",
      empty: {
        isEmpty: parData.idb[0]?.issueType === "No Issues Entered",
        message: "No Issues Entered",
      },
    },
    subcontractors: {
      Subcontractor: "name",
      Description: "description",
      Hours: "dailyHours",
      Count: "employeeCount",
      empty: {
        isEmpty: parData.subcontractor[0]?.name === "none",
        message: "None of our subcontractors were observed this period.",
      },
    },
    tAndMCustomer: {
      "T&M Customer": "matCustomer",
      "Customer Code": "matCustomerCode",
      "Onsite Representative": "matOsr",
      Jobsite: "matJobsite",
      empty: {
        isEmpty: parData.matCustomer === "",
        message:
          "None of our time and material customers were observed this period.",
      },
    },
    materials: {
      Material: "description",
      Units: "units",
      ...(!isSelectPrint && {
        Cost: "cost",
        Total: "total",
      }),
      "Phase Code": "phaseCode",
      "Phase Description": "phaseDesc",
      empty: {
        isEmpty: parData.material[0]?.phaseCode === "",
        message: "No Materials Used",
      },
    },
    hrvc: {
      EmployeeName: "employeeName",
      EmployeeId: "employeeId",
      Behavior: "behavior",
      empty: {
        isEmpty: parData.hrvc.length === 0,
        message: "No Verbal Counsels",
      },
    },
  };
};
