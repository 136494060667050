import { useState } from "react";

const HrvcEmployeeItem = (props) => {
  const { selectedData, setSelectedData, data } = props;
  const [selected, setSelected] = useState(false);
  const { FirstName, LastName, MidName, employeeID } = data;

  const handleSelectedList = () => {
    setSelected((s) => !s);
    if (!selected) {
      const employeeData = {
        name: `${FirstName} ${LastName}`,
        id: employeeID,
      };
      setSelectedData([employeeData]);
    } else {
      setSelectedData([]);
    }
  };

  return (
    <div
      className="selectable-item-wrapper"
      data-title={`${FirstName} ${MidName !== null ? MidName : ""} ${LastName}`}
    >
      <p
        onClick={handleSelectedList}
        className={`${
          data.employeeID === selectedData[0]?.id ? "selected" : "not-selected"
        }`}
      >
        {`${FirstName} ${MidName !== null ? MidName : ""} ${LastName}`}
      </p>
    </div>
  );
};

export default HrvcEmployeeItem;
