import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "../styles/toast-notifications.scss";

toast.configure();

export const errorToast = (error, enableHtml = false) => {
  if (enableHtml) {
    toast.error(<div dangerouslySetInnerHTML={{ __html: error }} />, {
      className: "toast-with-link",
    });
  } else {
    toast.error(error);
  }
};

export const infoToast = (info) => {
  toast.info(info);
};

export const successfulToast = (message) => {
  toast.success(
    <div>
      <FontAwesomeIcon icon="fa fa-check-circle" />
      {message}
    </div>,
    { hideProgressBar: true, autoClose: 2987 }
  );
};
