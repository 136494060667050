import { useCallback, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ConfirmationModal from "./modals/confirmation-modal/ConfirmationModal";
import AddClassForm from "./forms/AddClassForm";
import Modal from "./modals/Modal";

import getDescriptionFromClass from "./helpers/getDescriptionFromClass";
import preventNumScroll from "./helpers/preventNumScroll";
import { hoursWorkedIsValid } from "./helpers/hoursWorkedIsValid";
import { useCurrentPar } from "./contexts/CurrentParContext";
import { errorToast } from "../util/toastNotifications";

export default function PrevailingWagesTable(props) {
  const {
    phaseNum,
    pwClassesByJob,
    isLoading,
    editInfo,
    handleModal,
    isMobileScreen,
    parFormDataState,
  } = props;

  const [isDeletePrevailingWageModalOpen, setIsDeletePrevailingWageModalOpen] =
    useState(false);
  const [isPrevailingWageModalOpen, setIsPrevailingWageModalOpen] =
    useState(false);
  const [classesSelected, setClassesSelected] = useState(null);
  const [employeeClasses, setEmployeeClasses] = useState([]);
  const [sumAfterDelete, setSumAfterDelete] = useState(0);
  const [hoursUpdated, setHoursUpdated] = useState("n");
  const [employeeId, setEmployeeId] = useState("");
  const [jobClass, setJobClass] = useState("");

  const { parDispatch, parFormDataState: parData } = useCurrentPar();

  const masterData = useMemo(() => {
    if (parFormDataState?.phaseCodes) {
      return parFormDataState;
    }
    return parData;
  }, [parFormDataState, parData]);

  const laborData = masterData?.phaseCodes?.[phaseNum]?.laborData;

  const updateHoursWorked = useCallback(
    (sum, id) => {
      parDispatch({
        type: "updatePhaseCodeField",
        payload: {
          phaseCode: phaseNum,
          attributeName: "laborData",
          fieldName: "hoursWorked",
          updatedValue: sum,
          rowId: id,
          filterName: "id",
        },
      });
      setHoursUpdated("n");
    },
    [parDispatch, phaseNum, setHoursUpdated]
  );

  const dispatchHours = (value, keyName, employeeId) => {
    parDispatch({
      type: "updatePhaseCodeFieldObject",
      payload: {
        phaseCode: phaseNum,
        attributeName: "laborData",
        fieldName: "classes",
        updatedValue: value,
        rowId: employeeId,
        filterName: "id",
        objectKey: keyName,
      },
    });
    setHoursUpdated("y");
  };

  const dispatchLaborNotes = (value, employeeId) => {
    parDispatch({
      type: "updatePhaseCodeField",
      payload: {
        phaseCode: phaseNum,
        attributeName: "laborData",
        fieldName: "laborNotes",
        updatedValue: value,
        rowId: employeeId,
        filterName: "id",
      },
    });
  };

  const deleteClass = (pwClassesByJob, jobClass, employeeId) => {
    const filteredClasses = {};
    for (const key in pwClassesByJob) {
      if (key !== jobClass[0]) {
        filteredClasses[key] = pwClassesByJob[key];
      }
    }

    parDispatch({
      type: "updatePhaseCodeField",
      payload: {
        phaseCode: phaseNum,
        attributeName: "laborData",
        fieldName: "classes",
        updatedValue: filteredClasses,
        rowId: employeeId,
        filterName: "id",
      },
    });
  };

  const handleDeleteClassButtonClick = (classes, jobClass, employeeId) => {
    setEmployeeClasses(classes);
    setJobClass(jobClass);
    setEmployeeId(employeeId);
    setIsDeletePrevailingWageModalOpen(true);
  };

  const handleButtonClick = (id) => {
    setEmployeeId(id);
    setIsPrevailingWageModalOpen(true);
  };

  const classHoursOnBlur = (employeeId, employeeName, jobClass) => {
    const hourWorkedResult = hoursWorkedIsValid(
      masterData,
      employeeId,
      "laborData"
    );

    if (hourWorkedResult === "exceeds") {
      dispatchHours(0, jobClass[0], employeeId);

      errorToast(
        `${employeeName} Hours Has Exceeded Total Hours From PAR Job Information Section.`
      );
    } else if (hourWorkedResult === "precision") {
      dispatchHours(0, jobClass[0], employeeId);

      errorToast("Cannot Input More Than Two Decimal Places.");
    }
  };

  const renderWagesTable = () => {
    return laborData?.map((person) => {
      return (
        <div key={person.id} className="wages-table">
          <div className="employee-data">
            <div className="employee">
              {isMobileScreen && <p>Employee:</p>}
              <p className="employee-name">{person.name}</p>
              <p className="employee-id">{`(${person.id})`}</p>
            </div>

            {editInfo ? (
              <input
                type="text"
                name={"employee-note"}
                className="employee-note"
                placeholder="Labor Notes"
                defaultValue={person.laborNotes}
                onBlur={(e) => dispatchLaborNotes(e.target.value, person.id)}
              />
            ) : (
              <div className="employee-note">{person.laborNotes}</div>
            )}

            <p className="employee-hours">Total Hours | {person.hoursWorked}</p>

            {editInfo && (
              <div className="labor-buttons">
                <button
                  className="add-work"
                  onClick={() => handleButtonClick(person.id)}
                >
                  Add Work
                </button>

                <button
                  className="remove-employee"
                  onClick={() => {
                    setEmployeeId(person.id);
                    handleModal(
                      {
                        attributeName: "laborData",
                        selectedRecord: person.name,
                        filterName: "name",
                      },
                      "removeEmployee"
                    );
                  }}
                >
                  <FontAwesomeIcon icon="fas fa-trash" />
                </button>
              </div>
            )}
          </div>

          {renderClasses(
            person.classes,
            person.id,
            person.name,
            person.hoursWorked
          )}
        </div>
      );
    });
  };

  const renderClasses = (classes, employeeId, employeeName, sum) => {
    if (classes) {
      const values = Object.entries(classes);
      return values?.map((jobClass) => {
        return (
          <div className="labor-data" key={jobClass[0]}>
            <p className="class-header">Class |</p>
            <p className="class-name">
              {getDescriptionFromClass(jobClass[0], pwClassesByJob)
                ? getDescriptionFromClass(jobClass[0], pwClassesByJob)
                    .Description
                : jobClass[0]}
            </p>

            {editInfo ? (
              <>
                <input
                  className="class-hours"
                  value={jobClass[1]}
                  type="number"
                  min="0"
                  max="24"
                  step={0.25}
                  onWheel={preventNumScroll}
                  onBlur={() =>
                    classHoursOnBlur(employeeId, employeeName, jobClass)
                  }
                  onChange={(e) =>
                    dispatchHours(e.target.value, jobClass[0], employeeId)
                  }
                />

                <button
                  className="remove-work"
                  onClick={() => {
                    handleDeleteClassButtonClick(classes, jobClass, employeeId);
                    setSumAfterDelete(sum - jobClass[1]);
                  }}
                >
                  <FontAwesomeIcon icon="fas fa-trash" />
                </button>
              </>
            ) : (
              <div className="class-hours-view">{jobClass[1]}</div>
            )}
          </div>
        );
      });
    }
  };

  useEffect(() => {
    const phaseEmployees = masterData.phaseCodes[phaseNum].laborData;
    const currentEmployeeEdit = phaseEmployees.filter(
      (phaseEmployee) => phaseEmployee.id === employeeId
    );

    setClassesSelected(currentEmployeeEdit[0]?.classes || {});
  }, [employeeId, masterData.phaseCodes, phaseNum]);

  useEffect(() => {
    laborData?.forEach((person) => {
      let sum = 0;
      for (const indivClass in person.classes) {
        sum += parseFloat(person.classes[indivClass] || 0);
        if (hoursUpdated === "y") {
          updateHoursWorked(sum, person.id);
        }
      }
    });
  }, [laborData, hoursUpdated, updateHoursWorked]);

  return (
    laborData?.length !== 0 && (
      <div className={`wages-container ${isMobileScreen ? "mobile-view" : ""}`}>
        <div className="wages-header">
          <p className="header-employee">Employee</p>

          <p className="header-note">Labor Note</p>

          <p className="header-hours">Hours Worked</p>
        </div>

        {renderWagesTable()}

        <Modal
          isModalOpen={isPrevailingWageModalOpen}
          onRequestClose={() => setIsPrevailingWageModalOpen(false)}
        >
          <AddClassForm
            pwClassesByJob={pwClassesByJob}
            isLoading={isLoading}
            phaseNum={phaseNum}
            employeeId={employeeId}
            classesSelected={classesSelected}
            setClassesSelected={setClassesSelected}
            setIsPrevailingWageModalOpen={setIsPrevailingWageModalOpen}
          />
        </Modal>

        <ConfirmationModal
          isModalOpen={isDeletePrevailingWageModalOpen}
          onRequestClose={() => setIsDeletePrevailingWageModalOpen(false)}
          modalQuestion={`Are you sure you want to remove: ${
            getDescriptionFromClass(jobClass[0], pwClassesByJob)?.Description
          }?`}
          onConfirm={() => {
            deleteClass(employeeClasses, jobClass, employeeId);
            updateHoursWorked(sumAfterDelete, employeeId);
            setIsDeletePrevailingWageModalOpen(false);
          }}
        />
      </div>
    )
  );
}
