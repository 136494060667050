import { createContext, useContext, useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import Cookies from "js-cookie";

import asyncAPICall from "../../util/apiWrapper";

const AuthContext = createContext(null);

export default function AuthProvider({ children }) {
  const [user, setUser] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  const currentLocation = useLocation();
  const history = useHistory();

  const logout = () => {
    Cookies.remove("bearer_token");
    localStorage.clear();
    history.push("/login");
  };

  const handleUpdateUserCredentials = () => {
    asyncAPICall(
      "api:KE7MCNYf/auth/me",
      "GET",
      null,
      null,
      (data) => {
        setUser(data);
      },
      (err) => {
        console.error("error getting user authentication", err);
      },
      null,
      true
    );
  };

  useEffect(() => {
    isLoggedIn && handleUpdateUserCredentials();
  }, [isLoggedIn]);

  useEffect(() => {
    const cookieExists = Cookies.get("bearer_token");
    setIsLoggedIn(!!cookieExists);
  }, [currentLocation.pathname]);

  const values = {
    user,
    isLoggedIn,
    setUser,
    handleUpdateUserCredentials,
    logout,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
}

export const useAuthInfo = () => {
  return useContext(AuthContext);
};
