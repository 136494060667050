import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ApprovalsEmptyData = ({ parData }) => {
  const slicedPhaseNum = parData.phaseDescription.slice(0, 12);
  return (
    <div className="empty-data-container">
      {parData.phaseNote?.length === 0 && (
        <div className="no-phase-data short">
          <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" /> No Notes
        </div>
      )}

      {parData.laborData?.length === 0 && (
        <div className="no-phase-data short">
          <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" /> No Labor
        </div>
      )}

      {parData.equipmentData?.length === 0 &&
        slicedPhaseNum !== "012130-00-00" && (
          <div className="no-phase-data">
            <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" /> No
            Equipment
          </div>
        )}

      {parData.progressEntry?.length === 0 &&
        slicedPhaseNum !== "012130-00-00" && (
          <div className="no-phase-data">
            <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" /> No
            Progress
          </div>
        )}
    </div>
  );
};

export default ApprovalsEmptyData;
