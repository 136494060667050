import { useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import Modal from "../modals/Modal";
import PhaseCodeForm from "../forms/PhaseCodeForm";
import useApprovalFunctions from "../../pages/verification-approvals/hooks/useApprovalFunctions";
import { useCurrentPar } from "../contexts/CurrentParContext";
import { useModalContext } from "../contexts/ModalContext";
import { updatePerDiem } from "../helpers/updatePerDiem";
import { errorToast } from "../../util/toastNotifications";
import asyncAPICall from "../../util/apiWrapper";
import useAbortEffect from "../hooks/useAbortEffect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faBan,
  faPrint,
  faPlus,
  faCheck,
  faTimes,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import PrintEmailForm from "../forms/PrintEmailForm";

const ActionButtons = ({
  rejectMessage = "",
  setRejectMessage,
  pageData,
  parData,
  handleRejectMessage,
  handlePrint,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [phaseCodeOptions, setPhaseCodeOptions] = useState([]);
  const location = useLocation();
  const { pathname } = location;
  const history = useHistory();
  const view = pathname.split("/")[2];
  const isSelectPrint = pathname.startsWith("/select-print");
  const isApprovalsRoute = pathname.startsWith("/approvals");
  const [submitterEmail, setSubmitterEmail] = useState("");
  const [sendEmail, setSendEmail] = useState("");

  const pathRef = window.location.href;
  const pathRefArray = pathRef.split("/", 3);
  const dynamicPath = pathRefArray.join("/");
  const emailLink = `${dynamicPath}/par/`;

  const { submitFullPar, parApprovalStatus } = useApprovalFunctions();
  const {
    parFormDataState,
    parDispatch,
    lastSelected,
    allPhaseEmployees,
    allPhaseEquipment,
    phaseCodesRef,
  } = useCurrentPar();

  const {
    pfs,
    jobNumber,
    foreman,
    wind,
    precipitation,
    temperatureHigh,
    temperatureLow,
    date,
    safetyTopic,
    startTime,
    stopTime,
    forecast,
    injuries,
    incidents,
    idb,
    subcontractor,
    material,
    checkboxes,
  } = parFormDataState;

  const { isModalOpen, handleOpenModal, handleCloseModal } = useModalContext();

  const isFromApprovals =
    location.state?.fromApprovals || pathname.startsWith("/approvals/");

  const weatherIsFilledOut = () => {
    let isFilled = true;
    [temperatureHigh, temperatureLow, wind, precipitation, forecast].forEach(
      (attr) => {
        if (attr === "") {
          isFilled = false;
        }
      }
    );
    return isFilled;
  };

  const isValidTime = () => {
    if (checkboxes.overnight || startTime <= stopTime) {
      return true;
    } else {
      return false;
    }
  };

  const subcontractorsFilledOut = () => {
    let isFilled = true;
    if (subcontractor.length === 0) {
      isFilled = false;
    } else {
      subcontractor.forEach((s) => {
        if (
          s.name === "" ||
          s.description === "" ||
          s.dailyHours === "" ||
          s.employeeCount === ""
        ) {
          isFilled = false;
        }
      });
    }
    return isFilled;
  };

  const timeMaterialsFilledOut = () => {
    return !(
      parFormDataState.matCustomerCode === "" &&
      parFormDataState.matOsr === "" &&
      parFormDataState.matJobsite === ""
    );
  };

  const materialFilledOut = () => {
    let materialIsFilled = true;
    if (material.length === 0) {
      materialIsFilled = false;
    } else {
      if (material.length === 1 && material[0].id === 0) {
        materialIsFilled = true;
      } else {
        material.forEach((s) => {
          if (
            s.description === "" ||
            s.units === "0.00" ||
            s.cost === "0.00" ||
            s.total === "0.00" ||
            s.uom === "" ||
            s.phaseCode === "" ||
            s.phaseDesc === ""
          ) {
            materialIsFilled = false;
          }
        });
      }
    }
    return materialIsFilled;
  };

  const checkProgressEntry = () => {
    const progressEntryArray = [];

    Object.keys(parFormDataState.phaseCodes).forEach((phase) => {
      progressEntryArray.push(
        parFormDataState.phaseCodes[phase].progressEntry[0]?.dcrProgress
      );
    });

    const missingProgress =
      progressEntryArray.includes("0") || progressEntryArray.includes(undefined)
        ? true
        : false;

    return missingProgress;
  };

  const isFormAllFilledOut = () => {
    if (
      weatherIsFilledOut() &&
      date !== "" &&
      pfs !== 0 &&
      foreman !== "" &&
      jobNumber !== "" &&
      safetyTopic !== "" &&
      startTime !== "" &&
      stopTime !== "" &&
      isValidTime() &&
      (idb?.length > 0 || !checkboxes.issues) &&
      (incidents.length > 0 || !checkboxes.incidents) &&
      (injuries.length > 0 || !checkboxes.injuries) &&
      (subcontractorsFilledOut() || !checkboxes.subcontractor) &&
      (timeMaterialsFilledOut() || !checkboxes.timeMaterials) &&
      (materialFilledOut() || !checkboxes.material)
    ) {
      return true;
    }
    return false;
  };
  const handleVerifyToast = () => {
    setIsDisabled(true);

    if (date === "") {
      errorToast("Please Select a Date");
    } else if (pfs === 0) {
      errorToast("Please Select PAR Approver");
    } else if (jobNumber === "") {
      errorToast("Please Select Job Number");
    } else if (!weatherIsFilledOut()) {
      errorToast("Please Complete Weather");
    } else if (safetyTopic === "") {
      errorToast("Please Enter Safety Topic");
    } else if (startTime === "") {
      errorToast("Please Enter Start Time");
    } else if (stopTime === "") {
      errorToast("Please Enter Stop Time");
    } else if (!isValidTime()) {
      errorToast("Overnight Hours Box Not Checked");
    } else if (idb.length === 0 && checkboxes.issues) {
      errorToast("Missing Issues Records");
    } else if (incidents === "" && checkboxes.incidents) {
      errorToast("Missing Incidents Notes");
    } else if (injuries === "" && checkboxes.injuries) {
      errorToast("Missing Injuries Notes.");
    } else if (checkboxes.subcontractor && !subcontractorsFilledOut()) {
      errorToast("Missing Subcontractor details.");
    } else if (checkboxes.timeMaterials && !timeMaterialsFilledOut()) {
      errorToast("Missing Time and Materials Details");
    } else if (checkboxes.material && !materialFilledOut()) {
      errorToast("Missing Materials Details");
    } else if (checkProgressEntry()) {
      errorToast("Missing Progress Entry");
    }

    setTimeout(() => {
      setIsDisabled(false);
    }, 5000);
  };

  const handleSubmit = async () => {
    try {
      updatePerDiem(parDispatch, parFormDataState);
      await submitFullPar(
        parFormDataState,
        parFormDataState.approverEmail,
        setIsDisabled,
        parFormDataState.pfs,
        parFormDataState.foreman,
        parFormDataState.sendEmail
      );
    } catch (error) {
      console.error("Error submitting PAR:", error);
      errorToast("Failed to submit PAR");
      setIsDisabled(false);
    }
  };

  const handleApprove = () => {
    parApprovalStatus("Approved", parFormDataState, setIsDisabled);
  };

  const handleReject = () => {
    if (rejectMessage.length > 0) {
      const updatedParData = handleRejectMessage(rejectMessage);
      sendRejectEmail();
      parApprovalStatus("Reject", updatedParData, setIsDisabled);
    } else {
      errorToast("Please provide rejection comments");
    }
  };

  const handleParSubmit = () => {
    if (view === "par") {
      submitFullPar(
        parData,
        pageData.approverEmail,
        setIsDisabled,
        pageData.foreman,
        pageData.pfs,
        pageData.sendEmail
      );
    } else {
      parApprovalStatus("Approved", parData, setIsDisabled);
    }
  };

  const sendRejectEmail = () => {
    const emailData = {
      to_email: submitterEmail,
      template_id: "d-715298ff918245fcaf79789a361c4d27",
      data: {
        jobNumber: parFormDataState.jobNumber,
        date: parFormDataState.date,
        rejectMessage: rejectMessage,
        link: emailLink,
      },
    };

    asyncAPICall(
      `api:5e9BgwVw/sendgrid/dynamicEmail`,
      "POST",
      emailData,
      null,
      () => history.push("/approvals"),
      (err) => {
        console.error("Sending Rejection Email Error: ", err);
        errorToast("Failed to send rejection email");
      },
      null,
      true
    );
  };

  useEffect(() => {
    if (parFormDataState.foreman && pathname.startsWith("/approvals")) {
      asyncAPICall(
        `api:KE7MCNYf/vEmployeesNoSpacesIdByID?employeeID=${parFormDataState.foreman}`,
        "GET",
        null,
        null,
        (data) => {
          setSubmitterEmail(data[0].Email);
        }
      );
    }
  }, [parFormDataState.foreman, pathname]);

  useAbortEffect(
    (signal) => {
      asyncAPICall(
        `api:KE7MCNYf/vprojectphasesbyproject?jobNumber=${jobNumber}`,
        "GET",
        null,
        null,
        (data) => {
          if (data) {
            setPhaseCodeOptions(data);
          }
        },
        (err) => {
          if (!signal.aborted) {
            console.error("Getting Phases Error", err);
          }
        },
        signal
      );
    },
    [jobNumber]
  );

  useEffect(() => {
    if (lastSelected.current && !isModalOpen.bottomPhaseCode) {
      const selectedItem = phaseCodesRef.current.find(
        (el) => el?.id === lastSelected.current
      );

      if (selectedItem?.getBoundingClientRect().top < 150) {
        setTimeout(() => {
          selectedItem.scrollIntoView({ block: "start" });
          window.scrollBy(0, -150);
        }, 50);
      }
    }
  }, [isModalOpen, phaseCodesRef, lastSelected]);

  const handleRejectMessageChange = (e) => {
    setRejectMessage(e.target.value);
  };

  const getCancelDestination = () => {
    if (isSelectPrint) {
      if (location?.state?.prevPath?.startsWith("/par")) {
        return {
          pathname: location.state.prevPath,
          state: {
            bypassWarningModal: true,
            preserveContext: true,
            parFormData: location.state.parFormData,
          },
        };
      }

      if (location?.state?.prevPath?.startsWith("/approvals/")) {
        return {
          pathname: location.state.prevPath,
          state: { fromApprovals: true },
        };
      }

      if (location.state?.searchParams) {
        const { searchedStartDate, searchedEndDate, searchedJob } =
          location.state.searchParams;
        return {
          pathname: "/approvals",
          search: `?startDate=${searchedStartDate}&endDate=${searchedEndDate}&job=${searchedJob}`,
          state: { fromApprovals: true },
        };
      }
    }

    if (pathname?.startsWith("/par")) {
      return "/overview";
    }

    if (pathname?.startsWith("/approvals/")) {
      if (isFromApprovals) {
        return "/approvals";
      }
      return "/overview";
    }

    return "/overview";
  };

  const handleCancel = () => {
    window.scrollTo(0, 0);
    const destination = getCancelDestination();

    window.scrollTo(0, 0);

    if (typeof destination === "string") {
      history.push(destination);
    } else {
      if (destination.search) {
        history.push({
          pathname: destination.pathname,
          search: destination.search,
          state: destination.state,
        });
      } else {
        history.push(destination.pathname, destination.state);
      }
    }
  };

  const renderButtons = () => {
    const isParRoute = pathname.startsWith("/par");

    if (isParRoute) {
      return (
        <>
          <button
            onClick={isFormAllFilledOut() ? handleSubmit : handleVerifyToast}
            disabled={isDisabled}
            className="save"
          >
            <FontAwesomeIcon icon={faSave} className="button-icon" /> Submit
          </button>
        </>
      );
    }

    if (isApprovalsRoute) {
      if (view === "confirm") {
        return (
          <>
            <button
              className="reject"
              disabled={isDisabled || rejectMessage.length <= 0}
              onClick={handleReject}
            >
              <FontAwesomeIcon icon={faTimes} className="button-icon" />
              {isDisabled ? "Loading" : "Reject"}
            </button>

            <button
              className="save"
              disabled={isDisabled}
              onClick={handleParSubmit}
            >
              <FontAwesomeIcon icon={faCheck} className="button-icon" />
              {isDisabled ? "Loading" : "Approve"}
            </button>
          </>
        );
      } else {
        return (
          <>
            <button
              className="reject"
              disabled={isDisabled || rejectMessage.length <= 0}
              onClick={handleReject}
            >
              <FontAwesomeIcon icon={faTimes} className="button-icon" /> Reject
            </button>

            <button
              className="save"
              onClick={isFormAllFilledOut() ? handleApprove : handleVerifyToast}
              disabled={isDisabled}
            >
              <FontAwesomeIcon icon={faCheck} className="button-icon" /> Approve
            </button>
          </>
        );
      }
    }

    if (isSelectPrint) {
      return (
        <button className="print" onClick={handlePrint}>
          <FontAwesomeIcon icon={faPrint} className="button-icon" /> Print
        </button>
      );
    }
  };

  const isApprovalsPath = location.pathname.startsWith("/approvals");

  return (
    <>
      <div
        className={`action-buttons-wrapper ${
          isFromApprovals ? "approvals" : ""
        }`}
      >
        {isSelectPrint ? (
          <button
            className="cancel"
            onClick={() => {
              if (location.state?.searchParams) {
                history.push(
                  `/approvals?startDate=${location.state?.searchParams?.searchedStartDate}&endDate=${location.state?.searchParams?.searchedEndDate}&job=${location.state?.searchParams?.searchedJob}`
                );
              } else if (location.state?.prevPath?.startsWith("/par")) {
                history.push(`/par/${view}`);
              } else if (location.state?.prevPath?.startsWith("/approvals")) {
                history.push(`/approvals/${view}`);
              } else {
                history.push("/overview");
              }
            }}
          >
            <FontAwesomeIcon icon={faBan} className="button-icon" /> Cancel
          </button>
        ) : (
          <button className="cancel" onClick={handleCancel}>
            <FontAwesomeIcon icon={faBan} className="button-icon" /> Cancel
          </button>
        )}

        {!isSelectPrint && (
          <Link
            to={{
              pathname: `/select-print${
                pathname.startsWith("/approvals/")
                  ? "/" + pathname.split("/")[2]
                  : pathname.startsWith("/par") && pathname.split("/")[2]
                  ? "/" + pathname.split("/")[2]
                  : ""
              }`,
              state: {
                prevPath: pathname,
                fromApprovals: isFromApprovals,
                searchParams: location.state?.searchParams,
                parFormData: pathname.startsWith("/par")
                  ? parFormDataState
                  : parFormDataState,
              },
            }}
            className="print"
            onClick={(e) => {
              e.preventDefault();
              const slug = pathname.split("/")[2];
              history.push({
                pathname: `/select-print${slug ? `/${slug}` : ""}`,
                state: {
                  prevPath: pathname,
                  fromApprovals: isFromApprovals,
                  searchParams: location.state?.searchParams,
                  parFormData: pathname.startsWith("/par")
                    ? parFormDataState
                    : undefined,
                },
              });
            }}
          >
            <FontAwesomeIcon icon={faPrint} className="button-icon" /> Print
          </Link>
        )}

        {!isSelectPrint && (
          <button
            className="email"
            onClick={() => {
              handleOpenModal("printEmail");
            }}
          >
            <FontAwesomeIcon icon={faEnvelope} className="button-icon" />
            Email
          </button>
        )}

        {!isSelectPrint && (
          <button
            className="add-phase"
            onClick={() => handleOpenModal("bottomPhaseCode")}
            disabled={!jobNumber.length > 0}
          >
            <FontAwesomeIcon icon={faPlus} className="button-icon" /> Add Phase
          </button>
        )}

        {renderButtons()}
      </div>

      {isApprovalsPath &&
        !isSelectPrint &&
        pathname.startsWith("/approvals/") && (
          <div className="rejected-par-comments">
            <h2>Comments on Rejected PAR</h2>
            <textarea
              value={rejectMessage}
              onChange={handleRejectMessageChange}
              placeholder="Enter any changes that need to be made for the PAR to be approved."
            />
          </div>
        )}

      <Modal
        isModalOpen={isModalOpen.bottomPhaseCode}
        onRequestClose={() => handleCloseModal("bottomPhaseCode")}
      >
        <PhaseCodeForm
          allPhaseEmployees={allPhaseEmployees}
          allPhaseEquipment={allPhaseEquipment}
          lastSelected={lastSelected}
          bottomPhaseAdd={true}
          phaseCodeOptions={phaseCodeOptions}
          ref={phaseCodesRef}
        />
      </Modal>

      <Modal
        isModalOpen={isModalOpen.printEmail}
        onRequestClose={() => handleCloseModal("printEmail")}
      >
        <PrintEmailForm sendEmail={sendEmail} setSendEmail={setSendEmail} />
      </Modal>
    </>
  );
};

export default ActionButtons;
