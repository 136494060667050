import asyncAPICall from "../../util/apiWrapper";

import { baseParFormData } from "../../components/reducer/store/baseParFormData";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useCurrentPar } from "../contexts/CurrentParContext";

const useParReport = (baseCheckboxValues) => {
  const [parData, setParData] = useState(baseParFormData);
  const [checkboxValues, setCheckboxValues] = useState({
    report: baseCheckboxValues,
    combined: [],
    phase: {},
  });

  const { parFormDataState, parDispatch } = useCurrentPar();
  const { pathname, state } = useLocation();

  const checkmarksHistory = state?.approvalsCheckmarks;
  const split_path = pathname.split("/");
  const isSelectPrint = split_path[1] === "select-print";
  const view = split_path[2];

  const viewId = (() => {
    if (state?.prevPath?.startsWith("/par")) {
      return split_path.length > 2 ? split_path[2] : null;
    }
    if (state?.prevPath?.startsWith("/approvals/")) {
      return split_path.length > 2
        ? split_path[2]
        : state.prevPath.split("/")[2];
    }
    return isSelectPrint && split_path.length > 2
      ? split_path[2]
      : split_path[3];
  })();

  const handleCheckmarks = useCallback((action, value) => {
    setCheckboxValues((prev) => {
      if (value instanceof Function) {
        return { ...prev, [action]: value(prev[[action]]) };
      } else {
        return { ...prev, [action]: value };
      }
    });
  }, []);

  useEffect(() => {
    if (
      state?.prevPath?.includes("/par") ||
      state?.prevPath?.includes("/approvals") ||
      state?.prevPath?.includes("/select-print")
    ) {
      setParData(parFormDataState);
      return;
    }

    if (state?.prevPath?.startsWith("/overview") && viewId) {
      const controller = new AbortController();
      asyncAPICall(
        `api:W2oHkD04/partblmain/parNumber?parNumber=${viewId}`,
        "GET",
        null,
        null,
        (data) => {
          setParData(data);
        },
        (err) => {
          if (!controller.signal.aborted) {
            console.error("Error fetching PAR report", err);
          }
        },
        controller.signal
      );
      return () => controller.abort();
    }

    if (
      (state?.prevPath?.startsWith("/par/") ||
        state?.prevPath?.startsWith("/approvals/") ||
        state?.prevPath?.startsWith("/select-print/")) &&
      parFormDataState
    ) {
      setParData(parFormDataState);

      return;
    }

    if (viewId) {
      const controller = new AbortController();
      asyncAPICall(
        `api:W2oHkD04/partblmain/parNumber?parNumber=${viewId}`,
        "GET",
        null,
        null,
        (data) => {
          setParData(data);
        },
        (err) => {
          if (!controller.signal.aborted) {
            console.error("Error fetching PAR report", err);
          }
        },
        controller.signal
      );
      return () => controller.abort();
    }
  }, [viewId, parFormDataState, pathname, state?.prevPath, state]);

  useEffect(() => {
    if (parData && !isSelectPrint) {
      if (checkmarksHistory) {
        handleCheckmarks("combined", checkmarksHistory.combinedCheckboxValues);
        handleCheckmarks("phase", checkmarksHistory.phaseCheckmarkValues);
        handleCheckmarks("report", checkmarksHistory.checkboxValues);
      } else {
        const buildEmptyChecks = {};

        const phaseCodes =
          view !== "confirm" ? parFormDataState.phaseCodes : parData.phaseCodes;

        Object.keys(phaseCodes).forEach((phase) => {
          buildEmptyChecks[phase] = false;
        });

        handleCheckmarks("phase", buildEmptyChecks);
      }
    }
  }, [
    checkmarksHistory,
    isSelectPrint,
    parData,
    parFormDataState.phaseCodes,
    view,
    handleCheckmarks,
  ]);

  return {
    parData,
    handleCheckmarks,
    checkboxValues,
    parDispatch,
  };
};

export default useParReport;
