const ReportNotes = (props) => {
  const { notesData, labelText, wrapperClass = "", children } = props;

  return (
    <div className={`report-notes-wrapper ${wrapperClass}`}>
      {children}

      <div className="section-content">
        <div className="label">{labelText}</div>
        <div className="details">
          {notesData?.length > 0 ? notesData : "--"}
        </div>
      </div>
    </div>
  );
};

export default ReportNotes;
