const ConfirmationControls = ({
  modalQuestion,
  onConfirm,
  onRequestClose,
  yesButtonText,
  noButtonText,
}) => {
  return (
    <div className="confirmation-modal-container">
      <div className="message">{modalQuestion}</div>

      <div className="button-wrapper">
        <button
          className={noButtonText.split(" ").length > 1 ? "smaller-text" : ""}
          onClick={onRequestClose}
        >
          {noButtonText}
        </button>

        <button
          className={`button-alternate ${
            noButtonText.split(" ").length > 1 ? "smaller-text" : ""
          }`}
          onClick={onConfirm}
        >
          {yesButtonText}
        </button>
      </div>
    </div>
  );
};

export default ConfirmationControls;
