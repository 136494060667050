const ReportHeader = ({ image, header, subHeader, wrapperClass = "" }) => {
  return (
    <div className={`report-header-wrapper ${wrapperClass}`}>
      <div className="header-content">
        {image && (
          <img className="letter-head" src={image} alt="bhi letterhead" />
        )}

        <div className="header main-header">{header}</div>

        {subHeader && <div className="header sub-header">{subHeader}</div>}

        <div className="header show-print">Daily Work Ticket</div>
      </div>
    </div>
  );
};

export default ReportHeader;
