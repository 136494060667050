import { useEffect, useRef } from "react";

export default function useClickOutside(handler) {
  const domNode = useRef();

  useEffect(() => {
    const listener = (event) => {
      if (!domNode.current?.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  });

  return domNode;
}
