import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import preventNumScroll from "../../../components/helpers/preventNumScroll";
import { hoursWorkedIsValid } from "../../../components/helpers/hoursWorkedIsValid";
import { useCurrentPar } from "../../../components/contexts/CurrentParContext";
import { errorToast } from "../../../util/toastNotifications";

const EquipmentPhaseItem = (props) => {
  const { phaseNum, equipment, handleModal, isMobileScreen } = props;

  const { parFormDataState, parDispatch } = useCurrentPar();

  const handleOperatedHoursBlur = () => {
    const resetHoursWorked = {
      type: "updatePhaseCodeField",
      payload: {
        phaseCode: phaseNum,
        attributeName: "equipmentData",
        fieldName: "operatedHours",
        updatedValue: "0",
        rowId: equipment.equipNum,
        filterName: "equipNum",
      },
    };

    const hourWorkedResult = hoursWorkedIsValid(
      parFormDataState,
      equipment.equipNum,
      "equipmentData"
    );

    if (hourWorkedResult === "exceeds") {
      parDispatch(resetHoursWorked);

      errorToast(
        `${equipment.equipNum} Has Exceeded Total Hours From PAR Job Information Section`
      );
    } else if (hourWorkedResult === "precision") {
      parDispatch(resetHoursWorked);

      errorToast("Cannot Input More Than Two Decimal Places.");
    }
  };

  const handleOperatedHours = (e) =>
    parDispatch({
      type: "updatePhaseCodeField",
      payload: {
        phaseCode: phaseNum,
        attributeName: "equipmentData",
        fieldName: "operatedHours",
        updatedValue: e.target.value,
        rowId: equipment.equipNum,
        filterName: "equipNum",
      },
    });

  const handleEquipmentNote = (e) =>
    parDispatch({
      type: "updatePhaseCodeField",
      payload: {
        phaseCode: phaseNum,
        attributeName: "equipmentData",
        fieldName: "equipNote",
        updatedValue: e.target.value,
        rowId: equipment.equipNum,
        filterName: "equipNum",
      },
    });

  const handleRemoveEquipment = () =>
    handleModal(
      {
        attributeName: "equipmentData",
        selectedRecord: equipment.equipNum,
        filterName: "equipNum",
      },
      "removeEquipment"
    );

  return (
    <tr key={equipment.equipNum} className="table-row">
      <td className="padding-20">
        {isMobileScreen && "Equipment # "}
        {equipment.equipNum} {equipment.description}
      </td>

      <td>
        {isMobileScreen && <p>Operated Hours</p>}

        <input
          className="operated-hours"
          type="number"
          onKeyDown={(e) => e.code === "Minus" && e.preventDefault()}
          min="0"
          max="24"
          step={0.25}
          value={equipment.operatedHours}
          onWheel={preventNumScroll}
          onBlur={handleOperatedHoursBlur}
          onChange={handleOperatedHours}
        />
      </td>

      <td>
        {isMobileScreen && <p>Equipment Note</p>}

        <input
          type="text"
          value={equipment.equipNote}
          maxLength={150}
          onChange={handleEquipmentNote}
        />
      </td>

      <td className="remove-table-row">
        <FontAwesomeIcon
          icon="svg-inline--fa fa-trash"
          onClick={handleRemoveEquipment}
        />
      </td>
    </tr>
  );
};

export default EquipmentPhaseItem;
