import { useCallback, useState } from "react";

import ApprovalsEmptyData from "../verification-approvals/ApprovalsEmptyData.js";
import PrevailingWagesTable from "../../components/PrevailingWagesTable";
import GeneralCheckbox from "../../components/core/GeneralCheckbox";

import useAbortEffect from "../../components/hooks/useAbortEffect";

import asyncAPICall from "../../util/apiWrapper";
import { getTotalHours } from "../../components/helpers/totalHours.js";
import {
  calculateSectionValues,
  calculateTotalPerDiemHours,
} from "../../components/helpers/calculateHours";

const columnHeaders = {
  employeeHours: {
    name: "Employee Name",
    id: "Employee Number",
    phaseNum: "Phase",
    hoursWorked: "Hours",
    laborNotes: "Note",
  },
  perDiem: {
    name: "Employee Name",
    id: "Employee Number",
    phaseNum: "Phase",
    combinedPhaseTime: "Hours",
    perDiem: "Per Diem",
    perDiemAmount: "Per Diem Amount",
    laborNotes: "Note",
  },
  progressEntry: {
    phase: "Phase Code",
    description: "Phase Description",
    currentEstimatedUnits: "Current Estimated Units",
    uom: "UOM",
    jtdProgress: "JTD Progress",
    dcrProgress: "PAR Daily Progress",
  },
  equipmentData: {
    "equipNum/description": "Equipment Number & Description",
    phaseNum: "Phase",
    operatedHours: "Hours",
    equipNote: "Note",
  },
};

const VerificationPhasecode = (props) => {
  const {
    index,
    phaseNum,
    view,
    parData,
    checkboxValues,
    isDailyTicket,
    windowWidth,
    handleCheckmarks,
  } = props;

  const [pwClassesByJob, setPwClassesByJob] = useState([]);
  const [billedEquipmentHours, setBilledEquipmentHours] = useState(0);
  const pathName = window.location.pathname;
  const pathNameArray = pathName.split("/", 3);
  const urlPath = pathNameArray[1];

  const changeDisabledSubmit = useCallback(
    (updateField, index) => {
      handleCheckmarks("combined", (prevCheckboxValue) => {
        return prevCheckboxValue.map((checkboxObj) => {
          if (checkboxObj.index === index) {
            return { ...checkboxObj, [updateField]: !checkboxObj[updateField] };
          } else {
            return checkboxObj;
          }
        });
      });
    },
    [handleCheckmarks]
  );

  const checkColumnConditions = (field, data) => {
    let value = field.split("/");

    switch (true) {
      case field === "phaseNum":
        value = phaseNum;
        break;

      case field === "perDiem":
        value = data.perDiem ? "Y" : "N";
        break;

      case field === "combinedPhaseTime":
        value = getTotalHours(data.id, parData, "labor");
        break;

      case value.length > 1:
        value = `${data[[value[0]]]} ${data[[value[1]]]}`;
        break;

      case field === "operatedHours":
        switch (true) {
          case urlPath === "select-print" &&
            data.operatedHours < billedEquipmentHours:
            value = billedEquipmentHours;
            break;

          default:
            value = data.operatedHours;
            break;
        }
        break;

      default:
        value = data[[field]];
        break;
    }

    return value;
  };

  const renderMobileTableData = (field, dataArray) => {
    return dataArray.map((data, idx) => {
      return (
        <td
          className={idx === 0 ? "first-column" : ""}
          key={`${idx}-${data?.id ? data.id : ""}`}
        >
          {checkColumnConditions(field, data)}
        </td>
      );
    });
  };

  const renderMobileRows = (columnHeaders, dataArray) => {
    return Object.keys(columnHeaders).map((field) => {
      return (
        <tr key={field}>
          <th className="vertical-header">
            <span>{columnHeaders[[field]]}</span>
          </th>
          {renderMobileTableData(field, dataArray)}
        </tr>
      );
    });
  };

  const renderDesktopTableData = (columnHeaders, data) => {
    return Object.keys(columnHeaders).map((field) => {
      return <td key={field}>{checkColumnConditions(field, data)}</td>;
    });
  };

  const renderDesktopRows = (columnHeaders, dataArray) => {
    return dataArray.map((data, idx) => {
      return (
        <tr key={`${idx}-${data?.id ? data.id : ""}`}>
          {renderDesktopTableData(columnHeaders, data)}
        </tr>
      );
    });
  };

  const renderDesktopHeaders = (columnHeaders) => {
    return Object.keys(columnHeaders).map((field) => {
      return <th key={field}>{columnHeaders[[field]]}</th>;
    });
  };

  useAbortEffect((signal) => {
    asyncAPICall(
      `api:E3-qrfOe/vprevailingwageclassByJob?job=${parData.jobNumber}`,
      "GET",
      null,
      null,
      (data) => {
        setPwClassesByJob(() => {
          const workingPwClasses = [
            { Class: "default", Description: "Non Prevailing Wage", id: 0 },
          ];
          workingPwClasses.push(...data);
          return workingPwClasses;
        });
      },
      (err) => {
        console.error(err);
      },
      signal
    );
  }, []);

  useAbortEffect(
    (signal) => {
      if (urlPath === "select-print") {
        asyncAPICall(
          `api:W2oHkD04/vIdleVsOperatedEquipmentHoursByJob?job=${parData.jobNumber}`,
          "GET",
          null,
          null,
          (data) => {
            if (data?.billHours) {
              setBilledEquipmentHours(data.billHours);
            } else {
              setBilledEquipmentHours(0);
            }
          },
          (err) => {
            console.error("Error Retriving Equipment Billed Hours", err);
          },
          signal
        );
      }
    },
    [parData.jobNumber]
  );

  const alphabetizedEmployees = [
    ...parData.phaseCodes[phaseNum].laborData,
  ].sort((firstEmployee, secondEmployee) =>
    firstEmployee.name > secondEmployee.name ? 1 : -1
  );

  return (
    <div className="verification-phase-code-wrapper">
      <div className="section-content">
        {view !== "approved" &&
        view !== "searched" &&
        checkboxValues.combined ? (
          <GeneralCheckbox
            wrapperClass="verification-checkbox-wrapper"
            checked={
              checkboxValues.combined[index]
                ? checkboxValues.combined[index].phase
                : true
            }
            onChange={() => {
              if (handleCheckmarks) {
                handleCheckmarks("phase", (prev) => {
                  const updated = { ...prev };
                  updated[phaseNum] = !prev[phaseNum];
                  return updated;
                });
              }
              changeDisabledSubmit("phase", index);
            }}
          />
        ) : view !== "approved" &&
          view !== "searched" &&
          checkboxValues.phase ? (
          <GeneralCheckbox
            wrapperClass="verification-checkbox-wrapper"
            defaultChecked={checkboxValues.phase[phaseNum]}
            onClick={() => {
              if (handleCheckmarks) {
                handleCheckmarks("phase", (prev) => {
                  const updated = { ...prev };
                  updated[phaseNum] = !prev[phaseNum];
                  return updated;
                });
              }
              changeDisabledSubmit("phase", index);
            }}
            disabled={isDailyTicket}
          />
        ) : view !== "approved" && view !== "searched" ? (
          <GeneralCheckbox
            wrapperClass="verification-checkbox-wrapper"
            defaultChecked={checkboxValues.phase[phaseNum]}
            onChange={() => {
              if (handleCheckmarks) {
                handleCheckmarks("phase", (prev) => {
                  const updated = { ...prev };
                  updated[phaseNum] = !prev[phaseNum];
                  return updated;
                });
              }
              changeDisabledSubmit("phase", index);
            }}
            disabled={isDailyTicket}
          />
        ) : null}

        <div className="table">
          <h1>Phase Code: {parData.phaseCodes[phaseNum].phaseDescription}</h1>
        </div>
      </div>

      {parData.phaseCodes[phaseNum].phaseNote?.length > 0 && (
        <div className="section-content">
          <span className="checkmark-spacer" />

          <div className="table table-notes">
            <div className="phase-note">
              <div className="header">Phase Note</div>
              {parData.phaseCodes[phaseNum].phaseNote}
            </div>
          </div>
        </div>
      )}

      {parData.phaseCodes[phaseNum].laborData.length > 0 && (
        <div className="section-content">
          <span className="checkmark-spacer" />

          {windowWidth <= 650 ? (
            <div
              className={`table-wrapper ${
                phaseNum === "012130-00-00" || parData.jobCertified !== "Y"
                  ? "phase-table-wrapper"
                  : ""
              }`}
            >
              {phaseNum !== "012130-00-00" ? (
                parData.jobCertified !== "Y" ? (
                  <table>
                    <tbody>
                      {renderMobileRows(
                        columnHeaders["employeeHours"],
                        alphabetizedEmployees
                      )}
                    </tbody>
                  </table>
                ) : (
                  <PrevailingWagesTable
                    parFormDataState={parData}
                    phaseNum={phaseNum}
                    pwClassesByJob={pwClassesByJob}
                    editInfo={false}
                  />
                )
              ) : (
                <table>
                  <tbody>
                    {renderMobileRows(
                      columnHeaders["perDiem"],
                      alphabetizedEmployees
                    )}
                  </tbody>
                </table>
              )}
            </div>
          ) : (
            <div className="table-wrapper">
              {phaseNum !== "012130-00-00" ? (
                parData.jobCertified !== "Y" ? (
                  <table cellSpacing={0}>
                    <thead>
                      <tr>
                        {renderDesktopHeaders(columnHeaders["employeeHours"])}
                      </tr>
                    </thead>

                    <tbody>
                      {renderDesktopRows(
                        columnHeaders["employeeHours"],
                        alphabetizedEmployees
                      )}
                    </tbody>
                  </table>
                ) : (
                  <PrevailingWagesTable
                    parFormDataState={parData}
                    phaseNum={phaseNum}
                    editInfo={false}
                    pwClassesByJob={pwClassesByJob}
                  />
                )
              ) : (
                <table cellSpacing={0}>
                  <thead>
                    <tr>{renderDesktopHeaders(columnHeaders["perDiem"])}</tr>
                  </thead>

                  <tbody>
                    {renderDesktopRows(
                      columnHeaders["perDiem"],
                      alphabetizedEmployees
                    )}
                  </tbody>
                </table>
              )}
            </div>
          )}

          {parData.phaseCodes[phaseNum].laborData.length > 0 && (
            <h3 className="par-phase-total">
              Total Employee Hours:{" "}
              {phaseNum === "012130-00-00"
                ? calculateTotalPerDiemHours(
                    parData.phaseCodes[phaseNum].laborData,
                    parData
                  )
                : calculateSectionValues(
                    parData.phaseCodes[phaseNum].laborData,
                    "hoursWorked"
                  )}
            </h3>
          )}
        </div>
      )}

      {phaseNum !== "012130-00-00" &&
        parData.phaseCodes[phaseNum].equipmentData.length > 0 && (
          <div className="section-content">
            <label className="checkbox container hidden">
              <span className="checkmark" />
            </label>

            {windowWidth <= 650 ? (
              <div className="table-wrapper phase-table-wrapper">
                <table>
                  <tbody>
                    {renderMobileRows(
                      columnHeaders["equipmentData"],
                      parData.phaseCodes[phaseNum].equipmentData
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="table-wrapper">
                <table cellSpacing={0}>
                  <thead>
                    <tr>
                      {renderDesktopHeaders(columnHeaders["equipmentData"])}
                    </tr>
                  </thead>

                  <tbody>
                    {renderDesktopRows(
                      columnHeaders["equipmentData"],
                      parData.phaseCodes[phaseNum].equipmentData
                    )}
                  </tbody>
                </table>
              </div>
            )}

            {parData.phaseCodes[phaseNum].equipmentData.length > 0 &&
              urlPath !== "select-print" && (
                <h3 className="par-phase-total">
                  Total Equipment Hours:{" "}
                  {calculateSectionValues(
                    parData.phaseCodes[phaseNum].equipmentData,
                    "operatedHours"
                  )}
                </h3>
              )}
          </div>
        )}

      {phaseNum !== "012130-00-00" &&
        parData.phaseCodes[phaseNum].progressEntry.length > 0 && (
          <div className="section-content">
            <label className="checkbox container hidden">
              <span className="checkmark" />
            </label>

            {windowWidth <= 650 ? (
              <div className="table-wrapper phase-table-wrapper">
                <table>
                  <tbody>
                    {renderMobileRows(
                      columnHeaders["progressEntry"],
                      parData.phaseCodes[phaseNum].progressEntry
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="table-wrapper">
                <table cellSpacing={0}>
                  <thead>
                    <tr>
                      {renderDesktopHeaders(columnHeaders["progressEntry"])}
                    </tr>
                  </thead>

                  <tbody>
                    {renderDesktopRows(
                      columnHeaders["progressEntry"],
                      parData.phaseCodes[phaseNum].progressEntry
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}

      <ApprovalsEmptyData parData={parData.phaseCodes[phaseNum]} />
    </div>
  );
};

export default VerificationPhasecode;
