import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner-container">
      <FontAwesomeIcon icon="fa fa-circle-notch" spin />
    </div>
  );
};

export default LoadingSpinner;
