import { useState, useContext, createContext, useCallback } from "react";

import asyncAPICall from "../../util/apiWrapper";

import { useAuthInfo } from "./AuthContext";

const UserApprovalParsContext = createContext(null);

export const UserApprovalParsContextProvider = ({ children }) => {
  const [pendingApprovalPars, setPendingApprovalPars] = useState([]);
  const { user } = useAuthInfo();

  const handleChangePendingApprovalPars = (updatedPars) => {
    setPendingApprovalPars(updatedPars);
  };

  const getPendingApprovalPars = useCallback(() => {
    if (user?.employeeID)
      asyncAPICall(
        `api:W2oHkD04/partblmainByApprover?searchedApprover=${user.employeeID}`,
        "GET",
        null,
        null,
        (data) => {
          setPendingApprovalPars(data);
        },
        (err) => {
          console.error("error getting approval PAR reports", err);
        },
        null,
        true
      );
  }, [user?.employeeID]);

  const values = {
    pendingApprovalPars,
    getPendingApprovalPars,
    handleChangePendingApprovalPars,
  };

  return (
    <UserApprovalParsContext.Provider value={values}>
      {children}
    </UserApprovalParsContext.Provider>
  );
};

export const useUserPendingApprovalPars = () => {
  return useContext(UserApprovalParsContext);
};
