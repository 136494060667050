import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

import Truncate from "./helpers/Truncate";

const PastPhases = (props) => {
  const { phaseObj, setShowDropdown, setPhaseCodes, setTimes } = props;
  const [minimize, setMinimize] = useState(true);

  const renderPhases = () => {
    return Object.keys(phaseObj.phases).map((phase, idx) => {
      return (
        <div className="phase" key={idx}>
          {phaseObj.phases[phase].phaseDescription?.length > 32
            ? phaseObj.phases[phase].phaseDescription.slice(0, 29) + "..."
            : phaseObj.phases[phase].phaseDescription}
        </div>
      );
    });
  };

  const handlePhases = () => {
    setShowDropdown(false);
    setTimes({
      startTime: phaseObj.startTime,
      stopTime: phaseObj.stopTime,
    });
    const newPhaseCodes = {};
    Object.keys(phaseObj.phases).forEach((phase) => {
      const newPhase = { ...phaseObj.phases[phase] };
      newPhase.progressEntry = [];
      newPhaseCodes[phase] = newPhase;
    });
    setPhaseCodes(newPhaseCodes);
  };

  return (
    <div className="date-container">
      <div className="header-date">
        <h3 onClick={handlePhases}>
          <Truncate
            content={`${phaseObj.phaseDate} - ${phaseObj.submitter}`}
            maxChar={33}
          />
        </h3>

        <FontAwesomeIcon
          icon={`fas fa-chevron-${minimize ? "up" : "down"}`}
          onClick={() => setMinimize((m) => !m)}
        />
      </div>

      <div
        className={`phases-container ${
          minimize ? "hide-phases-container" : ""
        }`}
      >
        {renderPhases()}
      </div>
    </div>
  );
};

export default PastPhases;
