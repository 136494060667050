const ExternalLink = (props) => {
  const { link, text, className } = props;

  return (
    <a
      className={`external-link ${className}`}
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      {text}
    </a>
  );
};

export default ExternalLink;
