import { useState } from "react";

import ConfirmationModal from "../../../components/modals/confirmation-modal/ConfirmationModal";
import ExpandCollapseSection from "../../../components/core/ExpandCollapseSection";
import SubcontractorsSectionItem from "./SubcontractorsSectionItem";
import { ParTable } from "../../../components/helpers/ParFormComponents";

import useAbortEffect from "../../../components/hooks/useAbortEffect";

import asyncAPICall from "../../../util/apiWrapper";
import { useCurrentPar } from "../../../components/contexts/CurrentParContext";
import { useModalContext } from "../../../components/contexts/ModalContext";


const SubcontractorsSection = () => {
  const [approvedSubcontractors, setApprovedSubcontractors] = useState([]);
  const [selectedSubcontractor, setSelectedSubcontractor] = useState("");

  const { parFormDataState, parDispatch } = useCurrentPar();
  const { handleCloseModal, isModalOpen } = useModalContext();

  const { jobNumber, subcontractor, checkboxes } = parFormDataState;

  const addSubcontractor = (subcontractorType) => {
    parDispatch({
      type: "addSubcontractor",
      subcontractorType,
    });
  };

  const updateSubcontractor = (e, id, updateField) => {
    parDispatch({
      type: "updateSubcontractor",
      updateField: updateField,
      value: e.target.value,
      id: id,
    });
  };

  const deleteSubcontractor = () => {
    parDispatch({
      type: "deleteSubcontractor",
      payload: {
        subcontractor: selectedSubcontractor.id,
      },
    });
    handleCloseModal("removeSubcontractor");
  };

  const renderSubcontractors = () => {
    return subcontractor.map((row) => {
      return (
        <SubcontractorsSectionItem
          key={row.id}
          {...row}
          updateSubcontractor={updateSubcontractor}
          setSelectedSubcontractor={setSelectedSubcontractor}
          approvedSubcontractors={approvedSubcontractors}
        />
      );
    });
  };

  useAbortEffect(
    (signal) => {
      if (jobNumber) {
        asyncAPICall(
          `api:E3-qrfOe/vbhisubcontractorsByVendor?jobSearch=${jobNumber}`,
          "GET",
          null,
          null,
          (data) => {
            if (data) {
              setApprovedSubcontractors(data);
            }
          },
          (err) => {
            if (!signal.aborted) {
              console.error(`error retrieving subcontractors, ${err}`);
            }
          },
          signal
        );
      }
    },
    [jobNumber]
  );

  return (
    <>
      <ExpandCollapseSection
        sectionName={"Subcontractors"}
        checkboxValue={checkboxes.subcontractor}
      >
        {subcontractor?.length > 0 ? (
          <ParTable
            tableClass="subcontractors-table"
            tableHeaders={[
              { class: "header type", name: "Type" },
              { class: "header name", name: "Name" },
              {
                class: "header description",
                name: "Description",
              },
              {
                class: "header duration",
                name: "Shift Duration (In Hours)",
              },
              { class: "header count", name: "Employee Count" },
            ]}
            tableRows={renderSubcontractors()}
          />
        ) : (
          <h3> No Subcontractors Entered </h3>
        )}

        <div className="subcontractor-buttons-wrapper">
          <button
            className="subcontractor-button"
            onClick={() => addSubcontractor("bhi")}
            disabled={approvedSubcontractors.length === 0}
          >
            Add BHI
          </button>

          <button
            className="subcontractor-button"
            onClick={() => addSubcontractor("supplemental")}
          >
            Add Supplemental
          </button>
        </div>
      </ExpandCollapseSection>

      <ConfirmationModal
        isModalOpen={isModalOpen.removeSubcontractor}
        onRequestClose={() => handleCloseModal("removeSubcontractor")}
        modalQuestion={`Are you sure you want to remove: ${
          selectedSubcontractor.name?.length > 0
            ? selectedSubcontractor.name
            : `"untitled" `
        }?`}
        onConfirm={() => deleteSubcontractor()}
      />
    </>
  );
};
export default SubcontractorsSection;
