import {
  useReducer,
  useContext,
  createContext,
  useCallback,
  useState,
} from "react";

import { handleModalOpeningReducer } from "../reducer/modalReducer";
import { initialModalState } from "../reducer/store/initialModalState";

const ModalContext = createContext(null);

export const ModalContextProvider = ({ children }) => {
  const [modalDescription, setModalDescription] = useState("");
  const [state, dispatch] = useReducer(
    handleModalOpeningReducer,
    initialModalState
  );

  const handleOpenModal = useCallback((modalName) => {
    dispatch({ type: "openModal", modalName });
    setModalDescription(modalName);
  }, []);

  const handleCloseModal = useCallback((modalName) => {
    dispatch({ type: "closeModal", modalName });
  }, []);

  const values = {
    isModalOpen: state,
    handleOpenModal,
    handleCloseModal,
    modalDescription,
  };

  return (
    <ModalContext.Provider value={values}>{children}</ModalContext.Provider>
  );
};

export const useModalContext = () => {
  return useContext(ModalContext);
};
