import { useEffect, useState } from "react";

import LoadingSpinner from "../helpers/LoadingSpinner";

import useAbortEffect from "../hooks/useAbortEffect";
import { useCurrentPar } from "../contexts/CurrentParContext";
import { useAuthInfo } from "../contexts/AuthContext";

import asyncAPICall from "../../util/apiWrapper";
import { useModalContext } from "../contexts/ModalContext";
import { successfulToast, errorToast } from "../../util/toastNotifications";

const PrintEmailForm = ({ sendEmail, setSendEmail }) => {
  const { parFormDataState } = useCurrentPar();
  const { handleCloseModal } = useModalContext();

  const defaultEmailArray = [
    "industrial42@bhico.com",
    "wireless.comm@bhico.com",
    "bakken.electric@bhico.com",
    "civil.excavation@bhico.com",
    "facilities.pipeline@bhico.com",
    "commercial.electric@bhico.com",
  ];

  const [emailArray, setEmailArray] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const { user } = useAuthInfo();

  const handleSetEmail = async () => {
    setIsSending(true);

    try {
      const emailData = {
        to_email: sendEmail,
        template_id: "d-1a19486813524880829e09c2a962270f",
        data: {
          jobNumber: parFormDataState.jobNumber,
          date: parFormDataState.date,
          submitter: user.name,
          link: `https://par.bhico.com/select-print/${parFormDataState.dcrNumber}`,
        },
      };

      await asyncAPICall(
        "api:5e9BgwVw/sendgrid/dynamicEmail",
        "POST",
        emailData,
        null,
        () => {
          successfulToast(`T&M Email sent successfully to ${sendEmail}`);
          handleCloseModal("printEmail");
        },
        (err) => {
          console.error("Error sending T&M email:", err);
          errorToast("Failed to send T&M email");
          setIsSending(false);
        }
      );
    } catch (error) {
      console.error("Error in handleSetEmail:", error);
      errorToast("Failed to send T&M email");
      setIsSending(false);
    }
  };

  const handleClearEmail = () => {
    setSendEmail("");
    handleCloseModal("printEmail");
  };

  useAbortEffect((signal) => {
    asyncAPICall(
      `api:W2oHkD04/vmultipleapproversbyjob?job=${parFormDataState.jobNumber}`,
      "GET",
      null,
      null,
      (data) => {
        if (data) {
          const emailArray = [...defaultEmailArray];

          data.forEach((singleEmail) => {
            if (!emailArray.includes(singleEmail.Email)) {
              emailArray.push(singleEmail.Email);
            }
          });
          setEmailArray(emailArray);
        }
      },
      (err) => {
        if (!signal.aborted) {
          console.error("Error Getting Approver Emails", err);
        }
      },
      signal
    );
  }, []);

  useEffect(() => {
    setIsDisabled(() => (sendEmail?.length ? false : true));
  }, [sendEmail]);

  return (
    <div className="main-modal-container">
      <div className="inside-container">
        <h1>Select an Email for T&M Report</h1>

        <div className="select-email-wrapper">
          {emailArray.length ? (
            <select
              name="email-select"
              className="email-select"
              defaultValue={sendEmail}
              onChangeCapture={(e) => {
                setSendEmail(e.target.value);
              }}
            >
              <option value="">** Please Select an Email **</option>
              {emailArray.map((email) => (
                <option value={email} className="rendered-email" key={email}>
                  {email}
                </option>
              ))}
            </select>
          ) : (
            <LoadingSpinner />
          )}
        </div>

        <div className="buttons-wrapper">
          <button onClick={handleClearEmail}>Cancel</button>
          <button disabled={isDisabled || isSending} onClick={handleSetEmail}>
            {isSending ? "Sending..." : "Send Email"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PrintEmailForm;
