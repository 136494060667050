import { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  ParInput,
  ParTextArea,
} from "../../../components/helpers/ParFormComponents";

import { useCurrentPar } from "../../../components/contexts/CurrentParContext";

import useAbortSignal from "../../../components/hooks/useAbortSignal";

import { errorToast } from "../../../util/toastNotifications";
import asyncAPICall from "../../../util/apiWrapper";

const WeatherInformation = () => {
  const [isWeatherLoading, setIsWeatherLoading] = useState(false);
  const [jobLocation, setJobLocation] = useState(null);

  const { parFormDataState, parDispatch, resetting } = useCurrentPar();
  const {
    jobNumber,
    wind,
    precipitation,
    temperatureHigh,
    temperatureLow,
    forecast,
  } = parFormDataState;
  const { signal } = useAbortSignal();

  const getWeather = useCallback(async () => {
    setIsWeatherLoading(true);
    const accuWeatherKey = process.env.REACT_APP_ACCUWEATHER_API_KEY;
    try {
      if (jobNumber) {
        if (jobLocation) {
          const locationKey = await fetch(
            `https://dataservice.accuweather.com/locations/v1/cities/geoposition/search?apikey=${accuWeatherKey}&q=${jobLocation}`
          )
            .then((res) => {
              if (res.ok) {
                return res.json();
              } else {
                errorToast(
                  "Location Error: Unable to Get Location Data From AccuWeather."
                );
                return null;
              }
            })
            .then((data) => {
              return data.Key;
            });

          await fetch(
            `https://dataservice.accuweather.com/forecasts/v1/daily/1day/${locationKey}?apikey=${accuWeatherKey}&details=true`
          )
            .then((res) => {
              if (res.ok) {
                return res.json();
              } else {
                errorToast("AccuWeather Service Error: No Forecast Available");
                return null;
              }
            })
            .then((data) => {
              if (Object.keys(data).length > 0) {
                const dailyForeCast = data.DailyForecasts[0];
                const payload = {
                  temperatureHigh:
                    dailyForeCast.Temperature.Maximum.Value?.toString(),
                  temperatureLow:
                    dailyForeCast.Temperature.Minimum.Value?.toString(),
                  wind: `${dailyForeCast.Day.Wind.Speed.Value} MPH Gusts up to ${dailyForeCast.Day.WindGust.Speed.Value} MPH`,
                  forecast: dailyForeCast.Day.LongPhrase,
                  precipitation: dailyForeCast.Day.PrecipitationType
                    ? dailyForeCast.Day.PrecipitationType
                    : "None",
                };
                parDispatch({
                  type: "setWeatherInputs",
                  payload: payload,
                });
              }
              setIsWeatherLoading(false);
              return data;
            });
        } else {
          setIsWeatherLoading(false);
          errorToast("Location Error: Job Location Missing From Viewpoint.");
        }
      } else {
        setIsWeatherLoading(false);
        errorToast("Please select a job number");
      }
    } catch (error) {
      console.error("Get Weather Error: ", error);
      setIsWeatherLoading(false);
    }
  }, [jobLocation, parDispatch, jobNumber]);

  useEffect(() => {
    if (resetting) setJobLocation(null);
  }, [resetting]);

  useEffect(() => {
    if (jobNumber)
      asyncAPICall(
        `api:W2oHkD04/vlatlongByJob?jobSearch=${jobNumber}`,
        "GET",
        null,
        null,
        (data) => {
          if (!signal.aborted) setJobLocation(data[0]?.latLong);
        },
        () => {
          if (!signal.aborted) {
            console.error(
              `No Location Information Set for Job Number ${jobNumber}.`
            );
            errorToast(
              `No Location Information Set for Job Number ${jobNumber}.`
            );
          }
        },
        signal
      );
  }, [jobNumber, signal]);

  return (
    <div className="weather-information-wrapper">
      <button
        className="get-weather-btn"
        onClick={() => getWeather()}
        disabled={!isWeatherLoading && !jobLocation}
      >
        {!isWeatherLoading ? (
          <FontAwesomeIcon icon="fa fa-cloud-sun-rain" />
        ) : (
          <FontAwesomeIcon icon="fa fa-spinner" />
        )}
        {!jobLocation ? " No Job Location" : " Weather Autofill"}
      </button>

      <ParInput
        type="number"
        label="Temp High"
        value={temperatureHigh}
        onChange={(e) =>
          parDispatch({
            type: "updateInputField",
            payload: {
              field: "temperatureHigh",
              value: e.target.value,
            },
          })
        }
        disabled={resetting}
      />

      <ParInput
        type="number"
        label="Temp Low"
        value={temperatureLow}
        onChange={(e) =>
          parDispatch({
            type: "updateInputField",
            payload: {
              field: "temperatureLow",
              value: e.target.value,
            },
          })
        }
        disabled={resetting}
      />

      <ParInput
        type="text"
        label="Wind"
        customClass="wind-wrapper"
        value={wind}
        onChange={(e) =>
          parDispatch({
            type: "updateInputField",
            payload: {
              field: "wind",
              value: e.target.value,
            },
          })
        }
        disabled={resetting}
      />

      <ParInput
        type="text"
        label="Precipitation"
        value={precipitation}
        onChange={(e) =>
          parDispatch({
            type: "updateInputField",
            payload: {
              field: "precipitation",
              value: e.target.value,
            },
          })
        }
        disabled={resetting}
      />

      <ParTextArea
        customClass="forecast-wrapper"
        labelName="Forecast"
        rows={4}
        cols={10}
        type="text"
        value={forecast}
        onChange={(e) =>
          parDispatch({
            type: "updateInputField",
            payload: {
              field: "forecast",
              value: e.target.value.slice(0, 76),
            },
          })
        }
        disabled={resetting}
      />
    </div>
  );
};

export default WeatherInformation;
