import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const ParNotificationItem = ({
  formData,
  handleSelectPendingPar,
  to,
  showDelete = true,
  linkText = "Approve?",
  gradientClass = "blue-gradient",
  checkIconColor = "#0066b2",
  icon = faCheckCircle,
}) => {
  return (
    <div className={`par-notification-container ${gradientClass}`}>
      <div className="date-info">
        <FontAwesomeIcon
          icon={icon}
          className="check-icon"
          style={{ color: checkIconColor }}
        />
        <span className="date">{formData.date}</span>
      </div>

      <div className="submitter-info">
        <span className="submitter-name">
          {formData.submitterName.length > 0 && formData.submitterName[0]?.name
            ? formData.submitterName[0].name
            : "Former Employee"}
        </span>
      </div>

      <Link className="approve-header" to={to}>
        {linkText}
      </Link>

      <div className="delete-icon-wrapper">
        {showDelete && (
          <FontAwesomeIcon
            onClick={() => handleSelectPendingPar(formData)}
            icon="fa fa-trash"
            className="delete-icon"
          />
        )}
      </div>
    </div>
  );
};

export default ParNotificationItem;
