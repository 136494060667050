import { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";

import ConfirmationModal from "../../components/modals/confirmation-modal/ConfirmationModal";
import GeneralCheckbox from "../../components/core/GeneralCheckbox";
import HorizontalRule from "../../components/core/HorizontalRule";
import PrintEmailForm from "../../components/forms/PrintEmailForm";
import ApproverChanges from "../../components/ApproverChanges";
import VerificationTotals from "./VerificationTotals";
import Modal from "../../components/modals/Modal";
import ReportDetails from "./ReportDetails";
import ReportHeader from "./ReportHeader";
import ReportNotes from "./ReportNotes";
import ReportPhaseTables from "./ReportPhaseTables";
import ReportTable from "./ReportTable";
import ReportSignatures from "./ReportSignatures";
import { useCurrentPar } from "../../components/contexts/CurrentParContext";
import { useModalContext } from "../../components/contexts/ModalContext";
import { ParTextArea } from "../../components/helpers/ParFormComponents";
import {
  dailyTicketCheckboxValues,
  baseCheckboxValues,
  reportTableData,
  basePageData,
} from "../../components/helpers/verificationHelpers";

import useWindowWidth from "../../components/hooks/useWindowWidth";
import useParReport from "../../components/hooks/useParReport";

import letterHead from "../../img/letter-head.svg";

import ActionButtons from "../../components/core/ActionButtons";

function VerificationPage() {
  const { pathname, state } = useLocation();
  const split_path = pathname.split("/");
  const isSelectPrint = split_path[1] === "select-print";

  const [pageData, setPageData] = useState(basePageData(isSelectPrint));
  const [isPrinting, setIsPrinting] = useState(0);
  const [pageBlur, setPageBlur] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");
  const [dailyTicket, setDailyTicket] = useState({
    isDailyTicket: false,
    offValues: {
      ...baseCheckboxValues(isSelectPrint),
    },
  });

  const { windowWidth, setWindowWidth, windowWidthRef } = useWindowWidth();
  const { isModalOpen, handleCloseModal } = useModalContext();
  const { parData, checkboxValues, handleCheckmarks } = useParReport(
    baseCheckboxValues(isSelectPrint)
  );
  const { parFormDataState } = useCurrentPar();

  const isConfidential =
    !dailyTicket.isDailyTicket &&
    (checkboxValues.report.foremanJournal ||
      checkboxValues.report.forecastWork ||
      checkboxValues.report.issues ||
      (checkboxValues.report.changes && parData.statusComments?.length > 0));

  const view = pathname.split("/")[2];

  const handlePageData = (field, value) => {
    setPageData((prev) => ({ ...prev, [field]: value }));
  };

  const handleDailyCheck = () => {
    if (!dailyTicket.isDailyTicket) {
      handleCheckmarks("report", dailyTicketCheckboxValues);

      handleCheckmarks("combined", (prev) =>
        prev.map((checkbox) => ({ index: checkbox.index, phase: true }))
      );
    } else {
      handleCheckmarks("report", baseCheckboxValues(isSelectPrint));
    }
    setDailyTicket((dT) => {
      return { ...dT, isDailyTicket: !dT.isDailyTicket };
    });
  };

  const delay = useCallback(
    () =>
      setTimeout(() => {
        setIsPrinting(1);
      }, 500),
    []
  );

  const handlePrintCheck = (type) => {
    const newCheckboxValues = { ...checkboxValues.report };

    newCheckboxValues[type] = !newCheckboxValues[type];

    handleCheckmarks("report", newCheckboxValues);
  };

  const handlePrint = () => {
    handleCloseModal("dailyTicket");
    setPageBlur(true);
    delay();

    return setWindowWidth((prev) => {
      if (prev < 651) {
        return 651;
      } else {
        return prev;
      }
    });
  };

  const changePrint = useCallback(
    (updateField, index) => {
      handleCheckmarks("combined", (prevCheckboxValue) =>
        prevCheckboxValue.map((checkboxObj) => {
          if (checkboxObj.index === index) {
            return { ...checkboxObj, [updateField]: !checkboxObj[updateField] };
          } else {
            return checkboxObj;
          }
        })
      );
    },
    [handleCheckmarks]
  );

  useEffect(() => {
    if (isPrinting === 1 && windowWidth > 650) {
      window.print();
      setIsPrinting(2);
    }

    return () => clearTimeout(delay);
  }, [isPrinting, windowWidth, delay]);

  useEffect(() => {
    if (isPrinting === 2) {
      setWindowWidth(windowWidthRef.current);
      setIsPrinting(0);
      setPageBlur(false);
    }
  }, [isPrinting, setWindowWidth, windowWidthRef]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isFromApprovals = state?.fromApprovals;

  return (
    <div
      className={`verification-page-container ${
        isSelectPrint ? "select-print" : "approval-verification"
      } ${isSelectPrint && pageBlur && windowWidth <= 651 ? "blur" : ""}`}
    >
      <ReportHeader
        image={isSelectPrint && letterHead}
        header={
          isSelectPrint
            ? "Select Which Section to Print"
            : `CHECK EACH SECTION CAREFULLY TO MAKE\nSURE ALL INFORMATION IS CORRECT`
        }
        subHeader={!isSelectPrint && "PROJECT ACTIVITY RECORD VERIFICATION"}
      />

      {!isSelectPrint && (
        <HorizontalRule
          wrapperClass="verification-hr-wrapper"
          ruleClass="verification-hr"
        />
      )}

      <ReportDetails
        parData={parData}
        handlePageData={handlePageData}
        pageData={pageData}
        wrapperClass={
          isSelectPrint && !checkboxValues.report.jobInfo ? "hide-print" : ""
        }
      >
        <GeneralCheckbox
          wrapperClass="verification-checkbox-wrapper"
          checked={checkboxValues.report.jobInfo}
          onChange={() =>
            isSelectPrint
              ? handlePrintCheck("jobInfo")
              : handleCheckmarks("report", (c) => {
                  return { ...c, jobInfo: !c.jobInfo };
                })
          }
          disabled={isSelectPrint && dailyTicket.isDailyTicket}
        />
        {isSelectPrint && (
          <div className="daily-ticket-container hide-print">
            <p>Select This Checkbox for Daily Ticket Fields</p>

            <GeneralCheckbox
              wrapperClass="verification-checkbox-wrapper"
              checked={dailyTicket.isDailyTicket}
              onChange={handleDailyCheck}
            />
          </div>
        )}
      </ReportDetails>

      <HorizontalRule
        wrapperClass="verification-hr-wrapper"
        ruleClass={`verification-hr ${
          isSelectPrint && !checkboxValues.report.jobInfo ? "hide-print" : ""
        }`}
      />

      <ReportNotes
        notesData={parData.notes}
        labelText={isSelectPrint ? "Daily Journal" : "PFS Daily Journal"}
        wrapperClass={
          isSelectPrint && !checkboxValues.report.pfsJournal
            ? "hide-print"
            : "no-checkbox"
        }
      >
        {isSelectPrint && (
          <GeneralCheckbox
            wrapperClass="verification-checkbox-wrapper"
            checked={checkboxValues.report.pfsJournal}
            onChange={() => handlePrintCheck("pfsJournal")}
            disabled={dailyTicket.isDailyTicket}
          />
        )}
      </ReportNotes>

      {isSelectPrint && (
        <HorizontalRule
          wrapperClass="verification-hr-wrapper"
          ruleClass={`verification-hr ${
            checkboxValues.report.foremanJournal ? "" : "hide-print"
          }`}
        />
      )}

      <ReportNotes
        notesData={parData.workPreformed}
        labelText="Foreman Daily Journal"
        wrapperClass={
          isSelectPrint && !checkboxValues.report.foremanJournal
            ? "hide-print"
            : "no-checkbox"
        }
      >
        {isSelectPrint && (
          <GeneralCheckbox
            wrapperClass="verification-checkbox-wrapper"
            checked={checkboxValues.report.foremanJournal}
            onChange={() => handlePrintCheck("foremanJournal")}
            disabled={dailyTicket.isDailyTicket}
          />
        )}
      </ReportNotes>

      {isSelectPrint && (
        <HorizontalRule
          wrapperClass="verification-hr-wrapper"
          ruleClass={`verification-hr ${
            checkboxValues.report.forecastWork ? "" : "hide-print"
          }`}
        />
      )}

      <ReportNotes
        notesData={parData.workForecasted}
        labelText="Foreman Forecasted Work"
        wrapperClass={
          isSelectPrint && !checkboxValues.report.forecastWork
            ? "hide-print"
            : "no-checkbox"
        }
      >
        {isSelectPrint && (
          <GeneralCheckbox
            wrapperClass="verification-checkbox-wrapper"
            checked={checkboxValues.report.forecastWork}
            onChange={() => handlePrintCheck("forecastWork")}
            disabled={dailyTicket.isDailyTicket}
          />
        )}
      </ReportNotes>

      <HorizontalRule
        wrapperClass="verification-hr-wrapper"
        ruleClass={`verification-hr ${
          checkboxValues.report.forecastWork ||
          checkboxValues.report.foremanJournal ||
          checkboxValues.report.pfsJournal ||
          isSelectPrint
            ? ""
            : "hide-print"
        }`}
      />

      <ReportTable
        wrapperClass={
          isSelectPrint && !checkboxValues.report.issues ? "hide-print" : ""
        }
        parData={parData.idb}
        windowWidth={windowWidth}
        tableHeader="Issues"
        tableData={reportTableData(parData)["issues"]}
      >
        <GeneralCheckbox
          wrapperClass="verification-checkbox-wrapper"
          checked={
            isSelectPrint
              ? checkboxValues.report.issues
              : checkboxValues.report.idb
          }
          onChange={() =>
            isSelectPrint
              ? handlePrintCheck("issues")
              : handleCheckmarks("report", (c) => {
                  return { ...c, idb: !c.idb };
                })
          }
          disabled={isSelectPrint && dailyTicket.isDailyTicket}
        />
      </ReportTable>

      <HorizontalRule
        wrapperClass="verification-hr-wrapper"
        ruleClass={`verification-hr ${
          checkboxValues.report.forecastWork ||
          checkboxValues.report.foremanJournal ||
          checkboxValues.report.pfsJournal ||
          isSelectPrint
            ? ""
            : "hide-print"
        }`}
      />

      <ReportTable
        wrapperClass={
          isSelectPrint && !checkboxValues.report.verbalCounsels
            ? "hide-print"
            : ""
        }
        parData={parData.hrvc}
        windowWidth={windowWidth}
        tableHeader="Verbal Counsels"
        tableData={reportTableData(parData)["hrvc"]}
      >
        <GeneralCheckbox
          wrapperClass="verification-checkbox-wrapper"
          checked={checkboxValues.report.verbalCounsels}
          onChange={() =>
            handleCheckmarks("report", (c) => {
              return { ...c, verbalCounsels: !c.verbalCounsels };
            })
          }
          disabled={isSelectPrint && dailyTicket.isDailyTicket}
        />
      </ReportTable>

      {!isSelectPrint && (
        <>
          <HorizontalRule
            wrapperClass="verification-hr-wrapper"
            ruleClass="verification-hr"
          />

          <div className="incidents-and-injuries-wrapper">
            <GeneralCheckbox
              wrapperClass="verification-checkbox-wrapper"
              checked={checkboxValues.report.problems}
              onChange={() =>
                handleCheckmarks("report", (c) => {
                  return { ...c, problems: !c.problems };
                })
              }
            />

            <ReportNotes
              notesData={parData.injuries}
              labelText="Injuries"
              wrapperClass="injuries-notes"
            />

            <ReportNotes
              notesData={parData.incidents}
              labelText="Incidents"
              wrapperClass="incidents-notes"
            />
          </div>

          <HorizontalRule
            wrapperClass="verification-hr-wrapper"
            ruleClass="verification-hr"
          />
        </>
      )}

      <ReportTable
        wrapperClass={
          isSelectPrint && !checkboxValues.report.subcontractor
            ? "hide-print"
            : ""
        }
        parData={parData.subcontractor}
        windowWidth={windowWidth}
        tableHeader="Subcontractors"
        tableData={reportTableData(parData)["subcontractors"]}
      >
        <GeneralCheckbox
          wrapperClass="verification-checkbox-wrapper"
          checked={checkboxValues.report.subcontractor}
          onChange={() =>
            isSelectPrint
              ? handlePrintCheck("subcontractor")
              : handleCheckmarks("report", (c) => {
                  return { ...c, subcontractor: !c.subcontractor };
                })
          }
          disabled={isSelectPrint && dailyTicket.isDailyTicket}
        />
      </ReportTable>

      <ReportTable
        wrapperClass={
          isSelectPrint && !checkboxValues.report.timeMaterials
            ? "hide-print"
            : ""
        }
        parData={[
          {
            matCustomer: parData.matCustomer,
            matCustomerCode: parData.matCustomerCode,
            matOsr: parData.matOsr,
            matJobsite: parData.matJobsite,
          },
        ]}
        windowWidth={windowWidth}
        tableHeader="T&M Customer"
        tableData={reportTableData(parData)["tAndMCustomer"]}
      >
        <GeneralCheckbox
          wrapperClass="verification-checkbox-wrapper"
          checked={checkboxValues.report.timeMaterials}
          onChange={() =>
            isSelectPrint
              ? handlePrintCheck("timeMaterials")
              : handleCheckmarks("report", (c) => {
                  return { ...c, timeMaterials: !c.timeMaterials };
                })
          }
          disabled={isSelectPrint && dailyTicket.isDailyTicket}
        />
      </ReportTable>

      <ReportTable
        wrapperClass={
          isSelectPrint && !checkboxValues.report.material ? "hide-print" : ""
        }
        parData={parData.material}
        windowWidth={windowWidth}
        tableHeader="Materials"
        tableData={reportTableData(parData, isSelectPrint)["materials"]}
      >
        <GeneralCheckbox
          wrapperClass="verification-checkbox-wrapper"
          checked={checkboxValues.report.material}
          onChange={() =>
            isSelectPrint
              ? handlePrintCheck("material")
              : handleCheckmarks("report", (c) => {
                  return { ...c, material: !c.material };
                })
          }
          disabled={isSelectPrint && dailyTicket.isDailyTicket}
        />
      </ReportTable>

      <HorizontalRule
        wrapperClass="verification-hr-wrapper"
        ruleClass={`verification-hr ${
          isSelectPrint && !checkboxValues.report.totals ? "hide-print" : ""
        }`}
      />

      <ReportPhaseTables
        parData={parData}
        windowWidth={windowWidth}
        checkboxValues={checkboxValues}
        handleCheckmarks={handleCheckmarks}
        changeDisabledSubmit={changePrint}
        isDailyTicket={dailyTicket.isDailyTicket}
      />

      {!isSelectPrint && (
        <HorizontalRule
          wrapperClass="verification-hr-wrapper"
          ruleClass="verification-hr"
        />
      )}

      <VerificationTotals
        formData={parData}
        windowWidth={windowWidth}
        wrapperClass={
          isSelectPrint && !checkboxValues.report.totals ? "hide-print" : ""
        }
      >
        {isSelectPrint && (
          <GeneralCheckbox
            wrapperClass="verification-checkbox-wrapper"
            checked={checkboxValues.report.totals}
            onChange={() => handlePrintCheck("totals")}
            disabled={dailyTicket.isDailyTicket}
          />
        )}
      </VerificationTotals>

      {(parData.statusComments?.length > 0 ||
        (!isSelectPrint && parFormDataState.statusComments?.length > 0)) && (
        <ApproverChanges
          wrapperClass="verification-approvals-wrapper"
          parData={
            !isSelectPrint && view === "par" ? parFormDataState : parData
          }
          color={"black"}
        >
          {isSelectPrint && (
            <GeneralCheckbox
              wrapperClass="verification-checkbox-wrapper"
              checked={checkboxValues.report.changes}
              onChange={() => handlePrintCheck("changes")}
              disabled={dailyTicket.isDailyTicket}
            />
          )}
        </ApproverChanges>
      )}

      {isSelectPrint && (
        <>
          <HorizontalRule
            wrapperClass="verification-hr-wrapper"
            ruleClass={`verification-hr ${
              checkboxValues.report.timeMaterials ? "" : "hide-print"
            }`}
          />

          <ReportSignatures
            wrapperClass={!checkboxValues.report.signatures ? "hide-print" : ""}
          >
            <GeneralCheckbox
              wrapperClass="verification-checkbox-wrapper"
              checked={checkboxValues.report.signatures}
              onChange={() => handlePrintCheck("signatures")}
              disabled={dailyTicket.isDailyTicket}
            />
          </ReportSignatures>
        </>
      )}

      {isConfidential && isSelectPrint && (
        <div className="confidential-msg">
          * Confidential: Intended For Internal Use Only *
        </div>
      )}

      <ActionButtons
        isFromApprovals={isFromApprovals}
        rejectMessage={rejectMessage}
        pageData={pageData}
        parData={parData}
        handlePrint={handlePrint}
      />

      {!isSelectPrint && view === "confirm" && (
        <ParTextArea
          customClass={"reject-message-text"}
          labelName={"Comments on Rejected PAR"}
          rows={5}
          cols={50}
          placeholder={
            "Enter any changes that need to be made for the PAR to be approved."
          }
          value={rejectMessage}
          onChange={(e) => setRejectMessage(e.target.value)}
        />
      )}

      {isSelectPrint ? (
        <ConfirmationModal
          isModalOpen={isModalOpen.dailyTicket}
          onRequestClose={() => handleCloseModal("dailyTicket")}
          modalQuestion={
            "Warning: You are PRINTING internal information, not intended for externally sharing."
          }
          onConfirm={handlePrint}
          yesButtonText={"Print"}
          noButtonText={"Cancel"}
        />
      ) : (
        <Modal
          isModalOpen={isModalOpen.printEmail}
          onRequestClose={() => handleCloseModal("printEmail")}
        >
          <PrintEmailForm
            sendEmail={pageData.sendEmail}
            setSendEmail={(email) => handlePageData("sendEmail", email)}
          />
        </Modal>
      )}
    </div>
  );
}

export default VerificationPage;
