import { useState, useMemo } from "react";

import GeneralModalLayout from "../modals/GeneralModalLayout";

import { useCurrentPar } from "../contexts/CurrentParContext";

const AddClassForm = (props) => {
  const {
    pwClassesByJob,
    isLoading,
    phaseNum,
    employeeId,
    classesSelected,
    setClassesSelected,
    setIsPrevailingWageModalOpen,
  } = props;

  const [searchResult, setSearchResult] = useState("");
  const { parDispatch } = useCurrentPar();

  const submit = () => {
    parDispatch({
      type: "updatePhaseCodeField",
      payload: {
        phaseCode: phaseNum,
        attributeName: "laborData",
        fieldName: "classes",
        updatedValue: classesSelected,
        rowId: employeeId,
        filterName: "id",
      },
    });
    setClassesSelected({});
    setIsPrevailingWageModalOpen(false);
  };

  const handleClassClick = (jobClass) => {
    if (jobClass in classesSelected) {
      const filteredClasses = {};
      for (const key in classesSelected) {
        if (key !== jobClass) {
          filteredClasses[key] = "0";
        }
      }

      setClassesSelected({ ...filteredClasses });
    } else {
      setClassesSelected({
        ...classesSelected,
        [jobClass]: "0",
      });
    }
  };

  const filteredSearchClasses = useMemo(
    () =>
      pwClassesByJob.filter((searchedClass) =>
        searchedClass.Description.toLowerCase().includes(
          searchResult.toLowerCase()
        )
      ),
    [pwClassesByJob, searchResult]
  );

  const classesToShow = searchResult ? filteredSearchClasses : pwClassesByJob;

  const renderPrevailingWages = () => {
    return classesToShow.map((jobClass) => {
      return (
        <div
          key={jobClass.Class}
          data-title={jobClass.Description}
          onClick={() => handleClassClick(jobClass.Class)}
          className={`selectable-item-wrapper ${
            jobClass.Class in classesSelected ? "selected" : ""
          }`}
        >
          <p>{`${jobClass.Description}`}</p>
        </div>
      );
    });
  };

  return (
    <GeneralModalLayout
      title={"Select A Class"}
      isLoading={isLoading}
      renderModalInfo={renderPrevailingWages}
      handleSubmit={submit}
      setSearchValue={setSearchResult}
    />
  );
};
export default AddClassForm;
