import { useCurrentPar } from "../../../components/contexts/CurrentParContext";

const OvernightCheckbox = ({ handleOvernightCheckbox }) => {
  const { parFormDataState } = useCurrentPar();
  const { checkboxes } = parFormDataState;

  return (
    <div className="overnight-checkbox">
      <input
        id="overnight-confirmation"
        type="checkbox"
        checked={checkboxes.overnight}
        onChange={handleOvernightCheckbox}
      />
      <label htmlFor="overnight-confirmation">Overnight Hours?</label>
    </div>
  );
};
export default OvernightCheckbox;
