import { useState } from "react";

import PhaseCodeForm from "../../../components/forms/PhaseCodeForm";
import EquipmentForm from "../../../components/forms/EquipmentForm";
import AddAutoFill from "../../../components/forms/AddAutofill";
import AddJobForm from "../../../components/forms/AddJobForm";
import AddEmployee from "../../../components/forms/AddEmployee";
import Modal from "../../../components/modals/Modal";

import useAbortEffect from "../../../components/hooks/useAbortEffect";

import { useCurrentPar } from "../../../components/contexts/CurrentParContext";
import { useModalContext } from "../../../components/contexts/ModalContext";
import asyncAPICall from "../../../util/apiWrapper";

export default function ParJobInformationButtons({ handleAddPhaseCode }) {
  const { isModalOpen, handleOpenModal, handleCloseModal, modalDescription } =
    useModalContext();

  const { parFormDataState, allPhaseEmployees, allPhaseEquipment } =
    useCurrentPar();
  const { jobNumber } = parFormDataState;

  const [prevAllPhaseEmployees, setPrevAllPhaseEmployees] = useState([]);
  const [prevAllPhaseEquipment, setPrevAllPhaseEquipment] = useState([]);
  const [phaseCodeOptions, setPhaseCodeOptions] = useState([]);
  const [autofillData, setAutofillData] = useState([]);

  const modalChildren = {
    addJob: <AddJobForm />,
    addAutofill: <AddAutoFill autofillData={autofillData} />,
    addPhaseCode: (
      <PhaseCodeForm
        bottomPhaseAdd={false}
        phaseCodeOptions={phaseCodeOptions}
      />
    ),
    addAllPhaseEmployee: (
      <AddEmployee
        prevAllPhaseEmployees={prevAllPhaseEmployees}
        closeModal={() => handleCloseModal("addAllPhaseEmployee")}
      />
    ),
    addAllPhaseEquipment: (
      <EquipmentForm
        hoursWorked={"0"}
        prevAllPhaseEquipment={prevAllPhaseEquipment}
        closeModal={() => handleCloseModal("addAllPhaseEquipment")}
      />
    ),
  };

  const handleEmployeeAutofill = () => {
    setPrevAllPhaseEmployees([...allPhaseEmployees]);
    handleOpenModal("addAllPhaseEmployee");
  };

  const handleEquipmentAutofill = () => {
    setPrevAllPhaseEquipment([...allPhaseEquipment]);
    handleOpenModal("addAllPhaseEquipment");
  };

  useAbortEffect(
    (signal) => {
      if (jobNumber) {
        asyncAPICall(
          `api:KE7MCNYf/vprojectphasesbyproject?jobNumber=${jobNumber}`,
          "GET",
          null,
          null,
          (data) => {
            if (data) {
              setPhaseCodeOptions(data);
            }
          },
          (err) => {
            if (!signal.aborted) {
              console.error("Getting Phases Error", err);
            }
          },
          signal
        );

        asyncAPICall(
          `api:W2oHkD04/dcrtblautofill?jobNumber=${jobNumber}`,
          "GET",
          null,
          null,
          (data) => {
            if (data) {
              setAutofillData(data);
            }
          },
          (err) => {
            if (!signal.aborted) {
              console.error("Getting Autofill Data Error", err);
            }
          },
          signal
        );
      }
    },
    [jobNumber]
  );

  return (
    <div className="par-button-wrapper">
      <button
        className="autofill-phase-code"
        onClick={() => handleOpenModal("addAutofill")}
      >
        Autofill Phase Codes
      </button>

      <button
        className="add-phase-code"
        onClick={() => handleOpenModal("addPhaseCode")}
      >
        Add Phase Codes
      </button>

      <button className="employee-autofill" onClick={handleEmployeeAutofill}>
        All Phase Employee Add
      </button>

      <button className="equipment-autofill" onClick={handleEquipmentAutofill}>
        All Phase Equipment Add
      </button>

      {Object.keys(modalChildren).includes(modalDescription) && (
        <Modal
          isModalOpen={isModalOpen[modalDescription]}
          onRequestClose={() => handleCloseModal(modalDescription)}
          content={
            modalDescription === "addIssue"
              ? { maxHeight: "425px", height: "100vh" }
              : {}
          }
        >
          {modalChildren[modalDescription]}
        </Modal>
      )}
    </div>
  );
}
