import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";

import { useAuthInfo } from "./components/contexts/AuthContext";

import solidIcons from "./util/fontawesome/solidIcons";

import "./styles/app.scss";

solidIcons();

function App() {
  const { isLoggedIn } = useAuthInfo();

  return (
    <div className="App">
      {isLoggedIn !== null &&
        (isLoggedIn ? <PrivateRoutes /> : <PublicRoutes />)}
    </div>
  );
}

export default App;
