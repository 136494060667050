import ExpandCollapseSection from "../../components/core/ExpandCollapseSection";
import { useCurrentPar } from "../../components/contexts/CurrentParContext";

const noteTextAreas = [
  {
    label: "PFS Daily Journal",
    id: "pfs-daily-journal",
    field: "notes",
    placeholder:
      "Please record all important information pertaining to todays activities on your project. Who, What, Where, When, Why, and How. This information will be included in the Daily Construction Report and will be shared with others outside of BHI.",
  },
  {
    label: "Foreman Daily Journal",
    id: "forman-daily-journal",
    field: "workPreformed",
    placeholder:
      "Please record all important information pertaining to your crew's activities today. Who, What, Where, When, Why, and How. This information will be recorded in the PAR database and made available to your leaders.",
  },
  {
    label: "Foreman Forecasted Work",
    id: "forman-forecasted-work",
    field: "workForecasted",
    placeholder:
      "Please record the work that you and your crew are planning to complete in the coming days and weeks. Who, What, Where, When, Why, and How. Include any resources, labor, materials equipment etc, that may be required to complete the forecasted work. Also include any constraints or conflicts that may keep the work from being completed. This information will be recorded in the PAR database and made available to your leaders.",
  },
];

const JournalEntriesSection = () => {
  const { parFormDataState, parDispatch, resetting } = useCurrentPar();

  const handleTextareaChange = (e, field) => {
    parDispatch({
      type: "updateInputField",
      payload: {
        field: field,
        value: e.target.value,
      },
    });
  };

  return (
    <div className="notes-section-wrapper">
      <ExpandCollapseSection
        sectionName={"Journal Entries"}
        checkboxValue={true}
      >
        {noteTextAreas.map((note) => (
          <div key={note.id} className="notes-wrapper">
            <ExpandCollapseSection
              sectionName={`${note.label}`}
              checkboxValue={true}
              nested={true}
            >
              <textarea
                cols="30"
                rows="10"
                placeholder={note.placeholder}
                id={note.id}
                value={parFormDataState[note.field]}
                onChange={(e) => handleTextareaChange(e, note.field)}
                disabled={resetting}
              />
            </ExpandCollapseSection>
          </div>
        ))}
      </ExpandCollapseSection>

      <div className="subcontractor-disclaimer">
        ***The entries in these sections will never be a substitute for
        completing the proper documentation or notifying the correct individuals
        as defined in current BHI GOM policies and procedures regarding incident
        and injury reporting. If you have questions regarding these procedures,
        please contact your supervisor immediately.***
      </div>
    </div>
  );
};

export default JournalEntriesSection;
