import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ConfirmationModal from "../../../components/modals/confirmation-modal/ConfirmationModal";

import { useCurrentPar } from "../../../components/contexts/CurrentParContext";
import { useModalContext } from "../../../components/contexts/ModalContext";

const HrvcItem = (props) => {
  const { hrvc, setSelectedHrvc } = props;
  const { handleOpenModal } = useModalContext();
  const { parDispatch } = useCurrentPar();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleHrvcEdit = () => {
    setSelectedHrvc(hrvc);
    handleOpenModal("hrvc");
  };

  const handleHrvcDelete = () => {
    setSelectedHrvc(hrvc);
    setIsDeleteModalOpen(true);
  };

  const deleteHrvc = () => {
    parDispatch({
      type: "deleteHrvc",
      payload: { counselNumber: hrvc.counselNumber },
    });

    setIsDeleteModalOpen(false);
    setSelectedHrvc({});
  };

  const handleConfirmDelete = () => {
    deleteHrvc();
    setSelectedHrvc({});
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <tr className="table-row">
        <td className="name">{hrvc.employeeName}</td>
        <td className="number">{hrvc.employeeId}</td>
        <td className="behavior">{hrvc.behavior}</td>

        <td className="edit">
          <button onClick={handleHrvcEdit}>Edit</button>
        </td>

        <td className="delete">
          <FontAwesomeIcon icon="fa fa-trash" onClick={handleHrvcDelete} />
        </td>
      </tr>

      <ConfirmationModal
        isModalOpen={isDeleteModalOpen}
        onRequestClose={() => {
          setSelectedHrvc({});
          setIsDeleteModalOpen(false);
        }}
        modalQuestion={`Do you want to DELETE Verbal Counsel for ${hrvc.employeeName} involving ${hrvc.interaction}`}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
};

export default HrvcItem;
