import { useEffect, useState } from "react";
import Cookies from "js-cookie";

import LoginBottom from "../img/login-bottom.svg";
import Background from "../img/background.svg";
import ClosedEye from "../img/closed-eye.svg";
import LoginTop from "../img/login-top.svg";
import OpenEye from "../img/open-eye.svg";
import Logo from "../img/logo.svg";

import { useAuthInfo } from "../components/contexts/AuthContext";

import { errorToast } from "../util/toastNotifications";

const LoginPage = (props) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [from, setFrom] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(true);

  const { handleUpdateUserCredentials } = useAuthInfo();

  useEffect(() => {
    setFrom(props.from || "/overview");
  }, [props.from]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (e.target[0].value === "") {
      errorToast("Please enter an email");
      return;
    }
    if (e.target[1].value === "") {
      errorToast("Please enter a password");
      return;
    }
    setLoading(true);

    fetch("https://xytm-ohfx-fint.n7.xano.io/api:KE7MCNYf/auth/login", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        email: e.target[0].value.toLowerCase(),
        password: e.target[1].value,
      }),
    })
      .then((res) => {
        if (res.ok) {
          setLoading(false);
          return res.json();
        } else if (res.status === 500) {
          errorToast("Invalid Email/Password");
          setLoading(false);

          return null;
        } else if (res.status === 403) {
          errorToast(
            "Your account has been deactivated. Please contact your administrator."
          );
          setLoading(false);

          return null;
        } else if (res.status === 400) {
          errorToast("Invalid Email/Password");
          setLoading(false);

          return null;
        }
      })
      .then((data) => {
        if (data) {
          Cookies.set("bearer_token", data.authToken, { expires: 0.5 });
          localStorage.clear();
          props.history.push(from);
          handleUpdateUserCredentials();
        }
      })
      .catch((err) => setErrorMsg(err));
  };

  return (
    <div
      className="login-page-container"
      style={{ backgroundImage: `url(${Background})` }}
    >
      <div className="svg-wrapper">
        <img src={LoginTop} alt="Top Login Border" className="login-svg" />
      </div>

      <img src={Logo} alt="BHI" className="logo" />

      <div className="login-wrapper">
        <h2>PROJECT ACTIVITY RECORD</h2>
        <div className="error-message">{errorMsg}</div>

        <form onSubmit={handleSubmit} method="POST">
          <div className="input-wrapper">
            <input
              name="username"
              type="text"
              autoComplete="username"
              width="10px"
              placeholder="User Name"
            />
          </div>

          <div className="input-wrapper">
            <input
              name="password"
              maxLength={100}
              type={showPassword ? "password" : "text"}
              autoComplete="current-password"
              placeholder="Password"
            />

            <div className="open-closed-eye">
              <img
                src={showPassword ? OpenEye : ClosedEye}
                alt="open-closed-eye"
                onClick={() => setShowPassword((sp) => !sp)}
              />
            </div>
          </div>

          <button className="login-button" type="submit" disabled={loading}>
            {loading ? "" : "Login"}

            <span
              className={`loading ${loading ? "show-loading-login" : ""}`}
            />
          </button>
        </form>
      </div>

      <div className="svg-wrapper">
        <img
          src={LoginBottom}
          alt="Login Bottotm Corner SVG"
          className="login-svg svg-bottom"
        />
      </div>
    </div>
  );
};

export default LoginPage;
