const TESTING_MODE = false;
const TEST_CONFIG = {
  offsetDays: -4,
  hours: 11,
  minutes: 29,
};

/**
 * Normalize date to start of day for comparison
 * @param {Date} date
 * @returns {Date}
 */
const normalizeDate = (date) => {
  const normalized = new Date(date);
  normalized.setHours(0, 0, 0, 0);
  return normalized;
};

/**
 * Get the current date and time, adjusted for testing if needed
 * @returns {Date}
 */
export const getCurrentDate = () => {
  const today = new Date();

  if (TESTING_MODE) {
    today.setDate(today.getDate() + TEST_CONFIG.offsetDays);

    if (TEST_CONFIG.hours !== undefined) {
      today.setHours(TEST_CONFIG.hours);
    }
    if (TEST_CONFIG.minutes !== undefined) {
      today.setMinutes(TEST_CONFIG.minutes);
    }

    today.setSeconds(0);
  }

  return today;
};

/**
 * Check if it's within Tuesday grace period (before 11:30 AM)
 * @returns {boolean}
 */
const isInTuesdayGracePeriod = (date) => {
  const day = date.getDay();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return day === 2 && (hours < 11 || (hours === 11 && minutes <= 30));
};

/**
 * Gets the available date ranges based on current date
 * @param {Date} date - Current date
 * @returns {{start: Date, end: Date}} Start and end dates of allowed period
 */
export const getPayPeriodDates = (date = getCurrentDate()) => {
  const today = getCurrentDate();
  const currentDay = today.getDay();

  if (currentDay === 1 || isInTuesdayGracePeriod(today)) {
    const lastWeekStart = new Date(today);
    lastWeekStart.setDate(today.getDate() - (currentDay === 1 ? 7 : 8));
    lastWeekStart.setHours(0, 0, 0, 0);

    const lastWeekEnd = new Date(today);
    lastWeekEnd.setDate(today.getDate() - (currentDay === 1 ? 1 : 2));
    lastWeekEnd.setHours(23, 59, 59, 999);

    return {
      start: lastWeekStart,
      end: today,
      isMonday: currentDay === 1,
      isTuesdayGrace: currentDay === 2,
      lastWeekEnd: lastWeekEnd,
    };
  } else {
    const thisWeekStart = new Date(today);
    const diff = currentDay === 0 ? 6 : currentDay - 1;
    thisWeekStart.setDate(today.getDate() - diff);
    thisWeekStart.setHours(0, 0, 0, 0);

    return {
      start: thisWeekStart,
      end: today,
      isMonday: false,
      isTuesdayGrace: false,
    };
  }
};

export const logDateInformation = () => {
  // const today = getCurrentDate();
  //   const period = getPayPeriodDates(today);
  //   const isMonday = today.getDay() === 1;
  //   const inGracePeriod = isInTuesdayGracePeriod(today);
};

/**
 * Format date as YYYY-MM-DD for input value
 * @param {Date} date
 * @returns {string}
 */
export const formatDateForInput = (date) => {
  return date.toISOString().split("T")[0];
};

/**
 * Checks if a date is within the allowed range
 * @param {Date} date - Date to check
 * @returns {boolean}
 */
export const isDateInAllowedPayPeriods = (date) => {
  const current = getPayPeriodDates(getCurrentDate());
  const normalizedDate = normalizeDate(date);
  const normalizedStart = normalizeDate(current.start);
  const normalizedEnd = normalizeDate(current.end);

  return normalizedDate >= normalizedStart && normalizedDate <= normalizedEnd;
};

/**
 * Format date for display with custom format
 * @param {Date} date
 * @returns {string}
 */
export const formatDateForDisplay = (date) => {
  return new Intl.DateTimeFormat("en-US", {
    weekday: "long",
    month: "long",
    day: "numeric",
  }).format(date);
};

export const getDateSelectionMessage = (current) => {
  if (current.isMonday) {
    return (
      `You can select either:\n\n` +
      `• Last week's dates (${formatDateForDisplay(
        current.start
      )} to ${formatDateForDisplay(current.lastWeekEnd)})\n\n` +
      `• Or today (${formatDateForDisplay(current.end)})`
    );
  } else if (current.isTuesdayGrace) {
    const monday = new Date(current.end);
    monday.setDate(monday.getDate() - 1);

    return (
      `• Last week's dates (${formatDateForDisplay(
        current.start
      )} through ${formatDateForDisplay(current.lastWeekEnd)})\n\n` +
      `• Or ${formatDateForDisplay(
        monday
      )} through today (${formatDateForDisplay(current.end)})`
    );
  } else {
    return (
      `Select a date from:\n\n` +
      `${formatDateForDisplay(
        current.start
      )} through today (${formatDateForDisplay(current.end)})`
    );
  }
};
