import LoadingSpinner from "../helpers/LoadingSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const GeneralModalLayout = (props) => {
  const { title, isLoading, renderModalInfo, handleSubmit, setSearchValue } =
    props;

  return (
    <div className="modal-container">
      <div className="content-wrapper">
        <div className="header-container">
          <h1 className="title">{title}</h1>

          <div className="search-wrapper">
            <FontAwesomeIcon className="magnifying-glass" icon="fa fa-search" />

            <input
              type="search"
              name="search"
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        </div>

        <hr />

        <div
          className={`main-content-wrapper ${
            isLoading ? "loading-wrapper" : ""
          }`}
        >
          {isLoading ? <LoadingSpinner /> : renderModalInfo()}
        </div>

        <button onClick={handleSubmit} disabled={isLoading}>
          {isLoading ? "Loading" : "Save"}
        </button>
      </div>
    </div>
  );
};

export default GeneralModalLayout;
