import { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { useAuthInfo } from "../components/contexts/AuthContext";

const NoMatchPage = () => {
  const [countdown, setCountdown] = useState(5);
  const { isLoggedIn } = useAuthInfo();

  useEffect(() => {
    const timer = setInterval(() => setCountdown((prev) => prev - 1), 1000);

    return () => clearInterval(timer);
  }, []);

  if (countdown <= 0)
    return <Redirect to={isLoggedIn ? "/overview" : "/login"} />;

  return (
    <div className="no-match-page-container">
      <h1>Error: 404 Not Found</h1>

      <p>
        We couldn't find that page. You will be redirected in {countdown}{" "}
        seconds.
      </p>

      <p>
        Or, click <Link to={isLoggedIn ? "/overview" : "/login"}>here</Link> to
        redirect now.
      </p>
    </div>
  );
};

export default NoMatchPage;
