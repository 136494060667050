import { useState, useEffect } from "react";

import ConfirmationModal from "../../../components/modals/confirmation-modal/ConfirmationModal";
import ExpandCollapseSection from "../../../components/core/ExpandCollapseSection";
import PrevailingWagesTable from "../../../components/PrevailingWagesTable";
import EquipmentForm from "../../../components/forms/EquipmentForm";
import AddEmployee from "../../../components/forms/AddEmployee";
import ProgressEntryPhaseItem from "./ProgressEntryPhaseItem";
import EquipmentPhaseItem from "./EquipmentPhaseItem";
import Modal from "../../../components/modals/Modal";
import EmployeePhaseItem from "./EmployeePhaseItem";

import useAbortEffect from "../../../components/hooks/useAbortEffect";
import useWindowWidth from "../../../components/hooks/useWindowWidth";

import asyncAPICall from "../../../util/apiWrapper";
import {
  calculateSectionValues,
  calculateTotalPerDiemHours,
} from "../../../components/helpers/calculateHours";
import { useCurrentPar } from "../../../components/contexts/CurrentParContext";
import { ParTable } from "../../../components/helpers/ParFormComponents";
import { errorToast } from "../../../util/toastNotifications";

const PhaseCodeSubSection = (props) => {
  const {
    phaseNum,
    type,
    title,
    totalHours,
    perDiemAmount,
    pwClassesByJob,
    setPwClassesByJob,
  } = props;

  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [isProgressEmpty, setIsProgressEmpty] = useState(false);
  const [currentModalOpen, setCurrentModalOpen] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [deletionData, setDeletionData] = useState({
    attributeName: "",
    selectedRecord: "",
    filterName: "",
  });

  const { windowWidth, setWindowWidth, windowWidthRef } = useWindowWidth();
  const { parFormDataState, parDispatch } = useCurrentPar();
  const { phaseCodes, jobCertified, jobNumber } = parFormDataState;

  const alphabetizedEmployees = [...phaseCodes[phaseNum].laborData].sort(
    (firstEmployee, secondEmployee) =>
      firstEmployee.name > secondEmployee.name ? 1 : -1
  );

  const isPrevailingWage = !(
    jobCertified !== "Y" || phaseNum === "012130-00-00"
  );

  const isProgressEntryDisabled = () =>
    isLoading ||
    phaseCodes[phaseNum].progressEntry.length > 0 ||
    isProgressEmpty;

  const addButtonContent = {
    labor: {
      text: "Add Employee",
      props: {
        onClick: () => setCurrentModalOpen("addEmployee"),
      },
    },
    equipment: {
      text: "Add Equipment",
      props: {
        onClick: () => setCurrentModalOpen("addEquipment"),
      },
    },
    progressEntry: {
      text: "Add Progress Entry",
      props: {
        disabled: isProgressEntryDisabled(),
        onClick: () => {
          addProgressEntry();
          setIsLoading(true);
        },
      },
    },
  };

  const modalChildren = {
    addEmployee: (
      <AddEmployee
        phaseNum={phaseNum}
        perDiemAmount={perDiemAmount}
        closeModal={() => setCurrentModalOpen("")}
      />
    ),
    addEquipment: (
      <EquipmentForm
        phaseNum={phaseNum}
        hoursWorked="0"
        closeModal={() => setCurrentModalOpen("")}
      />
    ),
  };

  const confirmationModalProps = {
    removeEmployee: {
      modalQuestion: `Are you sure you want to remove: ${
        deletionData.selectedRecord.length > 0
          ? deletionData.selectedRecord
          : `"untitled" `
      }?`,
    },
    removeEquipment: {
      modalQuestion: `Are you sure you want to remove: \n
        ${
          deletionData.selectedRecord.length > 0
            ? deletionData.selectedRecord
            : `"untitled" `
        }?`,
    },
    removeProgressEntry: {
      modalQuestion: `Are you sure you want to remove: ID #${
        deletionData.selectedRecord > 0
          ? deletionData.selectedRecord
          : `"untitled" `
      }?`,
    },
  };

  const handleModal = (deletionData, modalName) => {
    setDeletionData(deletionData);
    setCurrentModalOpen(modalName);
  };

  const handleResetPerDiem = (
    perDiemAmount,
    attributeName,
    fieldName,
    rowId,
    filterName
  ) => {
    parDispatch({
      type: "updatePhaseCodeField",
      payload: {
        phaseCode: phaseNum,
        attributeName,
        fieldName,
        updatedValue: perDiemAmount,
        rowId,
        filterName,
      },
    });
    errorToast(
      "Error Per Diem Amount, cannot go above the per diem job limit."
    );
  };

  const deleteRow = () => {
    const { attributeName, selectedRecord, filterName } = deletionData;

    parDispatch({
      type: "removeRecord",
      payload: {
        phaseCode: phaseNum,
        attributeName,
        selectedRecord,
        filterName,
      },
    });

    let otherRecordsFound = false;

    Object.keys(phaseCodes).forEach((phaseCode) => {
      if (phaseCode !== "012130-00-00" && phaseCode !== phaseNum) {
        phaseCodes[phaseCode].laborData.forEach((employee) => {
          if (employee.name === selectedRecord) {
            otherRecordsFound = true;
            return;
          }
        });
      }
    });

    if (otherRecordsFound === false) {
      parDispatch({
        type: "removeRecord",
        payload: {
          phaseCode: "012130-00-00",
          attributeName,
          selectedRecord,
          filterName,
        },
      });
    }
  };

  const addProgressEntry = () => {
    const getUomData = new Promise((resolve, reject) => {
      let uomData;
      asyncAPICall(
        `api:W2oHkD04/vtbluombyJobAndPhase?job=${jobNumber}&phase=${phaseNum}`,
        "GET",
        null,
        null,
        (data) => {
          if (data && data.length > 0) {
            uomData = data;
            resolve(uomData);
          } else {
            setIsProgressEmpty(true);
            errorToast(
              `Job ${jobNumber} With Phase ${phaseNum} Does Not Have a Progress Entry`
            );
            setIsLoading(false);
          }
        },
        (err) => {
          console.log("Getting UOM Data Error", err);
          setIsLoading(false);
          reject(err);
        }
      );
    });

    getUomData.then((uomData) => {
      const lastIndex = phaseCodes[phaseNum].progressEntry.slice(-1);
      const lastId = lastIndex[0];
      const id = lastId?.id + 1;
      const newId = phaseCodes[phaseNum].progressEntry.length > 0 ? id : 1;

      parDispatch({
        type: "addRecord",
        payload: {
          phaseCode: phaseNum,
          attributeName: "progressEntry",
          newRecords: [
            {
              id: newId,
              job: uomData[0].job,
              phase: uomData[0].phase,
              description: uomData[0].description,
              currentEstimatedUnits: uomData[0].currentEstimatedUnits,
              uom: uomData[0].uom,
              jtdProgress: uomData[0].jtdProgress,
              dcrProgress: "0",
              costType: uomData[0].costType,
            },
          ],
        },
      });
      setIsLoading(false);
    });
  };

  useEffect(
    () => setWindowWidth(() => windowWidthRef.current),
    [windowWidthRef, setWindowWidth]
  );

  useEffect(() => setIsMobileScreen(windowWidth < 900), [windowWidth]);

  useAbortEffect((signal) => {
    if (type === "labor" && isPrevailingWage && !pwClassesByJob.length) {
      asyncAPICall(
        `api:E3-qrfOe/vprevailingwageclassByJob?job=${jobNumber}`,
        "GET",
        null,
        null,
        (data) => {
          setIsLoading(false);
          setPwClassesByJob(() => {
            const workingPwClasses = [
              { Class: "default", Description: "Non Prevailing Wage", id: 0 },
            ];
            workingPwClasses.push(...data);
            return workingPwClasses;
          });
        },
        (err) => {
          if (!signal.aborted) {
            setIsLoading(false);
            console.error(err);
          }
        },
        signal
      );
    }
  }, []);

  const phaseCodeTables = {
    labor: isPrevailingWage ? (
      <PrevailingWagesTable
        phaseNum={phaseNum}
        isLoading={isLoading}
        pwClassesByJob={pwClassesByJob}
        editInfo={true}
        handleModal={handleModal}
        isMobileScreen={isMobileScreen}
      />
    ) : phaseCodes[phaseNum].laborData.length ? (
      <ParTable
        tableClass={`labor-table ${isMobileScreen ? "mobile-view" : ""}`}
        tableHeaders={[
          { name: "Employee Name", class: "padding-20" },
          { name: "Employee #" },
          { name: "Hours Worked" },
          phaseNum === "012130-00-00" && {
            name: "Per Diem Y/N",
          },
          phaseNum === "012130-00-00" && {
            name: "Per Diem Rate",
          },
          { name: "Labor Note" },
          {},
        ]}
        tableRows={alphabetizedEmployees.map((employee) => {
          return (
            <EmployeePhaseItem
              key={employee.id}
              employee={employee}
              phaseNum={phaseNum}
              handleModal={handleModal}
              perDiemAmount={perDiemAmount}
              handleResetPerDiem={handleResetPerDiem}
              isMobileScreen={isMobileScreen}
            />
          );
        })}
      />
    ) : (
      ""
    ),
    equipment: phaseCodes[phaseNum].equipmentData.length ? (
      <ParTable
        tableClass={`equipment-table ${isMobileScreen ? "mobile-view" : ""}`}
        tableHeaders={[
          { name: "Equipment #", class: "padding-20" },
          { name: "Operated Hours (Not Billable)" },
          { name: "Equipment Note" },
          {},
        ]}
        tableRows={phaseCodes[phaseNum].equipmentData.map((equipment) => {
          return (
            <EquipmentPhaseItem
              key={equipment.equipNum}
              phaseNum={phaseNum}
              equipment={equipment}
              handleModal={handleModal}
              isMobileScreen={isMobileScreen}
            />
          );
        })}
      />
    ) : (
      ""
    ),
    progressEntry: phaseCodes[phaseNum].progressEntry.length ? (
      <ParTable
        tableClass={`progress-entry-table ${
          isMobileScreen ? "mobile-view" : ""
        }`}
        phaseNum={phaseNum}
        tableHeaders={[
          { name: "ID", class: "padding-20" },
          { name: "Phase" },
          { name: "Description" },
          { name: "Current Estimated Units" },
          { name: "UOM" },
          { name: "JTD Progress" },
          { name: "PAR Daily Progress" },
          {},
        ]}
        tableRows={phaseCodes[phaseNum].progressEntry.map((progressEntry) => {
          return (
            <ProgressEntryPhaseItem
              key={progressEntry.id}
              phaseNum={phaseNum}
              progressEntry={progressEntry}
              handleModal={handleModal}
              isMobileScreen={isMobileScreen}
            />
          );
        })}
      />
    ) : (
      ""
    ),
  };

  return (
    <div className="phase-code-sub-section-container">
      <ExpandCollapseSection sectionName={title} checkboxValue={true}>
        <div className="table-container">
          {phaseCodeTables[type]}

          {phaseCodes[phaseNum]?.[totalHours?.records]?.length > 0 && (
            <h3 className="par-phase-total">
              Total Employee Hours:{" "}
              {phaseNum === "012130-00-00"
                ? calculateTotalPerDiemHours(
                    phaseCodes[phaseNum][totalHours.records],
                    parFormDataState
                  )
                : calculateSectionValues(
                    phaseCodes[phaseNum][totalHours.records],
                    totalHours.filterProperty
                  )}
            </h3>
          )}

          <button className="add-button" {...addButtonContent[type].props}>
            {addButtonContent[type].text}
          </button>
        </div>
      </ExpandCollapseSection>

      {Object.keys(modalChildren).includes(currentModalOpen) && (
        <Modal
          isModalOpen={!!currentModalOpen}
          onRequestClose={() => setCurrentModalOpen("")}
        >
          {modalChildren[currentModalOpen]}
        </Modal>
      )}

      {Object.keys(confirmationModalProps).includes(currentModalOpen) && (
        <ConfirmationModal
          isModalOpen={!!currentModalOpen}
          onRequestClose={() => setCurrentModalOpen("")}
          onConfirm={() => {
            deleteRow();
            setCurrentModalOpen("");
          }}
          {...confirmationModalProps[currentModalOpen]}
        />
      )}
    </div>
  );
};

export default PhaseCodeSubSection;
