import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useModalContext } from "../../../components/contexts/ModalContext";

const IssuesSectionItem = (props) => {
  const {
    setSelectedIssue,
    description,
    issueType,
    dateRaised,
    issueNumber,
    setEditIssue,
  } = props;

  const { handleOpenModal } = useModalContext();

  const handleEditIssue = () => {
    setEditIssue({
      issueNumber,
      description,
      issueType,
      dateRaised,
    });

    handleOpenModal("addIssue");
  };

  const handleDeleteIssue = () => {
    handleOpenModal("removeIssue");
    setSelectedIssue({
      issueNumber,
      description,
      issueType,
      dateRaised,
    });
  };

  return (
    <tr className="table-row">
      <td className="issue-type">{issueType}</td>

      <td className="issue-date">{dateRaised}</td>

      <td className="issue-description">{description}</td>

      <td className="issue-edit">
        <button onClick={handleEditIssue}>Edit</button>{" "}
      </td>

      <td className="issue-delete">
        <FontAwesomeIcon icon="fas fa-trash" onClick={handleDeleteIssue} />
      </td>
    </tr>
  );
};

export default IssuesSectionItem;
