import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAuthInfo } from "../../components/contexts/AuthContext";

import useAbortEffect from "../../components/hooks/useAbortEffect";

import asyncAPICall from "../../util/apiWrapper";

const SafetyContact = () => {
  const [safetyContact, setSafetyContact] = useState({
    name: "Retrieving...",
    phone: "###-###-####",
  });
  const { user } = useAuthInfo();

  useAbortEffect(
    (signal) => {
      asyncAPICall(
        `api:KE7MCNYf/vprojectsafetywithid_byProject?project=${user.lastJob}`,
        "GET",
        null,
        null,
        (data) => {
          if (data.length > 0) {
            setSafetyContact({
              name: data[0].safetyName,
              phone: data[0].workPhone,
              email: data[0].safetyEmail,
            });
          } else {
            setSafetyContact({
              name: "No Contact",
              phone: "",
            });
          }
        },
        (err) => {
          if (!signal.aborted) {
            console.error("Employee table data get error", err);
          }
        },
        signal
      );
    },
    [user.lastJob]
  );

  return (
    <div className="notifications-container">
      <h1 className="safety">SAFETY CONTACT</h1>

      <div className="safety-scroll">
        <div className="par-box purple-gradient">
          <FontAwesomeIcon icon="fas fa-phone" />

          <h3 className="safety-name">{safetyContact.name}</h3>

          <h3 className="safety-phone">{safetyContact.phone}</h3>

          <h3>{safetyContact.email}</h3>
        </div>
      </div>
    </div>
  );
};

export default SafetyContact;
