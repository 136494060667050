import { useModalContext } from "./contexts/ModalContext";
import { ParInput } from "./helpers/ParFormComponents";

const SelectEmployee = (props) => {
  const { handleOpenModal } = useModalContext();
  const { label, value, setIsSupervisor } = props;

  const handleSetEmployee = () => {
    label === "Supervisor" ? setIsSupervisor(true) : setIsSupervisor(false);
    handleOpenModal("hrvcEmployee");
  };

  return (
    <div className="select-employee-wrapper">
      <ParInput
        type="text"
        label={label}
        customClass="hrvc-employee-name"
        value={value}
        readOnly={true}
        placeholder="Select Employee"
      />

      <button className="left-side-btn" onClick={handleSetEmployee}>
        Search
      </button>
    </div>
  );
};

export default SelectEmployee;
