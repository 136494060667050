export const handleModalOpeningReducer = (modalState, action) => {
  const { type, modalName } = action;

  switch (type) {
    case "openModal":
      return { ...modalState, [modalName]: true };

    case "closeModal":
      return { ...modalState, [modalName]: false };

    default:
      throw new Error(`Action Type ${type} is not valid for this reducer.`);
  }
};
