import JobGroupList from "./JobGroupList";

const SearchResults = ({
  searchedStartDate,
  searchedEndDate,
  searchedJob,
  searchedPAR,
  setSearchedStartDate,
  setSearchedEndDate,
  setSearchedJob,
  todayFormattedForInput,
  gradientClass,
  checkIconColor,
  icon,
}) => {
  return (
    <>
      <div className="search-inputs-wrapper">
        <div className="date-container inputs">
          <label htmlFor="startDate">Start Date</label>

          <input
            id="startDate"
            className="date-input"
            type="date"
            max={todayFormattedForInput()}
            value={searchedStartDate}
            onChange={(e) => {
              setSearchedStartDate(e.target.value);
            }}
          />
        </div>
        <div className="date-container inputs">
          <label htmlFor="endDate">End Date</label>
          <input
            id="endDate"
            className="date-input"
            type="date"
            min={searchedStartDate}
            max={todayFormattedForInput()}
            value={searchedEndDate}
            onChange={(e) => {
              setSearchedEndDate(e.target.value);
            }}
          />
        </div>
        <div className="job-number-container inputs">
          <label htmlFor="dcrNumber">Job Number</label>
          <input
            id="dcrNumber"
            type="search"
            placeholder="Search Job"
            value={searchedJob}
            onChange={(e) => {
              setSearchedJob(e.target.value);
            }}
            maxLength={12}
          />
        </div>
      </div>
      <JobGroupList
        pars={searchedPAR}
        showDelete={false}
        linkText="View Report"
        title="SEARCH RESULTS"
        searchParams={{
          searchedStartDate,
          searchedEndDate,
          searchedJob,
        }}
        gradientClass="green-gradient"
        checkIconColor="#4caf50"
        icon="fa fa-check-circle"
      />
    </>
  );
};

export default SearchResults;
