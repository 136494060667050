import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";

import SelectEmployee from "../../components/SelectEmployee";
import AddHrvcEmployee from "./AddHrvcEmployee";
import Modal from "../modals/Modal";

import { useCurrentPar } from "../contexts/CurrentParContext";
import { useModalContext } from "../contexts/ModalContext";
import { useAuthInfo } from "../contexts/AuthContext";

import { ParInput, ParSelect } from "../helpers/ParFormComponents";

import LgYellowDesign from "../../img/lg-yellow-design.svg";
import { todayFormattedForInput } from "../../util/dayString";

const VerbalCounselForm = (props) => {
  const { selectedHrvc, setSelectedHrvc } = props;

  const { handleCloseModal, handleOpenModal, isModalOpen } = useModalContext();
  const { parFormDataState, parDispatch } = useCurrentPar();
  const { user } = useAuthInfo();

  const [employeeInfo, setEmployeeInfo] = useState([]);
  const [supervisorInfo, setSupervisorInfo] = useState([]);
  const [isSupervisor, setIsSupervisor] = useState(false);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [hrvc, setHrvc] = useState(
    Object.keys(selectedHrvc).length
      ? selectedHrvc
      : {
          counselNumber: uuid(),
          submissionDate: "",
          interaction: "",
          behavior: "",
          counsel: "",
          response: "",
          submitterId: parFormDataState.foreman,
          submitterName: user.name,
          employeeId: "",
          employeeName: "",
          supervisorId: "",
          supervisorName: "",
          active: true,
        }
  );

  const availableTypes = [
    { type: "Attendance" },
    { type: "Professionalism" },
    { type: "Safety" },
    { type: "Performance" },
  ];

  const addHrvc = () => {
    parDispatch({
      type: "addHrvc",
      payload: hrvc,
    });
  };

  const editHrvc = () => {
    parDispatch({
      type: "editHrvc",
      payload: hrvc,
    });
  };

  const handleHrvcChange = (e) => {
    const { name, value } = e.target;
    setHrvc({
      ...hrvc,
      [name]: value,
    });
  };

  const handleSave = () => {
    if (Object.keys(selectedHrvc).length) {
      editHrvc();
    } else {
      addHrvc();
      handleOpenModal("addHrvc");
    }

    setSelectedHrvc({});
    handleCloseModal("hrvc");
  };

  useEffect(() => {
    if (
      hrvc.employeeId &&
      hrvc.interaction?.length &&
      hrvc.response?.length &&
      hrvc.counsel?.length &&
      hrvc.behavior?.length &&
      hrvc.supervisorId &&
      hrvc.submissionDate
    ) {
      setIsFormComplete(true);
    } else {
      setIsFormComplete(false);
    }
  }, [hrvc, setIsFormComplete]);

  useEffect(() => {
    if (employeeInfo.length) {
      setHrvc((c) => {
        return {
          ...c,
          employeeName: employeeInfo[0].name,
          employeeId: employeeInfo[0].id,
        };
      });
    }
  }, [employeeInfo]);

  useEffect(() => {
    if (supervisorInfo.length) {
      setHrvc((c) => {
        return {
          ...c,
          supervisorName: supervisorInfo[0].name,
          supervisorId: supervisorInfo[0].id,
        };
      });
    }
  }, [supervisorInfo]);

  return (
    <div className="hrvc-form-container">
      <div className="header-bar">
        <div className="header-wrapper">
          <div className="header-title">Verbal Counsel</div>
        </div>
        <img
          className="header-graphic"
          src={LgYellowDesign}
          alt="header graphic"
        />
      </div>

      <button className="close-button" onClick={() => handleCloseModal("hrvc")}>
        Close
      </button>

      <div className="information-wrapper">
        <div className="left-side-container">
          <div className="left-side-header">Done under process of counsel.</div>

          <ParInput
            type="date"
            label="Submission Date"
            customClass="hrvc-date"
            value={hrvc.submissionDate}
            max={todayFormattedForInput()}
            onChange={(e) =>
              setHrvc((prev) => {
                return { ...prev, submissionDate: e.target.value };
              })
            }
          />

          <SelectEmployee
            label={"Employee"}
            value={hrvc.employeeName}
            setIsSupervisor={setIsSupervisor}
          />

          <SelectEmployee
            label={"Supervisor"}
            value={hrvc.supervisorName}
            setIsSupervisor={setIsSupervisor}
          />

          <ParInput
            customClass="hrvc-date"
            type="text"
            label="Submitter Name"
            value={user.name}
            disabled={true}
          />

          <div className="gom-paragraph">
            <div>
              This form is used to document
              <br />
              verbal warnings or negative
              <br />
              verbal counseling given to
              <br />
              an employee regarding a lesser behavioral problem in accordance
              with{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  "https://drive.google.com/file/d/1h60Bo-0_u6hemzf5ZbpXwUTJNXJ0qLFN/view"
                }
              >
                GOM 100-8
              </a>
              . <br />
              <div className="gom-information">Stick with the facts only.</div>
              <div>
                <strong>Avoid:</strong> <br />
                Assumptions <br />
                Speculations <br />
                Judgements <br />
              </div>
            </div>
          </div>
        </div>

        <div className="right-side-container">
          <div className="right-side-header">
            Please answer the following with as much detail as possible.
          </div>

          <div className="hrvc-wrapper">
            <div className="hrvc-header">Behavior Type</div>
            <ParSelect
              customClass="behavior"
              idKey="type"
              options={availableTypes}
              value={hrvc.behavior}
              displayKeys={["type"]}
              onChange={(e) =>
                setHrvc((prev) => {
                  return { ...prev, behavior: e.target.value };
                })
              }
            />
          </div>

          <div className="hrvc-wrapper">
            <div className="hrvc-header">Interaction / Incedent</div>
            <textarea
              type="text"
              value={hrvc.interaction}
              name="interaction"
              onChange={handleHrvcChange}
              placeholder="State the facts. Who? What? When? Where? Why?"
              rows={5}
            />
          </div>

          <div className="hrvc-wrapper">
            <div className="hrvc-header">Counsel Given</div>
            <textarea
              type="text"
              value={hrvc.counsel}
              name="counsel"
              onChange={handleHrvcChange}
              placeholder="What council did you provide?"
              rows={5}
            />
          </div>

          <div className="hrvc-wrapper">
            <div className="hrvc-header">Employee Response</div>
            <textarea
              type="text"
              value={hrvc.response}
              name="response"
              onChange={handleHrvcChange}
              placeholder="State the actual employee words, actions and reaction."
              rows={5}
            />
          </div>
        </div>
      </div>

      <div className="save-btn-wrapper">
        <button disabled={!isFormComplete} onClick={handleSave}>
          Save
        </button>
      </div>

      <Modal
        isModalOpen={isModalOpen.hrvcEmployee}
        onRequestClose={() => handleCloseModal("hrvcEmployee")}
      >
        <AddHrvcEmployee
          setEmployeeInfo={setEmployeeInfo}
          setSupervisorInfo={setSupervisorInfo}
          closeModal={() => handleCloseModal("hrvcEmployee")}
          isSupervisor={isSupervisor}
        />
      </Modal>
    </div>
  );
};

export default VerbalCounselForm;
