import Modal from "../Modal";

import ConfirmationControls from "./ConfirmationControls";

const ConfirmationModal = (props) => {
  const {
    isModalOpen,
    onRequestClose,
    modalQuestion,
    onConfirm,
    yesButtonText = "Yes",
    noButtonText = "No",
  } = props;

  return (
    <Modal
      isModalOpen={isModalOpen}
      onRequestClose={onRequestClose}
      content={{
        top: "50%",
        width: "300px",
        height: "215px",
        border: "2px solid black",
        textAlign: "center",
        background: "rgb(234, 233, 233)",
      }}
      onConfirm={onConfirm}
    >
      <ConfirmationControls
        modalQuestion={modalQuestion}
        onConfirm={onConfirm}
        onRequestClose={onRequestClose}
        yesButtonText={yesButtonText}
        noButtonText={noButtonText}
      />
    </Modal>
  );
};

export default ConfirmationModal;
