import { renderTableData } from "../../components/helpers/renderTableData";

const ReportTable = ({
  wrapperClass = "",
  parData,
  windowWidth,
  tableHeader,
  tableData,
  children,
}) => {
  const renderMobileRows = () => {
    return (
      <tbody>
        {tableData.empty.isEmpty ? (
          <tr>
            <th className="vertical-header">
              <span>{tableHeader}</span>
            </th>
            <td className="first-column">{tableData.empty.message}</td>
          </tr>
        ) : (
          Object.keys(tableData)
            .filter((key) => key !== "empty")
            .map((key, idx) => {
              return (
                <tr key={idx}>
                  <th className="vertical-header">
                    <span>{key}</span>
                  </th>
                  {renderTableData(
                    parData,
                    idx,
                    (tableType) => tableType[[tableData[[key]]]]
                  )}
                </tr>
              );
            })
        )}
      </tbody>
    );
  };

  const renderDesktopHeaders = () => {
    if (tableData.empty.isEmpty) {
      return (
        <tr>
          <th>{tableHeader}</th>
        </tr>
      );
    } else {
      return (
        <tr>
          {Object.keys(tableData)
            .filter((key) => key !== "empty")
            .map((key, idx) => {
              return <th key={idx}>{key}</th>;
            })}
        </tr>
      );
    }
  };

  const renderDesktopRows = () => {
    if (tableData.empty.isEmpty) {
      return (
        <tr>
          <td>{tableData.empty.message}</td>
        </tr>
      );
    } else {
      const tableValues = Object.keys(tableData)
        .filter((key) => key !== "empty")
        .map((column) => tableData[[column]]);

      return parData.map((row, rowIdx) => {
        return (
          <tr key={rowIdx}>
            {tableValues.map((key, idx) => {
              return <td key={idx}>{row[[key]]}</td>;
            })}
          </tr>
        );
      });
    }
  };

  if (tableHeader === "Subcontractors" && parData.length < 1)
    return <>{children}--</>;

  return (
    <div className={`report-table-wrapper ${wrapperClass}`}>
      {children}
      <div className="table-wrapper">
        {windowWidth <= 650 ? (
          <table>{renderMobileRows()}</table>
        ) : (
          <table>
            <thead>{renderDesktopHeaders()}</thead>

            <tbody>{renderDesktopRows()}</tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default ReportTable;
