import { useEffect } from "react";
import ReactModal from "react-modal";

ReactModal.setAppElement("#root");

const Modal = (props) => {
  const { content, overlay, isModalOpen, onRequestClose, children } = props;

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = "unset";
      };
    }
  }, [isModalOpen]);

  return (
    <ReactModal
      isOpen={isModalOpen}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          backdropFilter: "blur(2px)",
          backgroundColor: "rgb(49, 53, 62, 0.5)",
          zIndex: 5,
          ...overlay,
        },
        content: {
          background: "#d0d0d0",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80vw",
          maxWidth: "760px",
          height: "80vh",
          maxHeight: "600px",
          borderRadius: "15px",
          padding: 0,
          overflow: "none",
          zIndex: 10,
          ...content,
        },
      }}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;
