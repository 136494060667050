import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import VerificationPhasecode from "./VerificationPhasecode";
import HorizontalRule from "../../components/core/HorizontalRule";

const ReportPhaseTables = (props) => {
  const { parData, windowWidth, checkboxValues, handleCheckmarks } = props;

  const { pathname } = useLocation();

  const split_path = pathname.split("/");
  const isSelectPrint = split_path[1] === "select-print";
  const view = isSelectPrint ? "print" : split_path[2];

  const movePerDiem = useMemo(
    () =>
      Object.keys(parData.phaseCodes).filter(
        (phaseCode) => phaseCode !== "012130-00-00"
      ),
    [parData.phaseCodes]
  );

  useEffect(() => {
    const checkboxList = [];

    const perdiemExists = Object.keys(parData.phaseCodes).some(
      (phaseCode) => phaseCode === "012130-00-00"
    );

    if (perdiemExists) movePerDiem.push("012130-00-00");

    for (let index in movePerDiem) {
      if (movePerDiem[index] === "012130-00-00") {
        checkboxList.push({
          index: Number(index),
          phase: !!isSelectPrint,
        });
      } else {
        checkboxList.push({
          index: Number(index),
          phase: !!isSelectPrint,
        });
      }
    }

    handleCheckmarks("combined", checkboxList);
  }, [parData, isSelectPrint, handleCheckmarks, movePerDiem]);

  const renderPhaseCodes = () => {
    return movePerDiem.map((phaseNum, index) => {
      return (
        <div
          className={
            isSelectPrint
              ? `${checkboxValues.combined[index]?.phase ? "" : "hide-print"}`
              : ""
          }
          key={phaseNum}
        >
          <VerificationPhasecode
            key={phaseNum}
            index={index}
            phaseNum={phaseNum}
            parData={parData}
            view={view}
            windowWidth={windowWidth}
            checkboxValues={checkboxValues}
            handleCheckmarks={handleCheckmarks}
          />

          {isSelectPrint ? (
            <HorizontalRule
              wrapperClass="verification-hr-wrapper"
              ruleClass={`verification-hr ${
                checkboxValues.combined[index]?.phase ? "" : "hide-print"
              }`}
            />
          ) : index !== movePerDiem.length - 1 ? (
            <HorizontalRule
              wrapperClass="verification-hr-wrapper"
              ruleClass="verification-hr"
            />
          ) : null}
        </div>
      );
    });
  };

  return (
    <div className="report-phase-tables-wrapper">{renderPhaseCodes()}</div>
  );
};

export default ReportPhaseTables;
