import { useLocation } from "react-router-dom";

import useAbortEffect from "../../components/hooks/useAbortEffect";
import Truncate from "../../components/helpers/Truncate";
import asyncAPICall from "../../util/apiWrapper";

const ReportDetails = (props) => {
  const {
    parData,
    handlePageData,
    pageData,
    wrapperClass = "",
    children,
  } = props;

  const { pathname } = useLocation();

  const isSelectPrint = pathname.split("/")[1] === "select-print";

  useAbortEffect(
    (signal) => {
      if (parData.jobNumber) {
        const getForemanPromise = new Promise((resolve, reject) => {
          if (parData.foreman !== 1111) {
            asyncAPICall(
              `api:KE7MCNYf/vEmployeesNoSpacesIdByID?employeeID=${parData.foreman}`,
              "GET",
              null,
              null,
              (data) => {
                if (data) {
                  const fullName = `${data[0].FirstName} ${data[0].MidName} ${data[0].LastName}`;
                  handlePageData("foreman", fullName);
                  resolve(true);
                }
              },
              (err) => {
                if (!signal.aborted) {
                  reject("error getting Foreman", err);
                }
              },

              signal
            );
          }
        });

        const getPfsPromise = new Promise((resolve, reject) => {
          if (parData.foreman !== 1111 || isSelectPrint) {
            asyncAPICall(
              `api:KE7MCNYf/vEmployeesNoSpacesIdByID?employeeID=${parData.pfs}`,
              "GET",
              null,
              null,
              (data) => {
                if (data?.length > 0) {
                  const fullName = `${data[0].FirstName} ${data[0].MidName} ${data[0].LastName}`;
                  handlePageData("pfs", fullName);
                  if (!isSelectPrint)
                    handlePageData("approverEmail", data[0].Email);
                  resolve(true);
                }
              },
              (err) => {
                if (!signal.aborted) {
                  reject("error getting PFS", err);
                }
              },
              signal
            );
          }
        });

        const getParJobPromise = new Promise((resolve, reject) => {
          if (parData.foreman !== 1111 || isSelectPrint) {
            asyncAPICall(
              `api:KE7MCNYf/vprojectswithid_by_Project?project=${parData.jobNumber}`,
              "GET",
              null,
              null,
              (data) => {
                if (
                  (data && !isSelectPrint) ||
                  (data.length && isSelectPrint)
                ) {
                  const jobNameDescription = `${data[0].Project} ${data[0].Project_Name}`;
                  handlePageData("jobNumDesc", jobNameDescription);
                  resolve(true);
                }
              },
              (err) => {
                if (!signal.aborted) {
                  reject("Getting Jobs Error", err);
                }
              }
            );
          }
        });

        Promise.all([getForemanPromise, getPfsPromise, getParJobPromise]).catch(
          console.error
        );
      }
    },
    [parData]
  );

  useAbortEffect(
    (signal) => {
      if (
        parData !== undefined &&
        parData.foreman !== 1111 &&
        parData.foreman &&
        !isSelectPrint
      ) {
        asyncAPICall(
          `api:KE7MCNYf/vEmployeesNoSpacesIdByID?employeeID=${parData.foreman}`,
          "GET",
          null,
          null,
          (data) => {
            if (data) {
              handlePageData("submitterEmail", data[0].Email);
            }
          },
          (err) => {
            if (!signal.aborted) {
              console.error("Getting Submitter Email Error", err);
            }
          },
          signal
        );
      }
    },
    [parData]
  );

  return (
    <div className={`report-details-wrapper ${wrapperClass}`}>
      {children}

      <div className="section-content">
        <div className="upper-content">
          <div className="section-grid">
            <div className="grid-row">
              <div className="data-label">Date: </div>
              <div className="data">
                {parData.date.length > 0 ? parData.date : "--"}
              </div>
            </div>

            <div className="grid-row">
              <div className="data-label">Temp High: </div>
              <div className="data">
                {parData.temperatureHigh.length > 0
                  ? `${parData.temperatureHigh}\xB0`
                  : "--"}
              </div>
            </div>

            <div className="grid-row">
              <div className="data-label">Temp Low: </div>
              <div className="data">
                {parData.temperatureLow.length > 0
                  ? `${parData.temperatureLow}\xB0`
                  : "--"}
              </div>
            </div>

            <div className="grid-row">
              <div className="data-label">Precipitation: </div>
              <div
                className={`data ${isSelectPrint ? "precipitation-data" : ""}`}
              >
                {parData.precipitation.length > 0
                  ? parData.precipitation
                  : "--"}
              </div>
            </div>

            <div className="grid-row">
              <div className="data-label">Wind: </div>
              <div className={`data ${isSelectPrint ? "wind-data" : ""}`}>
                {parData.wind.length > 0 ? parData.wind : "--"}
              </div>
            </div>
          </div>

          <div className="section-grid">
            <div className="grid-row">
              <div className="data-label">Start Time: </div>
              <div className="data">
                {parData?.startTime.length > 0 ? parData.startTime : "--"}
              </div>
            </div>

            <div className="grid-row">
              <div className="data-label">Stop Time: </div>
              <div className="data">
                {parData?.stopTime.length > 0 ? parData.stopTime : "--"}
              </div>
            </div>

            <div className="grid-row">
              <div className="data-label">Approver: </div>
              <div className="data">
                {pageData.pfs !== "" ? pageData.pfs : "--"}
              </div>
            </div>

            <div className="grid-row">
              <div className="data-label">Submitter: </div>
              <div className="data">
                {pageData.foreman !== "" ? pageData.foreman : "--"}
              </div>
            </div>

            <div className="grid-row">
              <div className="data-label">Safety Topic: </div>
              <div className="data">
                <Truncate
                  content={
                    parData.safetyTopic.length > 0 ? parData.safetyTopic : "--"
                  }
                  characters={30}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="lower-content">
          <div className="section-grid">
            <div className="grid-row">
              <div className="data-label">Job Number: </div>
              <div className="data">
                {parData.jobNumber.length > 0
                  ? pageData.jobNumDesc.length > 0
                    ? pageData.jobNumDesc
                    : parData.jobNumber
                  : "--"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportDetails;
