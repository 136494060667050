import ExpandCollapseSection from "../../../components/core/ExpandCollapseSection";
import {
  ParInput,
  ParTable,
} from "../../../components/helpers/ParFormComponents";

import useAbortEffect from "../../../components/hooks/useAbortEffect";

import asyncAPICall from "../../../util/apiWrapper";
import { useCurrentPar } from "../../../components/contexts/CurrentParContext";

const TimeMaterials = () => {
  const { parFormDataState, parDispatch, resetting } = useCurrentPar();

  const {
    jobNumber,
    checkboxes,
    matCustomer,
    matCustomerCode,
    matOsr,
    matJobsite,
  } = parFormDataState;

  const handleInputChange = (e, field) => {
    parDispatch({
      type: "updateInputField",
      payload: {
        field: field,
        value: e.target.value,
      },
    });
  };

  useAbortEffect(
    (signal) => {
      if (jobNumber) {
        asyncAPICall(
          `api:W2oHkD04/vcustomersbyJob?job=${jobNumber}`,
          "GET",
          null,
          null,
          (data) => {
            if (data) {
              parDispatch({
                type: "updateInputField",
                payload: {
                  field: "matCustomer",
                  value: `${data[0]?.name} ${data[0]?.customer}`,
                },
              });
            }
          },
          (err) => {
            if (!signal.aborted) {
              console.error("Getting Time and Material Customer Error", err);
            }
          },
          signal
        );
      }
    },
    [jobNumber, checkboxes.timeMaterials]
  );

  return (
    <ExpandCollapseSection
      sectionName={"Time and Materials"}
      checkboxValue={checkboxes.timeMaterials}
    >
      <ParTable
        tableClass="time-materials-table"
        tableHeaders={[
          { class: "issue-header name", name: "Customer" },
          { class: "issue-header code", name: "Customer Code" },
          {
            class: "issue-header representative",
            name: "Onsite Representative",
          },
          {
            class: "issue-header jobsite",
            name: "Jobsite",
          },
        ]}
        tableRows={
          <tr className="time-materials-row">
            <td>
              <ParInput
                type="text"
                label=""
                customClass="time-material-input"
                value={matCustomer}
                readOnly={true}
                disabled={resetting}
              />
            </td>

            <td>
              <ParInput
                type="text"
                label=""
                customClass="time-material-input"
                placeholder="Enter Customer Code"
                value={matCustomerCode}
                onChange={(e) => handleInputChange(e, "matCustomerCode")}
                disabled={resetting}
              />
            </td>

            <td>
              <ParInput
                type="text"
                label=""
                placeholder="Enter Onsite Representative"
                customClass="time-material-input"
                value={matOsr}
                onChange={(e) => handleInputChange(e, "matOsr")}
                disabled={resetting}
              />
            </td>

            <td>
              <ParInput
                type="text"
                label=""
                customClass="time-material-input"
                placeholder="Enter Jobsite"
                value={matJobsite}
                onChange={(e) => handleInputChange(e, "matJobsite")}
                disabled={resetting}
              />
            </td>
          </tr>
        }
      />
    </ExpandCollapseSection>
  );
};

export default TimeMaterials;
