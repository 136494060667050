import { getTotalHours } from "./totalHours";

export const updatePerDiem = (parDispatch, parFormDataState) => {
  if (parFormDataState.phaseCodes["012130-00-00"]) {
    const employeeIds = new Set();

    Object.keys(parFormDataState.phaseCodes).forEach((phaseCode) => {
      parFormDataState.phaseCodes[phaseCode].laborData.forEach((employee) => {
        employeeIds.add(employee.id);
      });
    });

    const idArray = Array.from(employeeIds);

    idArray.forEach((id) => {
      parDispatch({
        type: "updatePhaseCodeField",
        payload: {
          phaseCode: "012130-00-00",
          attributeName: "laborData",
          fieldName: "combinedPhaseTime",
          updatedValue: getTotalHours(id, parFormDataState, "labor"),
          rowId: id,
          filterName: "id",
        },
      });
    });
  }
};
