const ApproverChanges = (props) => {
  const { parData, color, wrapperClass = "", children } = props;

  const renderStatusComments = () => {
    return parData.statusComments.map((comment) => {
      return (
        <tr key={comment.id}>
          <td>
            {comment.date} <br />
            {comment.status}
          </td>
          <td>{comment.message}</td>
        </tr>
      );
    });
  };

  if (parData.statusComments?.length > 0) {
    return (
      <div className={`approver-changes-wrapper ${wrapperClass}`}>
        {children}
        <table className={`requested-changes ${color}`}>
          <thead>
            <tr>
              <th colSpan={2}>APPROVER REQUESTED CHANGES</th>
            </tr>
          </thead>

          <tbody>{renderStatusComments()}</tbody>
        </table>
      </div>
    );
  } else {
    return "";
  }
};

export default ApproverChanges;
