import ExpandCollapseSection from "../../../components/core/ExpandCollapseSection";
import { ParTextArea } from "../../../components/helpers/ParFormComponents";
import { useCurrentPar } from "../../../components/contexts/CurrentParContext";

const IncidentsSection = () => {
  const { parFormDataState } = useCurrentPar();

  const { incidents, checkboxes } = parFormDataState;

  return (
    <ExpandCollapseSection
      sectionName={"Incidents"}
      checkboxValue={checkboxes.incidents}
    >
      <ParTextArea
        customClass="single-problem-container"
        rows="3"
        cols="30"
        value={incidents}
        readOnly
      />
    </ExpandCollapseSection>
  );
};

export default IncidentsSection;
