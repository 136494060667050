export const hoursWorkedIsValid = (formData, id, type) => {
  const splitTimeOne = formData.startTime.split(":");
  const splitTimeTwo = formData.stopTime.split(":");
  let numberValue = 0;

  let hours = splitTimeTwo[0] - splitTimeOne[0];
  let minutes = splitTimeTwo[1] - splitTimeOne[1];

  if (hours <= -1) {
    hours += 24;
  }

  if (minutes < 0) {
    hours = hours - 1;
    minutes = splitTimeTwo[1] + 60 - splitTimeOne[1];
  }

  const decimalMinutes = parseInt((minutes / 6) * 10, 10);

  const decimalTimeValue = parseFloat(
    parseInt(hours, 10) +
      "." +
      (decimalMinutes < 10 ? "0" : "") +
      decimalMinutes
  ).toFixed(2);

  Object.keys(formData.phaseCodes).forEach((phaseCode) => {
    formData.phaseCodes[phaseCode][type].forEach((record) => {
      if (
        id === record.id &&
        type === "laborData" &&
        phaseCode !== "012130-00-00"
      ) {
        numberValue = Number(record.hoursWorked) + numberValue;
      } else if (id === record.equipNum && type === "equipmentData") {
        numberValue = Number(record.operatedHours) + numberValue;
      }
    });
  });

  if (numberValue > decimalTimeValue) {
    return "exceeds";
  } else if (!new RegExp("^[0-9]*.[0-9]{0,2}$").test(numberValue)) {
    return "precision";
  } else {
    return false;
  }
};
