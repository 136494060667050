import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faCircleNotch,
  faCloudSunRain,
  faExclamation,
  faExclamationCircle,
  faPhone,
  faSearch,
  faSpinner,
  faTimes,
  faTriangleExclamation,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

const solidIcons = () => {
  return library.add(
    faTimes,
    faSpinner,
    faCloudSunRain,
    faCheckCircle,
    faPhone,
    faSearch,
    faCircleNotch,
    faExclamation,
    faExclamationCircle,
    faChevronUp,
    faChevronDown,
    faTriangleExclamation,
    faTrash
  );
};

export default solidIcons;
