import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import LoginPage from "./pages/LoginPage";
import NoMatchPage from "./pages/NoMatchPage";

export default function PublicRoutes(props) {
  return (
    <div className="wrapper">
      <div className="container">
        <Switch>
          <Route path="/login" render={(props) => <LoginPage {...props} />} />

          <Redirect exact from="/" to="/login" />

          <Route component={NoMatchPage} />
        </Switch>
      </div>
    </div>
  );
}
