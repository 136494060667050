import { useEffect, useState } from "react";

import ConfirmationModal from "../components/modals/confirmation-modal/ConfirmationModal";
import ExternalLink from "../components/core/ExternalLink";
import SearchResults from "../components/SearchResults";
import JobGroupList from "../components/JobGroupList";

import useAbortEffect from "../components/hooks/useAbortEffect";
import useDebounce from "../components/hooks/useDebounce";

import asyncAPICall from "../util/apiWrapper";
import { useUserPendingApprovalPars } from "../components/contexts/UserApprovalParsContext";
import { useModalContext } from "../components/contexts/ModalContext";
import { errorToast, successfulToast } from "../util/toastNotifications";
import { todayFormattedForInput } from "../util/dayString";

const ApprovalsPage = (props) => {
  const [selectedPAR, setSelectedPAR] = useState(false);
  const [searchedPAR, setSearchedPAR] = useState([]);
  const [searchedJob, setSearchedJob] = useState("");
  const [searchedStartDate, setSearchedStartDate] = useState("");
  const [searchedEndDate, setSearchedEndDate] = useState("");

  const debounceJob = useDebounce(searchedJob);
  const { isModalOpen, handleOpenModal, handleCloseModal } = useModalContext();
  const {
    pendingApprovalPars,
    getPendingApprovalPars,
    handleChangePendingApprovalPars,
  } = useUserPendingApprovalPars();

  const handleSelectPendingPar = (par) => {
    setSelectedPAR(par);
    handleOpenModal("deletePar");
  };

  const handleDeletePendingPAR = () => {
    asyncAPICall(
      `api:W2oHkD04/partblmain/deactivatePAR?dcrNumber=${selectedPAR.dcrNumber}`,
      "POST",
      null,
      (res) => {
        if (res.ok) {
          successfulToast(
            `Successfully deleted ${selectedPAR.jobNumber} on ${selectedPAR.date}`
          );
        }
      },
      null,
      (err) => {
        errorToast(
          `Something went wrong with deleting ${selectedPAR.jobNumber} on ${selectedPAR.date}`
        );
      }
    );

    handleChangePendingApprovalPars(
      pendingApprovalPars.filter((item) => item.id !== selectedPAR.id)
    );
    handleCloseModal("deletePar");
  };

  useAbortEffect(
    (signal) => {
      if (searchedStartDate && searchedEndDate && debounceJob?.length > 0) {
        asyncAPICall(
          `api:W2oHkD04/partblmainByJobWithStartAndEndDates?job=${debounceJob}&startDate=${searchedStartDate}&endDate=${searchedEndDate}`,
          "GET",
          null,
          null,
          (data) => {
            if (!signal.aborted) setSearchedPAR(data || []);
          },
          (err) => {
            if (!signal.aborted) console.log("error searching", err);
          },
          signal
        );
      }
    },
    [debounceJob, searchedStartDate, searchedEndDate]
  );

  useEffect(() => {
    getPendingApprovalPars();
  }, [getPendingApprovalPars]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const params = new URLSearchParams(props.history.location.search);
    const startDate = params.get("startDate");
    const endDate = params.get("endDate");
    const job = params.get("job");

    if (startDate && endDate && job) {
      setSearchedStartDate(startDate);
      setSearchedEndDate(endDate);
      setSearchedJob(job);
    }
  }, [props.history.location.search]);

  return (
    <div className="approvals-page-container">
      <div className="header-wrapper">
        <h1 className="approve-submissions-header">APPROVE SUBMISSIONS</h1>

        <div className="external-links-wrapper">
          <ExternalLink
            text={`Approver\nInstructions`}
            link="https://scribehow.com/shared/PAR_Approver__CH8IlpZCQvKX5RVHV2_5yA"
          />

          <ExternalLink
            text={`PAR\nUpdates`}
            link="https://sites.google.com/bhico.com/parupdates/home"
          />
        </div>
      </div>

      <div className="approvals-wrapper">
        <JobGroupList
          pars={pendingApprovalPars}
          handleSelectPendingPar={handleSelectPendingPar}
          showDelete={true}
          linkText="Approve?"
          title="ASSIGNED APPROVALS"
          gradientClass="blue-gradient"
          checkIconColor="#0066b2"
          icon="fa fa-check-circle"
        />

        <SearchResults
          searchedStartDate={searchedStartDate}
          searchedEndDate={searchedEndDate}
          searchedJob={searchedJob}
          searchedPAR={searchedPAR}
          setSearchedStartDate={setSearchedStartDate}
          setSearchedEndDate={setSearchedEndDate}
          setSearchedJob={setSearchedJob}
          todayFormattedForInput={todayFormattedForInput}
          gradientClass="green-gradient"
          checkIconColor="#4caf50"
          icon="fa fa-check-square"
        />
      </div>

      <ConfirmationModal
        isModalOpen={isModalOpen.deletePar}
        onRequestClose={() => handleCloseModal("deletePar")}
        modalQuestion={`Are you sure you want to delete ${selectedPAR.jobNumber} on ${selectedPAR.date}`}
        onConfirm={handleDeletePendingPAR}
      />
    </div>
  );
};

export default ApprovalsPage;
