const GeneralCheckbox = (props) => {
  const {
    wrapperClass = "",
    inputClass = "",
    labelClass = "",
    labelText = "",
    checked = "",
    ...restProps
  } = props;

  const labelId = labelText.replaceAll(" ", "-").toLowerCase();

  return (
    <div className={`general-checkbox-wrapper ${wrapperClass}`}>
      <input
        className={`general-checkbox ${inputClass}`}
        id={labelId}
        type="checkbox"
        checked={checked}
        {...restProps}
      />
      {labelText && (
        <label className={labelClass} htmlFor={labelId}>
          {labelText}
        </label>
      )}
    </div>
  );
};

export default GeneralCheckbox;
